import { AxiosResponse } from "axios";
import { instance } from "./instances";
import type { SpaceListResponse } from "utils/types/response/SpaceResponse";

const SpaceAPI = {
  updateSpace: ({
    spaceId,
    data,
  }: {
    spaceId: number;
    data: { title?: string; currency?: string };
  }) => instance.put(`/my_spaces/${spaceId}/`, data),
  getMySpaces: () => instance.get<SpaceListResponse[]>("/my_spaces/"),
};

export default SpaceAPI;
