import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import useStore from "store/store";
import App from "App.tsx";
import { useNavigate } from "react-router-dom";
import { ProfileAPI } from "services/API/Profile";
import { Subsription } from "utils/types/response/ProfileRes";
import Modal from "utils/components/Modal";
import CustomAlert from "utils/components/CustomAlert";
import AlertAboutLimit from "utils/components/AlertAboutLimit/AlertAboutLimit";
import CustomAlertContext, { type CustomAlertOptions } from "CusomAlertContext";
import useSetAccounts from "useSetAccounts";
import useUpdateData from "useUpdateData";
import CookieMessage from "components/CookieMessage";

const AppContainer = () => {
  const { loggedIn, setLoggedIn, setSubscription, setIsEmployee, setIsFree } =
    useStore();

  const checkAuth = useMutation({
    mutationKey: ["checkAuth"],
    mutationFn: () => AuthAPI.checkAuth(),
    onSuccess: () => setLoggedIn(true),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const syncLogout = (e: StorageEvent) => {
      if (e.key === "logout") {
        navigate("/login");
      }
    };
    window.addEventListener("storage", syncLogout);
    if (localStorage.getItem("token")) {
      checkAuth.mutateAsync();
    }
  }, []);
  // getting user subscribe
  const { data: userRoles } = useQuery({
    queryKey: ["get user roles"],
    queryFn: () => ProfileAPI.ProfileView().then((res) => res.data.roles),
    enabled: loggedIn,
  });

  useEffect(() => {
    if (userRoles) {
      const userSubscription = userRoles.find(
        (value) =>
          value === "free" ||
          value === "business_lic" ||
          value === "business_member_lic" ||
          value === "business_member_plan" ||
          value === "business_plan"
      ) as Subsription;
      setSubscription(userSubscription);
      setIsEmployee(userRoles.includes("employee"));
      setIsFree(userRoles.includes("free"));
    }
  }, [userRoles]);
  const [showingCustomAlert, setShowingCustomAlert] = useState<
    boolean | "limit alert"
  >(false);
  const [customAlertText, setCustomAlertText] = useState("");
  const [customAlertOptions, setCustomAlertOptions] =
    useState<CustomAlertOptions>({});

  // doesn't metter with text you insert if it's a limit alert
  const showCustomAlert = (text: string, options: CustomAlertOptions = {}) => {
    if (options?.itsLimitAlert) {
      setShowingCustomAlert("limit alert");
    } else {
      setCustomAlertText(text);
      setShowingCustomAlert(true);
      setCustomAlertOptions(options);
    }
  };
  // updating accounts and categories every 10 seconds
  useUpdateData();

  // useSetAccounts - implements the accounts API and writes accounts to the store
  const isPendingForAccounts = useSetAccounts();

  const [showCookieMessage, setShowCookieMessage] = useState(
    !localStorage.getItem("disableCookieMessage")
  );

  return (
    <>
      <CustomAlertContext.Provider value={showCustomAlert}>
        <App
          isLoading={checkAuth.isPending || isPendingForAccounts}
          loggedIn={loggedIn}
        />

        {showCookieMessage && (
          <CookieMessage setShowCookieMessage={setShowCookieMessage} />
        )}

        {showingCustomAlert && (
          <Modal
            setActive={
              setShowingCustomAlert as Dispatch<SetStateAction<boolean>>
            }
          >
            {showingCustomAlert === "limit alert" ? (
              <AlertAboutLimit setActive={setShowingCustomAlert} />
            ) : (
              <CustomAlert
                text={customAlertText}
                setActive={setShowingCustomAlert}
                options={customAlertOptions}
              />
            )}
          </Modal>
        )}
      </CustomAlertContext.Provider>
    </>
  );
};

export default AppContainer;
