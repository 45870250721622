import type { TicketStatus } from "utils/types/response/TicketsRes";
import clock from "image/Tickets/clock.svg";
import React from "react";

interface Props {
  ePalLogo: string | undefined;
  status: TicketStatus;
  isEmployee: boolean;
  handleCloseTicket: () => void;
  handleTakeClick: () => void;
  title: string;
  employee: string | undefined;
  message: string;
  unseen: number;
  createTime: string;
  help_in_space: boolean;
  handleOpen: () => void;
}

const TicketPreview = React.memo(
  ({
    ePalLogo,
    status,
    isEmployee,
    handleCloseTicket,
    handleTakeClick,
    title,
    unseen,
    message,
    employee,
    createTime,
    help_in_space,
    handleOpen,
  }: Props) => {
    const isWaiting = status === "waiting";
    const isClosed = status === "closed";

    return (
      <section className="bg-[#121212] p-3.5 rounded-xl">
        <div className="grid gap-x-2 grid-cols-[58px_6fr_auto]">
          {isWaiting ? (
            <img
              className="bg-[#181818] p-[1rem] rounded-xl row-[1/4]"
              src={clock}
            />
          ) : (
            <span className="row-[1/4] flex justify-center rounded-xl text-3xl mb-2 max-h-16 max-w-16 items-center bg-[#181818]">
              {ePalLogo}
            </span>
          )}
          <p className="font-bold">{title}</p>
          <p className="row-[2/3]">
            {isWaiting ? "Waiting for an employee" : employee}
          </p>
          <p className="row-[3/4] text-[#727272] text-[16px]">
            {message.length > 23 ? `${message.slice(0, 20)}...` : message}
          </p>
          <span className="text-sm text-[#727272] justify-self-end">
            {createTime}
          </span>
          {!!unseen && (
            <span className="bg-orange rounded-full justify-self-end text-sm row-[3/4] text-black px-1.5 flex items-center">
              {unseen}
            </span>
          )}
        </div>
        <div className="flex justify-between mt-4 items-center">
          <span className="text-xs font-bold text-[#727272]">
            Help with the project is required:{" "}
            {help_in_space ? "true" : "false"}
          </span>
          {isEmployee && status === "in_process" && (
            <button
              onClick={handleCloseTicket}
              className="bg-[#808080] font-bold text-black px-3 py-1 rounded-2xl"
            >
              Close
            </button>
          )}
          {isWaiting && isEmployee && (
            <button
              onClick={handleTakeClick}
              className="text-black font-semibold px-3 py-1 hover:bg-[#cc8800] rounded-2xl text-sm duration-300 bg-orange"
            >
              take
            </button>
          )}
          {isClosed && (
            <span className="bg-[#808080] rounded-2xl text-sm px-3 py-1">
              Closed
            </span>
          )}
          {status === "in_process" && (
            <button
              className="bg-orange text-black font-semibold px-3 py-1 hover:bg-[#cc8800] rounded-2xl text-sm duration-300"
              onClick={handleOpen}
            >
              Open
            </button>
          )}
        </div>
      </section>
    );
  },
);

export default TicketPreview;
