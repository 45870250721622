import TableComponent from "./ProjectOverview/TableComponent";
import StatisticComponent from "./Service/StatisticComponent";
import { motion } from "framer-motion";

const ServiceAndOverview = () => {
  return (
    <motion.div
      className="h-[96dvh] bg-[#19191b] grow rounded-2xl flex flex-col"
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.005,
        transition: { duration: 0.5 },
      }}
    >
      <StatisticComponent />
      <TableComponent />
    </motion.div>
  );
};

export default ServiceAndOverview;
