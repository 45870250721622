import type { Ticket } from "utils/types/response/TicketsRes";
import { format, parseISO, isSameDay, isSameYear } from "date-fns";
import useStore from "store";
import { useMutation } from "@tanstack/react-query";
import { TicketsAPI } from "services/API/Tickets";
import smalltalk from "utils/libraries/smalltalk";
import { queryClient } from "main";
import SpaceAPI from "services/API/Space";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import TicketPreview from "./TicketPreview.tsx";
import type TicketInf from "../TicketInfType.ts";

const getFormattedTime = (time: string) => {
  const inputTime = parseISO(time);
  const currentTime = new Date();
  if (isSameDay(inputTime, currentTime)) {
    return format(inputTime, "HH:mm");
  } else if (isSameYear(inputTime, currentTime)) {
    return format(inputTime, "d MMM HH:mm");
  } else {
    return format(inputTime, "yyyy d MMM HH:mm");
  }
};

interface Props extends Ticket {
  handleOpenClick?: (ticket: TicketInf) => void;
}

const updateData = () => {
  queryClient.invalidateQueries({
    queryKey: ["get tickets"],
  });
  queryClient.invalidateQueries({
    queryKey: ["get waiting tickets"],
  });
};

const TicketPreviewContainer = ({
  title,
  help_in_space,
  status,
  message,
  created_at,
  space_pk,
  id,
  handleOpenClick,
  employee,
  unseen,
  chat,
}: Props) => {
  const { isEmployee } = useStore();

  const { mutate: takeTicketMutate } = useMutation({
    mutationFn: TicketsAPI.takeTicket,
    onSuccess: () => {
      if (help_in_space) {
        SpaceAPI.getMySpaces()
          .then((res) => res.data)
          .then((data) => data.find((space) => space.id === space_pk))
          .then((space) =>
            localStorage.setItem(
              "space",
              JSON.stringify(sanitizeObject(space)),
            ),
          );
      }
    },
    onSettled: updateData,
  });

  const { mutate: closeTicketMutate } = useMutation({
    mutationFn: TicketsAPI.closeTicket,
    onSuccess: () => {
      if (help_in_space) {
        SpaceAPI.getMySpaces()
          .then((res) => res.data)
          .then((data) => data.find((space) => space.id !== space_pk))
          .then((space) =>
            localStorage.setItem(
              "space",
              JSON.stringify(sanitizeObject(space)),
            ),
          );
      }
    },
    onSettled: updateData,
  });

  const handleCloseTicket = () => {
    smalltalk
      .confirm("Are you sure ?", "You`re gonna close the ticket")
      .then(() => closeTicketMutate(id));
  };

  const handleTakeClick = () => {
    takeTicketMutate(id);
  };
  const handleOpen = () =>
    handleOpenClick?.({
      title,
      id: chat as number,
      employee: employee as string,
    });

  const ePalLogo = employee?.[0];

  return (
    <TicketPreview
      ePalLogo={ePalLogo}
      createTime={getFormattedTime(created_at)}
      status={status}
      handleTakeClick={handleTakeClick}
      handleCloseTicket={handleCloseTicket}
      title={title}
      isEmployee={isEmployee}
      unseen={unseen}
      message={message}
      employee={employee}
      help_in_space={help_in_space}
      handleOpen={handleOpen}
    />
  );
};

export default TicketPreviewContainer;
