import { type MouseEventHandler, type ReactNode, useState } from "react";
import cl from "./Subscription.module.scss";
import { useTranslation } from "react-i18next";
import useStore from "store/store";

const Subsction = ({
  image,
  title,
  titleColor,
  desription,
  details,
  buttonTextColor,
  buttonBg,
  cost,
  costBg,
  plan,
  costTextColor,
  period,
  action,
  containerClass,
  handleButtonClick,
}: {
  title: "Business License" | "Business Plan" | "Basic";
  titleColor: "#ffaa00" | "#00c2ff";
  desription: string;
  details: string | ReactNode;
  buttonTextColor: "black" | "white";
  buttonBg: "#fea800" | "#00c2ff";
  cost: 100 | 500 | "FREE";
  costBg: "#3e2901" | "#003445";
  costTextColor: "#c29131" | "#2aa1ca";
  period: string;
  action: string;
  plan: string;
  image: string;
  containerClass: string;
  handleButtonClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  const { subscription } = useStore();
  const isBusinessPlan =
    subscription === "business_plan" || subscription === "business_member_plan";
  const isBusinessLic = subscription === "business_lic"  || subscription === "business_member_lic";
  const buttonText =
    isBusinessLic && title === "Business License"
      ? t("subscription.addlic")
      : t("subscription.select");

  return (
    <article className={containerClass ? containerClass : ""}>
      <div className={cl.description}>
        <div className="flex">
          <p className="text-2xl my-3" style={{ color: titleColor }}>
            {t(`subscription.${title.toLowerCase()}`)}
          </p>
          <div className={cl.iconContainer} style={{ background: costBg }}>
            <img className={cl.centeredIcon} src={image} />
          </div>
        </div>
        <div className="flex">
          <div
            className="font-normal grow text-3xl inline relative w-[50%] rounded-r-md"
            style={{
              color: costTextColor,
            }}
          >
            {cost}
            {title !== "Basic" && (
              <div
                className="align-top text-sm inline"
                style={{
                  color: costTextColor,
                }}
              >
                €/{title === "Business Plan" ? "month" : "license"}
              </div>
            )}
          </div>
        </div>
        <div className="my-1 font-normal grow text-sm inline h-full py-[5px] relative w-full rounded-r-md text-[gray]">
          {plan}
        </div>
        {title !== "Basic" &&
          !(isBusinessPlan || (isBusinessLic && title === "Business Plan")) && (
            <button
              className="font-normal text-2xl py-4 px-3 rounded-3xl w-[39%]"
              style={{
                background: buttonBg,
                color: buttonTextColor,
              }}
              type="button"
              title={buttonText}
              onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          )}
        <div className="bg-[gray] h-[1px] w-full my-[2vh]" />
        <p className="text-md">{desription}</p>
        <div className={`text-[#787878] my-3 ${cl.details}`}>
          {t("subscription.included")}:
          <br />
          <div className="whitespace-pre-line">{details}</div>
        </div>
      </div>
    </article>
  );
};

export default Subsction;
