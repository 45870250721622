import { z } from "zod";
import { arrayIcons } from "utils/constants/categoryIcons";

export const titleObj = z
  .string()
  .max(24, { message: "The title must be up to 24 characters long" })
  .min(2, { message: "The title must be at least 2 characters long" })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The title contains prohibited characters",
  });

import { currenciesNames } from "utils/constants/currencies";

export const currencyObj = z
  .string()
  .refine((value) => currenciesNames.includes(value), {
    message: "It`s non correct currency",
  })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The currency contains prohibited characters",
  });

export const categoryIconsObj = z.enum(arrayIcons);

export const commentObj = z
  .string()
  .max(100, { message: "The comment must be up to 100 characters long" })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The comment contains prohibited characters",
  });

export const amountObj = z
  .string()
  .refine(
    (value) => {
      const parsedValue = Number.parseFloat(value);
      return !Number.isNaN(parsedValue) && parsedValue > 0;
    },
    { message: "the amount must be a positive number" },
  )
  .refine((value) => Number.parseFloat(value) < 99000000000, {
    message: "the amount is too big",
  })
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The amount contains prohibited characters",
  });

export const verifyCodeObj = z
  .string()
  .length(8, { message: "code must be 8 characters long" })
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "Your code contains prohibited characters",
  });

export const emailObj = z
  .string()
  .email({ message: "This is not a valid email" })
  .max(50, { message: "This email is too long" })
  .min(5, { message: "This email is too short" })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The email contains prohibited characters",
  });

const badPasLenght = {
  message: "The password should be between 8 and 24 characters long",
};

const getLength = (arr: string[] | null) => (arr ? arr.length : 0);

export const passwordObj = z
  .string()
  .max(24, badPasLenght)
  .min(8, badPasLenght)
  .refine(
    (value) => {
      const letters: string[] | null = value.match(/[a-zA-Z]/g);
      const numbers: string[] | null = value.match(/[0-9]/g);
      return getLength(letters) >= 1 && getLength(numbers) >= 1;
    },
    {
      message: "The password must contain at least 1 letter and 1 number",
    },
  );
