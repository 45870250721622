import { Fragment, memo } from "react";
import Message from "./Message";
import type { Message as MessageType } from "utils/types/response/TicketsRes";
import { format } from "date-fns";

const MessagesPart = memo(
  ({
    messages,
    date,
    email,
    ePalLogo,
  }: {
    messages: MessageType[];
    date: string;
    email: string;
    ePalLogo: string;
  }) => {
    return (
      <div className="flex flex-col gap-y-4">
        <span className="mx-auto bg-[#323232] rounded-2xl px-6 text-[#8c8098]">
          {format(new Date(date), "dd MMM")}
        </span>
        {messages.map(({ text, created_at, email: messageEmail }, i) => (
          <Fragment key={`message${i}`}>
            <Message
              ePalLogo={ePalLogo}
              text={text}
              time={created_at}
              isOwnMessage={email === messageEmail}
            />
          </Fragment>
        ))}
      </div>
    );
  },
);

export default MessagesPart;
