import React from "react";
import cl from "./Form.module.scss";
import Safe from "image/expenses/Safe White.svg";
import Cashout from "image/expenses/Cash Out.svg";
import { UseFormRegister } from "react-hook-form";
import { IFormInputs } from "./TransactionFormContainer";
import withErrorShow from "utils/hoc/withErrorShow";
import { useTranslation } from "react-i18next";

type TransactionsFormProps = {
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  selectedAccount: number | null;
  setSelectedAccount: React.Dispatch<React.SetStateAction<number | null>>;
  onSubmit: () => void;
  accounts: { id: number; title: string; balance: number; currency: string }[];
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  register: UseFormRegister<IFormInputs>;
};

const TransactionsForm: React.FC<TransactionsFormProps> = ({
  accounts,
  setModal,
  register,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={onSubmit}
      className="grid text-[20px] items-center gap-y-4 p-[24px] grid-rows-[48px_48px_auto] grid-cols-[minmax(0,2fr)_minmax(0,1fr)_minmax(0,1fr)]"
    >
      <div className="relative col-[1/4] w-full">
        <img
          src={Safe}
          alt="Safe"
          className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-6"
        />
        <select {...register("accountId")} className={`${cl.field}`}>
          <option value="" disabled className={cl.selectAccountDropdown}>
            {t("transactionsForm2.selectAccount")}
          </option>
          {accounts.map((account) => (
            <option
              key={`account ${account.id}`}
              value={account.id}
              className={cl.selectAccountDropdown}
            >
              {account.title} | {account.balance} {account.currency}
            </option>
          ))}
        </select>
      </div>
      <div className="relative flex col-[1/4]">
        <img
          src={Cashout}
          alt="Cashout"
          className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-6"
        />
        <input
          type="text"
          placeholder={t("transactionsForm2.amount")}
          className={cl.field}
          {...register("amount")}
          autoComplete="off"
        />
      </div>
      <input
        type="text"
        {...register("comment")}
        placeholder={t("transactionsForm2.comment")}
        className={`${cl.field} bg-hashtag_logo !rounded-r-none !col-[1/3]`}
        autoComplete="off"
      />
      <p className="bg-[#242424] text-center py-[13px] rounded-r-xl text-[#6E6E6E]">
        {t("transactionsForm2.optional")}
      </p>
      <button
        type="button"
        className="rounded-l-xl text-white bg-[#181818] py-[12px]"
        onClick={() => setModal(false)}
      >
        {t("transactionsForm2.cancel")}
      </button>
      <button
        type="submit"
        className="bg-[#FFA800] my-2 rounded-r-xl py-[12px] col-[2/4]"
      >
        {t("transactionsForm2.add")}
      </button>
    </form>
  );
};

export default withErrorShow(TransactionsForm);
