import { memo } from "react";
import cl from "./CookieMessage.module.scss";
import type { TFunction } from "i18next";

const CookieMessage = memo(
  ({
    handleButtonClick,
    t,
  }: {
    handleButtonClick: () => void;
    t: TFunction;
  }) => {
    return (
      <div className={cl.container}>
        <p>{t("cookieMessage")}</p>
        <button onClick={handleButtonClick}>Okay</button>
      </div>
    );
  },
);

export default CookieMessage;
