import React from "react";
import cl from "./Table.module.scss";

export const ErrorMessage = ({ message }: { message?: string }) => (
  <div className={cl.error}>{message || "Error"}</div>
);

export const rowVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: (index: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: index * 0.1,
      duration: 0.5,
    },
  }),
  exit: { opacity: 0, x: 50 },
};
