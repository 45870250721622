import { z } from "zod";

export const valSchema = z.object({
  neededHelp: z.boolean(),
  title: z
    .string()
    .max(40, { message: "The title must be up to 40 characters long" })
    .min(2, { message: "The title must be at least 2 characters long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The title contains prohibited characters",
    }),
  message: z
    .string()
    .min(2, { message: "The subject must be at least 2 characters long" })
    .max(800, { message: "The subject is too long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The subject contains prohibited characters",
    }),
});
