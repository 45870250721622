import {
  ViewDepositsRes,
  ViewStatisticRes,
  GetSessionRes,
  ViewTableRes,
} from "utils/types/response/AdminRes";
import { instance } from "./instances";

export const AdminAPI = {
  ViewDeposits: (spaceId?: number) =>
    instance.get<ViewDepositsRes>(`/my_spaces/${spaceId}/total_deposits/`),

  ViewStatistic: (spaceId?: number) =>
    instance.get<ViewStatisticRes>(`/my_spaces/${spaceId}/service_statistic/`),

  GetServiceSession: (spaceId?: number) =>
    instance.get<GetSessionRes>(`/store/my_spaces/${spaceId}/create_checkout_service_session/`),

  ViewTable: (spaceId?: number) =>
    instance.get<ViewTableRes>(`/my_spaces/${spaceId}/project_overview/`),
};
