import Title from "../CreateAccount/Title";
import Check from "image/Account/Check.svg";
import React, { useEffect, type Dispatch, type SetStateAction } from "react";
import { useMutation } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import type { IIncomeAccountReq } from "utils/types/request/AccountRequest";
import { queryClient } from "main";
import TransactionsFormContainer from "./TransactionsFormContainer";
import showErrorMessage from "utils/constants/showErrorMessage";
import useStore from "store";
import { useTranslation } from "react-i18next";

const IncomingModal = React.memo(
  ({
    setModal,
    accountId,
  }: {
    setModal: Dispatch<SetStateAction<boolean>>;
    accountId: number;
  }) => {
    const { setShowSpinner } = useStore();
    const { mutate } = useMutation({
      mutationKey: ["income account"],
      mutationFn: (data: IIncomeAccountReq) => {
        setModal(false);
        setShowSpinner(true);
        return AccountAPI.IncomeAccount(accountId, data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get accounts"] });
        queryClient.invalidateQueries({ queryKey: ["get history"] });
      },
      onError: showErrorMessage,
      onSettled: () => setShowSpinner(false),
    });

    const { t } = useTranslation();

    return (
      <>
        <Title
          image={Check}
          header={t("incomeModal.header")}
          title={t("incomeModal.title")}
          className="rounded-tr-[24px]"
        />
        <TransactionsFormContainer
          mutate={mutate}
          setModal={setModal}
          firstInputClass="bg-course_up"
        />
      </>
    );
  },
);

export default IncomingModal;
