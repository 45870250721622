import { useEffect, useRef, type FC } from "react";
import Joyride, { type CallBackProps } from "react-joyride";
import { useTour } from "./Hooks/useTour";
import useTourSteps from "./Steps";
import { getTourStyles } from "./TourStyles";
import { useTourTriggers } from "./Hooks/useTourTriggers";
import "./AppTour.module.scss";
import { useTranslation } from "react-i18next";
import useStore from "store";
import eventEmitter from "services/EventEmitter";
import { runNextStep } from "./runNextStep";

const AppTour: FC = () => {
  const { tourRunning, stepIndex, setStepIndex, handleTourCallback } =
    useTour();
  const tourSteps = useTourSteps();
  const { t } = useTranslation();
  const { accounts } = useStore();

  const totalBalanceIndex = accounts ? accounts.length : 0;

  const { triggerArrowClick, triggerPlusAccountClick, triggerEventEmitters } =
    useTourTriggers();

  // the variable stores information for the handleCallback function to know if it was run by the enter key the previous time
  // It's necessary for step 19 to work correctly.
  const prevEmulation = useRef(false);

  const handleCallback = (data: CallBackProps & { simulation?: boolean }) => {
    const { action, index, type } = data;

    handleTourCallback(data);

    if (type === "step:after") {
      const nextTriggers: { [key: number]: () => void } = {
        2: () => eventEmitter.emit("setAccountNumber", totalBalanceIndex),
        4: () => triggerArrowClick("#arrowAccount"),
        5: triggerPlusAccountClick,
        6: () => document.querySelector("#cancelButtonAccount")?.["click"](),
        7: () => triggerEventEmitters("setHoveredTrue"),
        8: () => {
          triggerEventEmitters("setHoveredFalse");
          triggerEventEmitters("setSpendTrue");
        },
        9: () => triggerEventEmitters("setSpendFalse"),
        10: () => triggerEventEmitters("setNothTrue"),
        11: () => triggerEventEmitters("setNothFalse"),
        12: () => triggerEventEmitters("setMenuTrue"),

        19: () => {
          if (data.simulation || !prevEmulation.current) {
            document.querySelector("#ExcelButton")?.["click"]();
          }
        },

        21: () => {
          if (data.simulation || !prevEmulation.current) {
            document.querySelector("#ExcelButton")?.["click"]();
          }
        },
      };

      const prevTriggers: { [key: number]: () => void } = {
        22: () => document.querySelector("#ExcelButton")?.["click"](),
        20: () => document.querySelector("#ExcelButton")?.["click"](),
        13: () => triggerEventEmitters("setMenuFalse"),
        12: () => triggerEventEmitters("setNothTrue"),
        11: () => triggerEventEmitters("setNothFalse"),
        10: () => triggerEventEmitters("setSpendTrue"),
        9: () => {
          triggerEventEmitters("setSpendFalse");
          triggerEventEmitters("setHoveredTrue");
        },
        8: () => triggerEventEmitters("setHoveredFalse"),
        7: () => {
          document.querySelector("#arrowAccount")?.["click"]();
          document.querySelector("#arrowAccount")?.["click"]();
          setStepIndex(5);
        },
        3: () => eventEmitter.emit("setAccountNumber", 0),
        5: () => eventEmitter.emit("setAccountNumber", totalBalanceIndex),
      };

      if (action === "next" && nextTriggers[index]) {
        nextTriggers[index]();
        prevEmulation.current = !!data.simulation;
      } else if (action === "prev" && prevTriggers[index]) {
        prevTriggers[index]();
      }
    }
  };

  useEffect(() => {
    if (tourRunning) {
      const handleEnter = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          runNextStep(handleCallback, stepIndex);
        }
      };

      document.addEventListener("keyup", handleEnter);
      return () => {
        document.removeEventListener("keyup", handleEnter);
      };
    }
  }, [stepIndex, tourRunning]);

  return (
    <div className="absolute">
      <Joyride
        steps={tourSteps}
        run={tourRunning}
        continuous
        showProgress
        stepIndex={stepIndex}
        callback={handleCallback}
        styles={getTourStyles(stepIndex)}
        scrollToFirstStep={true}
        disableScrolling={true}
        showSkipButton={false}
        disableOverlayClose={true}
        locale={{
          back: t("tour.back"),
          nextLabelWithProgress: t("tour.next", {
            step: stepIndex + 1,
            steps: tourSteps.length,
          }),
        }}
      />
    </div>
  );
};

export default AppTour;
