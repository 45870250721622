import React, { useEffect, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import classes from "./expenses.module.scss";
import Circle from "./Circles/Circle";
import AccountCircle from "./Circles/AccountCircle";
import { CategoryAPI } from "services/API";
import getSpaceId from "utils/constants/getSpaceId";
import useStore from "store";
import PageNavigation from "./PageNavigation";

const Expenses: React.FC = () => {
  const spaceId = getSpaceId();
  const {
    setCategories,
    categories,
    categoryChanged,
    setCategoryChanged,
    userRestrictions: { categories: limit },
  } = useStore();

  // Функция для получения данных
  const { data, refetch } = useQuery({
    queryKey: ["get categories"],
    queryFn: () => CategoryAPI.getCategories(spaceId),
    enabled: !!spaceId,
  });

  const categoriesLimitReached = useMemo(
    () => categories && categories.length >= limit,
    [limit, categories]
  );

  // useEffect(() => {
  //   const updateTink = async () => {
  //     await TinkService.updateTink();
  //   };

  //   updateTink();
  // }, []);

  // Обновление категорий вручную при изменении данных
  useEffect(() => {
    if (data) {
      setCategories(data.data);
      if (categoryChanged) {
        setCategoryChanged(false); // Сбросить флаг после обновления
      }
    }
  }, [data, setCategories, categoryChanged, setCategoryChanged]);

  // Рефетч данных при изменении флага
  useEffect(() => {
    if (categoryChanged) {
      refetch();
    }
  }, [categoryChanged, refetch]);

  const expensesRef = useRef<HTMLDivElement>(null);

  const generateCircularAndRectangularPositions = (count: number) => {
    const positions = [];
    const circleSize = 200; // Size of each circle
    const spacing = 30; // Spacing between circles
    const iconsInLastRow = 0; // Number of icons in the last row
    const lastRowIndex = count - iconsInLastRow;

    let angleStep = 0;
    let layer = 0;
    let currentLayerCount = 0;

    for (let i = 0; i < lastRowIndex; i++) {
      if (currentLayerCount >= layer * 6) {
        layer++;
        currentLayerCount = 0;
        angleStep = (Math.PI * 2) / (layer * 6);
      }

      const angle = currentLayerCount * angleStep;
      const radius =
        layer === 1
          ? circleSize + spacing + 50
          : layer * (circleSize + spacing) + 50;

      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);

      positions.push({ x, y });

      currentLayerCount++;
    }

    // Last row layout as a rectangle
    const lastRowCenterX = 0;
    const lastRowCenterY = (layer + 1) * (circleSize + spacing);
    const lastRowWidth = iconsInLastRow * (circleSize + spacing);

    for (let i = 0; i < iconsInLastRow; i++) {
      const x = lastRowCenterX - lastRowWidth / 2 + i * (circleSize + spacing);
      const y = lastRowCenterY;

      positions.push({ x, y });
    }

    return positions;
  };

  const totalCircles = 126;
  const positions = generateCircularAndRectangularPositions(totalCircles);
  const disabledCategories = categories !== data?.data;

  return (
    <PageNavigation childRef={expensesRef}>
      <div
        onContextMenu={(e) => e.preventDefault()}
        ref={expensesRef}
        className={classes.expenses}
      >
        <div className={classes.center}>
          <AccountCircle />
        </div>
        {positions.map((pos, index) => (
          <div
            key={index}
            className={classes.circle}
            style={{
              transform: `translate(${pos.x}px, ${pos.y}px)`,
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-100px",
              marginTop: "-100px",
            }}
          >
            <Circle
              id={`circle${index + 1}`}
              className={classes[`circle${index + 1}`]}
              disabled={disabledCategories}
              category={categories ? categories[index] : undefined}
              categoriesLimitReached={categoriesLimitReached}
              isBlocked={categories && categories[index] && index + 1 > limit}
            />
          </div>
        ))}
      </div>
    </PageNavigation>
  );
};

export default Expenses;
