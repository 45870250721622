import { useEffect, type RefObject } from "react";
import { useResizeObserver } from "usehooks-ts";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";
import utilCl from "utils/Classes.module.scss";

const usePanelResizing = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const savedSize = localStorage.getItem("panelSize");
    if (ref.current) {
      ref.current.className += ` ${utilCl.resizibleWin}`;
      if (savedSize) {
        const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
        ref.current.style.width = `${width}px`;
        ref.current.style.height = `${height}px`;
      }
    }
  }, []);

  useResizeObserver({
    ref,
    box: "border-box",
    onResize: ({ width, height }) =>
      localStorage.setItem(
        "panelSize",
        JSON.stringify(
          sanitizeObject({
            width,
            height,
          })
        )
      ),
  });
};

export default usePanelResizing;
