import React from "react";
import { AreaChart, Area, ResponsiveContainer, Tooltip } from "recharts";
import { motion } from "framer-motion";
import cl from "./TotalDeposits.module.scss";
import Dollar from "/src/image/AdminPanel/Dollar.svg";
import { useTranslation } from "react-i18next";

type StatCardProps = {
  deposits: number[];
};

const TotalDeposits: React.FC<StatCardProps> = ({ deposits }) => {
  const totalDeposits = deposits.reduce((acc, curr) => acc + curr, 0);
  const { t } = useTranslation();

  const chartData = deposits.map((deposit, index) => ({
    name: `Day ${index + 1}`,
    deposit,
  }));

  return (
    <motion.div
      className={cl.statCard}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.005,
        transition: { duration: 0.3 },
      }}
    >
      <motion.div
        className={cl.statHeader}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <div className={cl.statFooter}>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className={cl.totalDeposits}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <div className={cl.tooltip}>{t("stats.description3")}</div>
            {t("stats.title2")}
          </motion.h2>
          <motion.div
            className={cl.statAmount}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            €{totalDeposits.toFixed(2)}
          </motion.div>
        </div>
        <motion.img
          src={Dollar}
          alt="Dollar Icon"
          className={cl.dollarIcon}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.3 },
          }}
        />
      </motion.div>

      <div className={cl.chartContainer}>
        <ResponsiveContainer width="100%" height="50%">
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#808080" stopOpacity={0.95} />
                <stop offset="100%" stopColor="#808080" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="linear"
              dataKey="deposit"
              stroke="#808080"
              fill="url(#gradient)"
              strokeWidth={2}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
              }}
              labelFormatter={(label) => `${t("stats.statPay")}: ${label}`}
              formatter={(value) => [`${t("stats.statAm")}: ${value}`]}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default TotalDeposits;
