import type { INotification } from "utils/types/response/NotificationsRes";
import Notification from "./Notification";
import type { MutableRefObject } from "react";
import Loader from "./Loader";

const NotificationsWindow = ({
  data,
  NotificationsRef,
  refForLastNotification,
  isLoading,
}: {
  data: INotification[] | undefined;
  NotificationsRef: MutableRefObject<null>;
  refForLastNotification: any;
  isLoading: boolean;
}) => {
  return (
    <aside
      id="notificationWindow"
      ref={NotificationsRef}
      style={{
        direction: "rtl",
      }}
      className="top-6 overflow-y-auto overflow-x-hidden p-5 right-6 bg-[#101010] z-10 rounded-3xl text-[40px] text-white"
    >
      <p style={{ direction: "ltr" }}>Notification center</p>
      {isLoading ? (
        <Loader />
      ) : data?.length === 0 ? (
        <p style={{ direction: "ltr" }}>You don't have any notifications</p>
      ) : (
        data?.map((el, i) => (
          <Notification
            key={`${el.id} ${el.type}`}
            importance={el.importance}
            time={el.time}
            date={el.date}
            message={el.message}
            type={el.type}
            refForLastNotification={
              i + 1 === data.length ? refForLastNotification : undefined
            }
          />
        ))
      )}
    </aside>
  );
};

export default NotificationsWindow;
