import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import SpaceList from "../../Space/SpaceList/SpaceList.tsx";

import Cards from "image/p_menu/Cards.svg";
import Converter from "image/p_menu/Converter.svg";
// import Crypt from "/src/image/p_menu/Crypt.svg";
// import Dowt from "/src/image/p_menu/Dowt.svg";
import History from "image/p_menu/History.svg";
// import Messenger from "/src/image/p_menu/Messenger.svg";
import PeriodicSpends from "image/p_menu/PeriodicSpends.svg";
import Settings from "image/p_menu/Settings.svg";
import Shop from "image/p_menu/Shop.svg";
import Space from "image/p_menu/Space.svg";
import Statistics from "image/p_menu/Statistics.svg";
import Goals from "image/p_menu/Goals.svg";
import Admin from "image/p_menu/Admin.svg";
import Tickets from "image/p_menu/Tickets.svg";
import ConverterWindow from "./ConverterWindow";
import SettingsWindow from "./SettingsWindow";
import CryptoAndStocks from "./CryptoAndStocksWindow/CryptoAndStocks";
import GoalsWindow from "./GoalsWindow";
import DowtWindow from "./DowtWindow";
import PeriodicSpendsContainer from "./PeriodicSpendsWindow";
// import ShopWindowContainer from "./ShopWindow";
import HistoryWindow from "./HistoryWindow/HistoryWindow.tsx";
import eventEmitter from "services/EventEmitter.ts";
import ExcelButton from "./ExcelButton";
import TicketsWindow from "./TicketsWindow/TicketsWindow.tsx";
import { useTour } from "components/Tour/Hooks/useTour.tsx";
import CardsWindow from "./CardWindow/CardWindow.tsx";
import useStore from "store/store.ts";

interface MenuProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean | ShowingType>>;
  menuPos: { top: string; left: string };
  showMenu: boolean | ShowingType;
}

export type ShowingType =
  | "Menu"
  | "Converter"
  | "Settings"
  | "SpaceMenu"
  | "Goals"
  | "Cards"
  | "Crypto&Stocks"
  | "Dowt"
  | "Periodic spends"
  | "Shop"
  | "History"
  | "AdminPanel"
  | "Tickets";

const PremiumMenu = ({ setShowMenu, menuPos, showMenu }: MenuProps) => {
  interface Button {
    id: string;
    icon: string;
    onClick?: () => void;
    title: string;
  }
  const navigate = useNavigate();
  const { isEmployee } = useStore();
  const { tourRunning } = useTour();

  const [showing, setShowing] = useState<ShowingType>(
    typeof showMenu !== "boolean" ? showMenu : "Menu",
  );

  // in case if user gonna click on upgrade button in the alertAboutLimit
  const openShop = () => {
    navigate("/shop");
  };

  useEffect(() => {
    eventEmitter.on("openShop", openShop);
    return () => {
      eventEmitter.off("openShop", openShop);
    };
  }, []);

  const toggleSpaceMenu = () => {
    setShowing(showing === "SpaceMenu" ? "Menu" : "SpaceMenu");
    if (!(showing === "SpaceMenu")) {
      navigate("/space");
    }
  };

  // const toggleCryptoAndStocks = () => {
  //   setShowing(showing === "Crypto&Stocks" ? "Menu" : "Crypto&Stocks");
  // };

  const Buttons: (Button | string)[] = [
    {
      id: "settings",
      icon: Settings,
      title: "Settings",
      onClick: () => setShowing("Settings"),
    },
    {
      id: "converter",
      icon: Converter,
      title: "Converter",
      onClick: () => setShowing("Converter"),
    },
    {
      id: "goals",
      icon: Goals,
      title: "Goals",
      onClick: () => setShowing("Goals"),
    },
    {
      id: "statistics",
      icon: Statistics,
      onClick: () => navigate("/statistics"),
      title: "Statistics",
    },
    {
      id: "history",
      icon: History,
      onClick: () => setShowing("History"),
      title: "History",
    },
    {
      id: "shop",
      icon: Shop,
      title: "Shop",
      onClick: () => navigate("/shop"),
    },
    // {
    //   id: "ids.dowt",
    //   icon: Dowt,
    //   onClick: () => setShowing("Dowt"),
    //   title: "Dowt",
    // },
    "Excel button",
    {
      id: "ids.cards",
      icon: Cards,
      title: "Cards",
      onClick: () => setShowing("Cards"),
    },
    {
      id: "ids.periodic_spends",
      icon: PeriodicSpends,
      title: "Periodic spends",
      onClick: () => setShowing("Periodic spends"),
    },
    ...(isEmployee
      ? [
          {
            id: "space",
            icon: Space,
            onClick: toggleSpaceMenu,
            title: "Space",
          },
        ]
      : []),
    // {
    //   id: "ids.crypt",
    //   icon: Crypt,
    //   title: "Crypt",
    //   onClick: toggleCryptoAndStocks,
    // },
    // { id: "ids.messenger", icon: Messenger, title: "Messenger" },
    {
      id: "adminpanel",
      icon: Admin,
      title: "Admin",
      onClick: () => navigate("/admin-panel"),
    },
    {
      id: "tickets",
      icon: Tickets,
      title: "Tickets",
      onClick: () => setShowing("Tickets"),
    },
  ];

  const ref = useRef(null);
  const featureRef = useRef(null);

  const handleClickOutside = () => {
    if (!tourRunning) {
      setShowMenu(false);
      setShowing("Menu");
    }
  };

  useOnClickOutside(ref, handleClickOutside);
  const handleBack = () => setShowing("Menu");

  switch (showing) {
    case "Converter":
      return <ConverterWindow converterRef={featureRef} onBack={handleBack} />;
    case "Settings":
      return <SettingsWindow settingsRef={featureRef} onBack={handleBack} />;
    case "Goals":
      return <GoalsWindow goalsRef={featureRef} onBack={handleBack} />;
    case "Dowt":
      return <DowtWindow dowtRef={featureRef} onBack={handleBack} />;
    case "Periodic spends":
      return (
        <PeriodicSpendsContainer featureRef={featureRef} onBack={handleBack} />
      );
    case "Cards":
      return <CardsWindow cardRef={featureRef} onBack={handleBack} />;
    case "History":
      return <HistoryWindow historyRef={featureRef} onClose={handleBack} />;
    case "Tickets":
      return <TicketsWindow onBack={handleBack} />;
    default:
      return (
        <div
          ref={ref}
          id="premium_menu"
          className={`
            pointer-events-auto flex flex-col
            h-auto w-[5rem] absolute scale-90
            gap-[5.2%] rounded-3xl bg-[#1c1c1c] bg-opacity-70 px-[0.6rem] pb-3
            ${showing === "Menu" ? "top-[50%] -translate-y-[50%] left-2" : ""}
          `}
        >
          {showing === "SpaceMenu" ? (
            <SpaceList />
          ) : showing === "Crypto&Stocks" ? (
            <CryptoAndStocks onClose={() => setShowing("Menu")} />
          ) : (
            Buttons.map((button) =>
              typeof button === "string" ? (
                <ExcelButton key={"excel button"} />
              ) : (
                <button
                  onClick={button.onClick}
                  id={button.id}
                  key={button.id}
                  type="button"
                  title={button.title}
                  className={`flex h-[3.7rem] w-full items-center justify-center
                  rounded-[1rem] border border-[#727171]
                  bg-[#262626] hover:border-[#ffa800] ${
                    button.id === "ids.cards" ? "" : "mt-3"
                  }`}
                >
                  <img src={button.icon} alt="button" />
                </button>
              ),
            )
          )}
        </div>
      );
  }
};

export default PremiumMenu;
