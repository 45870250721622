import DOMPurify from "dompurify";
import {
  type currencyType,
  invertedCurrensies,
} from "utils/constants/currencies";
import AutoComplete from "components/LoginPage/ChooseCurrency/AutoComplete";
import type { UseFormSetValue, UseFormRegister } from "react-hook-form";
import { type SyntheticEvent, type FC } from "react";
import type { IFormInputs } from "./SettingsContainer";
import { useTour } from "components/Tour/Hooks/useTour";
import PlaybackSpeed from "image/Settings/PlaybackSpeed.svg";
import { useTranslation } from "react-i18next";

interface Props {
  isEditing: boolean;
  setValue: UseFormSetValue<IFormInputs>;
  register: UseFormRegister<IFormInputs>;
}

const AdditionalSettings: FC<Props> = ({ isEditing, setValue, register }) => {
  const { reStartTour } = useTour();
  const { t } = useTranslation();

  const currencyCode = JSON.parse(
    DOMPurify.sanitize(localStorage.getItem("space") as string),
  ).currency;

  const handleCurrencyClick = (e: SyntheticEvent<HTMLElement, Event>) => {
    setValue("currency", e.currentTarget.innerText as currencyType);
  };

  return (
    <div className="text-white mt-4 border border-[#121212] bg-[#1b1b1b] rounded-3xl">
      <div className="flex justify-between items-center py-3 px-6 border-b border-[#121212]">
        <div>
          <p className="text-[20px]">{t("startGuide")}</p>
          <p className="text-[#636363] text-[14px]">{t("guideDescription")}</p>
        </div>
        <button onClick={reStartTour}>
          <img src={PlaybackSpeed} alt="PlaybackSpeed" className="w-10" />
        </button>
      </div>
      <div className="py-3 px-6">
        {isEditing ? (
          <AutoComplete
            inputClass="inline-block box-content text-[20px] bg-[#101010] rounded-lg"
            placeholder={t("currencyPlaceholder")}
            register={register}
            onClickElement={handleCurrencyClick}
          />
        ) : (
          <p className="text-[20px]">{invertedCurrensies[currencyCode]}</p>
        )}
        <p className="text-[#636363] text-[14px]">{t("currencyLabel")}</p>
      </div>
    </div>
  );
};

export default AdditionalSettings;
