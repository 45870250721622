import {
  useState,
  useLayoutEffect,
  type ReactNode,
  type RefObject,
} from "react";
import { MapInteractionCSS } from "react-map-interaction";

const PageNavigation = ({
  children,
  childRef,
}: {
  children: ReactNode;
  childRef: RefObject<HTMLDivElement>;
}) => {
  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";
    if (childRef.current) {
      childRef.current.style.willChange = "transform";
      childRef.current.style.zIndex = "1";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const [value, setValue] = useState({
    scale: 0.8,
    translation: {
      x: window.innerWidth / 2 - 1300,
      y: window.innerHeight / 2 - 1300,
    },
  });

  const handleChange = ({ scale, translation }: typeof value) => {
    const { x, y } = translation;
    const bounds = 3100 * scale;
    const minX = window.innerWidth - bounds;
    const minY = window.innerHeight - bounds;

    const availableX = Math.max(minX, x);
    const availableY = Math.max(minY, y);

    setValue({
      scale: scale,
      translation: {
        x: availableX,
        y: availableY,
      },
    });
  };

  return (
    <MapInteractionCSS
      minScale={0.8}
      maxScale={3}
      translationBounds={{
        yMax: 0,
        xMax: 0,
      }}
      value={value}
      onChange={handleChange}
    >
      {children}
    </MapInteractionCSS>
  );
};

export default PageNavigation;
