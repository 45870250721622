import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import AppContainer from "AppContainer.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "i18n/i18n.ts";
import domain from "utils/constants/domain";
import AppTour from "components/Tour/AppTour";
import { TourProvider } from "components/Tour/Hooks/useTour";

const globalHandleError = (e: Error) => {
  // in case if server didn't respond and user is not in /error page - he'll redirect there
  if (
    e.message === "error.response is undefined" &&
    // TODO: change to the domain name in the release
    window.location.href !== `${domain}/error`
  ) {
    window.location.href = "/error";
  }
};

const queryCache = new QueryCache({
  onError: globalHandleError,
});
const mutationCache = new MutationCache({
  onError: globalHandleError,
});

export const queryClient = new QueryClient({ queryCache, mutationCache });

const clientId =
  "1026773878584-1ei93c6boeklt98a1ahbdq3f5mf78fuo.apps.googleusercontent.com";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={clientId}>
          <TourProvider>
            <AppTour />
            <AppContainer />
          </TourProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
