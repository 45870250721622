import check_mark_logo from "image/Goals/check_mark.svg";
import trash_logo from "image/Goals/trash.svg";
import edit_logo from "image/Goals/edit.svg";
import cl from "./Goal.module.scss";
import ProgressBar from "./ProgressBar";
import wallet_logo from "image/Goals/wallet.svg";
import type { goalI } from "./GoalsWindow";
import { useMutation } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import { queryClient } from "main";
import { useState, type Dispatch, type FC, type SetStateAction } from "react";
import Modal from "utils/components/Modal";
import EditGoal from "./EditGoal";
import showErrorMessage from "utils/constants/showErrorMessage";
import smalltalk from "utils/libraries/smalltalk";
import Block from "utils/components/Block/Block";
import { useTranslation } from "react-i18next";
import useStore from "store";
import TransferingFormContainer from "./TransferingFormContainer";

interface Props extends Omit<goalI, "collected" | "father_space"> {
  setEvent: Dispatch<SetStateAction<boolean>>;
  isBlocked: boolean;
}

const Goal: FC<Props> = ({
  title,
  collected_percentage,
  created_date,
  created_time,
  currency,
  goal_converted,
  id,
  goal,
  collected_converted,
  isBlocked,
}) => {
  const { t } = useTranslation();

  const { setShowSpinner } = useStore();
  const { mutate } = useMutation({
    mutationKey: ["delete goal"],
    mutationFn: () => {
      setShowSpinner(true);
      return GoalsAPI.deleteGoal(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["get goals"] }),
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false),
  });

  const [goalEvent, setGoalEvent] = useState<
    "transfering" | "editing" | "none"
  >("none");

  const actions = [
    {
      icon: check_mark_logo,
      onClick: () => {
        smalltalk
          .confirm(
            t("goal.confirmCompleteTitle"),
            t("goal.confirmCompleteMessage")
          )
          .then(mutate);
      },
    },
    { icon: edit_logo, onClick: () => setGoalEvent("editing") },
    {
      icon: trash_logo,
      onClick: () => {
        smalltalk
          .confirm(t("goal.confirmDeleteTitle"), t("goal.confirmDeleteMessage"))
          .then(mutate);
      },
    },
  ];

  return (
    <>
      <section className={cl.container}>
        {isBlocked && <Block />}
        <div className={cl.header}>
          <p>{title.length <= 18 ? title : `${title.slice(0, 15)}...`}</p>
          {actions.map((el, i) => (
            <button
              key={el.icon}
              type="button"
              className={`bg-[#3c3600cb] py-4 px-5 ${
                i === 2 ? "rounded-tr-2xl" : ""
              }`}
              onClick={el.onClick}
            >
              <img
                className="h-[25px]"
                src={el.icon}
                alt={t("goal.actionButton")}
              />
            </button>
          ))}
        </div>
        <article className="rounded-t-3xl mt-4">
          <p>{t("goal.creationDate")}</p>
          <p>{created_time}</p>
          <p className="ml-2">{created_date}</p>
        </article>
        <article>
          <p>{t("goal.balance")}</p>
          <p className="!bg-orange !text-black">{collected_converted}</p>
          <p className="ml-2">{currency}</p>
        </article>
        <article className="rounded-b-3xl">
          <p>{t("goal.progress")}</p>
          <ProgressBar
            firstLabel={collected_percentage}
            secondLabel={goal_converted}
            completed={collected_percentage}
          />
        </article>
        <button
          type="button"
          className="w-[90%] flex justify-center items-center rounded-xl mt-4 h-12 bg-[#242424]"
          onClick={() => setGoalEvent("transfering")}
        >
          <img src={wallet_logo} alt="wallet logo" className="h-[70%] mr-3" />
          <p className="text-xl text-[#777777]">{t("goal.addMoneyButton")}</p>
        </button>
      </section>
      {goalEvent === "transfering" ? (
        <Modal setActive={(arg) => setGoalEvent(arg ? "transfering" : "none")}>
          <TransferingFormContainer goalId={id} setGoalEvent={setGoalEvent} />
        </Modal>
      ) : goalEvent === "editing" ? (
        <Modal setActive={(arg) => setGoalEvent(arg ? "editing" : "none")}>
          <EditGoal
            goalId={id}
            goalTitle={title}
            goal={goal}
            setGoalEvent={setGoalEvent}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default Goal;
