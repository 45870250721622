import React from "react";
import cl from "./LoaderElement.module.scss";

const LittleLoaderElement: React.FC = () => {
  return (
    <div className={`${cl.loader} col-span-3`}>
      <div className={cl.spinner}></div>
    </div>
  );
};

export default LittleLoaderElement;
