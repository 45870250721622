export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export interface ChartOptions {
  responsive: boolean;
  maintainAspectRatio: boolean;
  layout: {
    padding: {
      left: number;
      right: number;
      top: number;
      bottom: number;
    };
  };
  plugins: {
    legend: { display: boolean };
    tooltip: { enabled: boolean };
  };
  scales: {
    x: {
      grid: { display: boolean };
      ticks: { font: { size: number } };
    };
    y: {
      beginAtZero: boolean;
      min: number;
      max: number;
      ticks: {
        stepSize: number;
        font: { size: number };
      };
      grid: { color: string };
    };
  };
}
