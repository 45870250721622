import { useQuery } from "@tanstack/react-query";
import Collaborators from "./Collaborators/Collaborators";
import MiniStatistics from "./MiniStatistics/MiniStatistics";
import Subsctiptions from "./Subscription/Subsctiptions";
import { SpaceAPI } from "services/API";
import getSpaceId from "utils/constants/getSpaceId";

interface User {
  username: string;
  tag: number;
  email: string;
}

const SubscriptionAndCollabs = () => {
  const { data: users, refetch } = useQuery<User[]>({
    queryKey: ["spaceMembers"],
    queryFn: async () => {
      const spaceIdNumber = getSpaceId();
      const data = await SpaceAPI.getSpaceMembers(spaceIdNumber);

      return data;
    },
    onError: (err) => {
      console.error("Error:", err);
    },
  });

  return (
    <div>
      <Subsctiptions />
      <MiniStatistics usersCount={users?.length || 0} />
      <Collaborators refetchSlots={refetch} usersCount={users?.length || 0} />
    </div>
  );
};

export default SubscriptionAndCollabs;
