import TicketPreview from "./TicketPreview";
import type { Ticket } from "utils/types/response/TicketsRes";
import type TicketInf from "./TicketInfType";

const Tickets = ({
  data,
  handleOpenClick,
}: {
  data: Ticket[];
  handleOpenClick?: (ticketInf: TicketInf) => void;
}) => {
  return (
    <>
      {data.map((ticket) => (
        <TicketPreview
          handleOpenClick={handleOpenClick}
          key={`ticket${ticket.id}`}
          {...ticket}
        />
      ))}
    </>
  );
};

export default Tickets;
