import { instance } from "./instances";
import type { Ticket, GetChatRes } from "utils/types/response/TicketsRes";

export const TicketsAPI = {
  getClosedTickets: () => instance.get<Ticket[]>("/tickets/closed/"),

  sendMessage: ({ text, id }: { text: string; id: number }) =>
    instance.post(`/tickets/${id}/`, { text }),

  getChat: (id: number) => instance.get<GetChatRes>(`/tickets/${id}/`),
  // employee
  getWaitingTickets: () => instance.get<Ticket[]>("/tickets/waiting/"),
  takeTicket: (ticketId: number) => instance.get(`/tickets/took/${ticketId}/`),
  closeTicket: (ticketId: number) =>
    instance.get(`/tickets/close/${ticketId}/`),
  // user
  getTickets: () => instance.get<Ticket[]>("/tickets/my_tickets/"),
  createTicket: (data: {
    help_in_space: boolean;
    space_pk: number;
    message: string;
    title: string;
  }) => instance.post("/tickets/create/", data),
};
