import Form from "./Form";
import { useMutation } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import { valSchema } from "utils/validators/Goals/CreateGoal";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { Dispatch, SetStateAction } from "react";
import { queryClient } from "main";
import showErrorMessage from "utils/constants/showErrorMessage";
import useStore from "store";

export interface IFormInputs {
  title: string;
  goal: string;
}

const FormContainer = ({
  setCreatingGoal,
}: {
  setCreatingGoal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setShowSpinner } = useStore();

  const { mutate, isPending } = useMutation({
    mutationFn: (data: { title: string; goal: number }) => {
      setShowSpinner(true);
      return GoalsAPI.createGoal(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get goals"] });
      setCreatingGoal(false);
    },
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = ({ title, goal }) => {
    mutate({ title, goal: Number.parseFloat(goal) });
  };

  return (
    <Form
      isPending={isPending}
      setCreatingGoal={setCreatingGoal}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default FormContainer;
