import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import cl from "./members_menu.module.scss";
import add_member_logo from "image/spaces_p/AddUserIcon.svg";

const MembersMenu = ({ canEditPermission }: { canEditPermission: boolean }) => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const [members, setMembers] = useState([]);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const spaceIdNumber = Number(spaceId);
        if (!isNaN(spaceIdNumber)) {
          const membersData = await SpaceAPI.getSpaceMembers(spaceIdNumber);
          setMembers(membersData);
        } else {
          console.error("Invalid space ID");
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, [spaceId]);

  const handleAddMember = async () => {
    try {
      setError(false);
      const spaceIdNumber = Number(spaceId);
      if (!isNaN(spaceIdNumber) && newMemberEmail) {
        await SpaceAPI.addMemberToSpace(spaceIdNumber, newMemberEmail);
        const data = await SpaceAPI.getSpaceMembers(spaceIdNumber);
        setMembers(data);
        setNewMemberEmail("");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleEditPermission = (memberId) => {
    navigate(`/space/${spaceId}/members/${memberId}/edit`);
  };

  return (
    <div className={cl.container}>
      <div className={cl.titleContainer}>
        <div className={cl.inputWrapper}>
          <input
            type="text"
            placeholder={error ? "Invalid email" : "Add new member to space"}
            className={`${cl.titleInput} ${error ? cl.errorInput : ""}`}
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
          />
          <button className={cl.searchButton} onClick={handleAddMember}>
            <img
              src={add_member_logo}
              alt="Add Member"
              className={cl.searchIcon}
            />
          </button>
        </div>
      </div>
      <ul className={cl.ul}>
        {members.map((member) => (
          <li key={member.id}>
            <div className={cl.username}>
              {member.username}#{member.tag}
              {canEditPermission && (
                <button
                  className={cl.editButton}
                  onClick={() => handleEditPermission(member.id)}
                >
                  Edit permissions
                </button>
              )}
            </div>
            <div className={cl.email}>{member.email}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MembersMenu;
