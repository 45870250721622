import React, { useState, useEffect } from "react";
import LicenseElement from "./LicenseElement";
import PlanElement from "./PlanElement";
import useStore from "store/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Subscriptions = () => {
  const { subscription } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderSubscriptionElements = () => {
    switch (subscription) {
      case "business_plan" || "business_member_plan":
        return (
          <>
            <PlanElement text={t("plans.yours")} />
            <LicenseElement text={t("plans.upgrade")} />
          </>
        );
      case "business_lic" || "business_member_lic":
        return (
          <>
            <LicenseElement text={t("plans.yours")} />
            <PlanElement text={t("plans.upgrade")} />
          </>
        );
      default:
        return (
          <div
            onClick={() => navigate("/shop/")}
            className="w-[88vh] h-[29vh] bg-[#181818] relative rounded-lg overflow-hidden flex items-center justify-center text-white text-xl font-bold cursor-pointer"
          >
            {t("plans.no_plan")}
          </div>
        );
    }
  };

  return (
    <div className="flex flex-row gap-4 mb-4">
      {renderSubscriptionElements()}
    </div>
  );
};

export default Subscriptions;
