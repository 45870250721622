import { z } from "zod";
import { useTranslation } from "react-i18next";

const getLength = (arr: string[] | null) => (arr ? arr.length : 0);

const defLogObject = (t: Function) => ({
  email: z
    .string()
    .email({ message: t("validation2.email.invalid") })
    .max(50, { message: t("validation2.email.tooLong") })
    .min(5, { message: t("validation2.email.tooShort") })
    // Small step towards XSS protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: t("validation2.email.prohibitedChars"),
    }),
  // better add the passwordObj from the defObject
  password: z
    .string()
    .max(24, { message: t("validation2.password.length") })
    .min(8, { message: t("validation2.password.length") })
    .refine(
      (value) => {
        const letters: string[] | null = value.match(/[a-zA-Z]/g);
        const numbers: string[] | null = value.match(/[0-9]/g);
        return getLength(letters) >= 1 && getLength(numbers) >= 1;
      },
      {
        message: t("validation2.password.requirements"),
      },
    ),
});

export const getValSchema = (isLogining: boolean) => {
  const { t } = useTranslation();

  if (isLogining) {
    return z.object(defLogObject(t));
  }

  return z.object({
    ...defLogObject(t),
    license: z.literal(true, {
      errorMap: () => ({
        message: t("validation2.license.notAccepted"),
      }),
    }),
  });
};
