import Form from "./Form";
import { useMutation } from "@tanstack/react-query";
import { valSchema } from "utils/validators/PeriodicSpends/createPayment";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { Dispatch, SetStateAction } from "react";
import { queryClient } from "main";
import useStore from "store";
import showErrorMessage from "utils/constants/showErrorMessage";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import { createSpendBody } from "../localUtils";

export interface IFormInputs {
  title: string;
  amount: string;
  account_pk: string;
  category_pk: string;
  day: string;
}

const FormContainer = ({
  setCreatingPayment,
}: {
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setShowSpinner } = useStore();
  const { accounts, categories } = useStore();

  const { mutate, isPending } = useMutation({
    mutationKey: ["create periodic payment"],
    mutationFn: (data) => {
      setShowSpinner(true);
      return PeriodicSpendsAPI.createPayment(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get periodic spends"] });
      setCreatingPayment(false);
    },
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false),
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    mutate(createSpendBody(data));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
    defaultValues: {
      day: "M",
    },
  });

  return (
    <Form
      isLoading={isPending}
      setCreatingPayment={setCreatingPayment}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      accounts={accounts}
      categories={categories}
    />
  );
};

export default FormContainer;
