import React, { Dispatch, SetStateAction } from "react";
import Modal from "utils/components/Modal";
import Title from "../../Circles/AccountCircle/CreateAccount/Title.tsx";
import Check from "../../../../image/Account/Check.svg";
import { createPortal } from "react-dom";
import TransactionsFormContainer from "./TransactionFormContainer.tsx";
import { useTranslation } from "react-i18next";

type OutgoingModalProps = {
  setModal: Dispatch<SetStateAction<boolean>>;
  categoryId: number;
};

const OutgoingModal: React.FC<OutgoingModalProps> = ({
  setModal,
  categoryId,
}) => {
  const { t } = useTranslation();

  return createPortal(
    <Modal setActive={setModal}>
      <div id="SpendModal">
        <Title
          image={Check}
          header={t("outgoingModal.enterExpense")}
          title={t("outgoingModal.enterDetails")}
          className="rounded-tr-[24px] h-40"
        />
        <TransactionsFormContainer
          setModal={setModal}
          categoryId={categoryId}
        />
      </div>
    </Modal>,
    document.body,
  );
};

export default OutgoingModal;
