import { useQuery } from "@tanstack/react-query";
import { SpaceAPI } from "services/API";
import { ProfileAPI } from "services/API/Profile";
import getSpaceId from "utils/constants/getSpaceId";
import { User } from "./types";

export const useMyEmail = () => {
  return useQuery<string>({
    queryKey: ["myEmail"],
    queryFn: async () => {
      const res = await ProfileAPI.ProfileView();
      return res.data.email;
    },
    onError: (err) => console.error("Error:", err),
  });
};

export const useUsers = () => {
  return useQuery<User[]>({
    queryKey: ["spaceMembers"],
    queryFn: async () => {
      const spaceIdNumber = getSpaceId();
      const data = await SpaceAPI.getSpaceMembers(spaceIdNumber);
      return data;
    },
    onError: (err) => console.error("Error:", err),
  });
};
