import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useSpring, animated } from "@react-spring/web";
import { useTranslation } from "react-i18next"; // Импорт перевода
import cl from "./Collaborator.module.scss";
import CollaboratorElement from "./CollaboratorElement";
import { useUsers, useMyEmail } from "./useUserManagement";
import { useCollaborators } from "./useCollaborators";
import AddUser from "/src/image/AdminPanel/AddUser.svg";
import Done from "/src/image/AdminPanel/Done.svg";
import LittleLoader from "utils/components/Loader/LittleLoader";
import getSpaceSlots from "utils/constants/getSpaceSlots";

const Collaborators: React.FC = () => {
  const { t } = useTranslation(); // Подключение `useTranslation`
  const slots = getSpaceSlots();

  const {
    data: users = [],
    isLoading,
    error: usersError,
    refetch,
  } = useUsers();

  const {
    data: myEmail,
    isLoading: isEmailPending,
    error: userError,
  } = useMyEmail();

  const {
    isAddingUser,
    setIsAddingUser,
    newMemberEmail,
    setNewMemberEmail,
    emailError,
    setEmailError,
    handleAddUser,
    handleSaveUser,
    enhancedUsers,
  } = useCollaborators(users, myEmail, refetch, slots);

  const emailInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isAddingUser && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [isAddingUser]);

  const animationProps = useSpring({
    opacity: isAddingUser ? 1 : 0,
    transform: isAddingUser ? "translateY(0)" : "translateY(-20px)",
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSaveUser();
    } else if (event.key === "Escape") {
      setIsAddingUser(false);
      setNewMemberEmail("");
      setEmailError("");
    }
  };

  if (isLoading || isEmailPending) {
    return <LittleLoader />;
  }

  if (usersError || userError) {
    return <div>{usersError?.message || userError?.message}</div>;
  }

  return (
    <motion.div
      className={cl.collaboratorsContainer}
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.005,
        transition: { duration: 0.5 },
      }}
    >
      <div className={cl.collaboratorsHeader}>
        <div className={cl.collaboratorsTitle}>
          {t("collaborators.title")}
          <span className={cl.tooltip}>{t("collaborators.tooltip")}</span>
        </div>
        {isAddingUser ? (
          <button onClick={handleSaveUser}>
            <img
              src={Done}
              alt={t("collaborators.done")}
              className={cl.saveUserIcon}
            />
          </button>
        ) : (
          <button onClick={handleAddUser}>
            <img
              src={AddUser}
              alt={t("collaborators.addUser")}
              className={cl.addUserIcon}
            />
          </button>
        )}
      </div>
      {isAddingUser && (
        <>
          {emailError && (
            <div className={cl.emailErrorMessage}>{emailError}</div>
          )}
          <animated.input
            ref={emailInputRef}
            type="email"
            value={newMemberEmail}
            onChange={(e) => {
              setNewMemberEmail(e.target.value);
              setEmailError("");
            }}
            onKeyDown={handleKeyPress}
            placeholder={t("collaborators.enterEmail")}
            className={cl.addUserInput}
            style={animationProps}
          />
        </>
      )}
      <div className={cl.collaboratorsList}>
        {enhancedUsers.map((user) => (
          <CollaboratorElement
            key={user.email}
            username={user.username}
            email={user.email}
            tag={user.tag}
            me={user.me}
            refetch={async () => {
              await refetch();
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Collaborators;
