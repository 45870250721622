import Form from "./Form";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useRef } from "react";
import { valSchema } from "utils/validators/Tickets/createTicket";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomAlertContext from "CusomAlertContext";
import { useMutation } from "@tanstack/react-query";
import { TicketsAPI } from "services/API/Tickets";
import getSpaceId from "utils/constants/getSpaceId";
import { queryClient } from "main";
import useStore from "store";
import showErrorMessage from "utils/constants/showErrorMessage";

export interface Inputs {
  neededHelp: boolean;
  title: string;
  message: string;
}

const FormContainer = ({
  handleBackClick,
}: {
  handleBackClick: () => void;
}) => {
  const customAlert = useContext(CustomAlertContext);
  const { setShowSpinner } = useStore();

  const { mutate } = useMutation({
    mutationFn: TicketsAPI.createTicket,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get tickets"],
      });
    },
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(valSchema),
  });

  useEffect(() => {
    const errValues = Object.values(errors);
    if (errValues.length) {
      customAlert(errValues[0].message as string);
    }
  }, [errors]);

  const onSubmit = handleSubmit(({ neededHelp, title, message }) => {
    handleBackClick();
    setShowSpinner(true);
    mutate({
      message,
      title,
      help_in_space: neededHelp,
      space_pk: getSpaceId(),
    });
  });

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const onTextAreaChange = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "28px";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  return (
    <Form
      register={register}
      textAreaRef={textAreaRef}
      onTextAreaChange={onTextAreaChange}
      onSubmit={onSubmit}
    />
  );
};

export default FormContainer;
