import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import cl from "./SlotsStat.module.scss";
import AddLicense from "/src/image/AdminPanel/AddLicense.svg";
import useStore from "store/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Slots4CaloboratorsProps {
  current: number;
  max: number;
}

const Slots4Caloborators: React.FC<Slots4CaloboratorsProps> = ({
  current,
  max,
}) => {
  const progress = (current / max) * 100;
  const { subscription } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate("/shop");
  };

  const progressBarVariants = {
    initial: { opacity: 0, width: 0 },
    animate: {
      opacity: 1,
      width: `${progress}%`,
      transition: {
        duration: 0.8,
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const iconVariants = {
    rest: { scale: 1, rotate: 0 },
    hover: {
      scale: 1.1,
      rotate: 5,
      transition: { type: "spring", stiffness: 300 },
    },
  };

  const valueVariants = {
    initial: { scale: 0.8, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5, type: "spring", stiffness: 100 },
    },
    whileHover: {
      scale: 1.1,
      transition: { duration: 0.3, type: "spring", stiffness: 150 },
    },
  };

  return (
    <motion.div
      className={cl.container}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.005,
        transition: { duration: 0.3 },
      }}
    >
      {subscription === "business_lic" && (
        <div className={cl.iconContainer}>
          <motion.button
            onClick={handleIconClick}
            className={cl.iconButton}
            variants={iconVariants}
            initial="rest"
            whileHover="hover"
          >
            <motion.img
              src={AddLicense}
              className={cl.dollarIcon}
              alt="Add License"
              whileHover={{ scale: 1.1 }}
            />
          </motion.button>
          <div className={cl.tooltip}>{t("stats.buy")}</div>
        </div>
      )}

      <motion.div
        className={`${cl.value} ${cl.collaboratorsTitle}`}
        variants={valueVariants}
        initial="initial"
        animate="animate"
        whileHover="whileHover"
      >
        {current} / {max}
        <div className={cl.tooltip}>{t("stats.description4")}</div>
      </motion.div>

      <motion.div
        className={cl.title}
        initial={{ scale: 1 }}
        whileHover={{
          scale: 1.05,
          color: "#FFF",
          transition: { duration: 0.3 },
        }}
      >
        {t("stats.title3")}
      </motion.div>

      <div className={cl.progressBar}>
        <motion.div
          className={cl.progress}
          variants={progressBarVariants}
          initial="initial"
          animate="animate"
        />
      </div>
    </motion.div>
  );
};

export default Slots4Caloborators;
