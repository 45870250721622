import React from "react";
import smalltalk from "utils/libraries/smalltalk";

interface WithLoaderProps {
  errors: { [key: string]: { message: string } };
}

const withErrorShow = <P extends object>(Component: React.ComponentType<P>) => {
  return class WithLoaderComponent extends React.Component<
    P & WithLoaderProps
  > {
    componentDidUpdate(prevProps: Readonly<P & WithLoaderProps>): void {
      const { errors } = this.props;
      if (errors !== prevProps.errors) {
        if (Object.keys(errors).length) {
          smalltalk.alert("Error", Object.values(errors)[0].message);
        }
      }
    }
    render() {
      return <Component {...(this.props as P)} />;
    }
  };
};
export default withErrorShow;
