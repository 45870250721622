import React from "react";
import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Login/ChooseCurrency";
import { Navigate, useNavigate } from "react-router-dom";
import useStore from "store/store";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { currenciesNames, currencies } from "utils/constants/currencies";
import ChooseCurrency from "./ChooseCurrency";
import smalltalk from "utils/libraries/smalltalk";
import { useTour } from "components/Tour/Hooks/useTour";

const ChooseCurrencyContainer = () => {
  const { code, setCurrency } = useStore((store) => store);
  if (code === "") {
    return <Navigate to="/login" />;
  }

  type Input = {
    currency: string;
  };

  const verifEmailReq = useMutation({
    mutationKey: ["verify email"],
    mutationFn: ({ code, currency }: { code: string; currency: string }) =>
      AuthAPI.verifyEmail(code, currency),
    onError: (error) => smalltalk.alert("Error", error.response.data.detail),
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Input>({
    resolver: zodResolver(valSchema),
  });

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const newCurrency = currencies[data.currency];
    setCurrency(newCurrency);
    await verifEmailReq.mutateAsync({ code, currency: newCurrency });
    navigate("/login");
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;
    setValue("currency", target.dataset.text || target.innerText, {
      shouldValidate: true,
    });
  };
  return (
    <ChooseCurrency
      errors={errors}
      isLoading={verifEmailReq.isPending}
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      currenciesNames={currenciesNames}
      onMenuClick={onMenuClick}
    />
  );
};

export default ChooseCurrencyContainer;
