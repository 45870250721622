import React, { useState, type RefObject } from "react";
import Goal from "./Goal";
import plus_logo from "image/Goals/plus.svg";
import { useQuery } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import type { currencies } from "utils/constants/currencies";
import Modal from "utils/components/Modal";
import CreateGoal from "./CreateGoal";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import useStore from "store";
import { useTranslation } from "react-i18next";
import usePanelResizing from "utils/hooks/usePanelResizing";
import LittleLoader from "utils/components/Loader/LittleLoader";

export interface goalI {
  id: number;
  collected: number;
  collected_converted: string;
  collected_percentage: string;
  created_date: string;
  created_time: string;
  currency: keyof typeof currencies;
  father_space: number;
  goal: string;
  goal_converted: string;
  title: string;
}

interface GoalsWindowProps {
  goalsRef: RefObject<HTMLElement>;
  onBack: () => void;
}

const GoalsWindow = ({ goalsRef, onBack }: GoalsWindowProps) => {
  const { t } = useTranslation();
  const { data, isPending } = useQuery({
    queryKey: ["get goals"],
    queryFn: () => GoalsAPI.getGoals(),
  });

  const [creatingGoal, setCreatingGoal] = useState<boolean>(false);

  useSetCoolScroll(goalsRef);
  usePanelResizing(goalsRef);

  const {
    userRestrictions: { goals: limit },
  } = useStore();

  const isLimitReached = data?.data.length >= limit;

  return (
    <aside
      ref={goalsRef}
      className="bg-[#121212] font-vela overflow-hidden rounded-3xl flex flex-col relative top-[2.3%] left-[1.5%] shadow-md shadow-[#FFA80080]"
    >
      <div className="flex-grow overflow-y-auto">
        <div className="flex flex-col items-center justify-start p-4">
          <div className="w-full top-0 z-10 bg-[#121212] pt-4 pb-2">
            <div className="flex items-center justify-between w-[90%] mx-auto relative">
              <button
                onClick={onBack}
                type="button"
                className="absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
              >
                ←
              </button>
              <h2 className="text-[20px] text-center flex-grow text-white">
                {t("features.goals.name")}
              </h2>
            </div>
          </div>
          {isPending ? (
            <LittleLoader />
          ) : (
            data.data.map((goal: goalI, index) => (
              <Goal
                title={goal.title}
                created_date={goal.created_date}
                created_time={goal.created_time}
                key={goal.id}
                currency={goal.currency}
                goal_converted={goal.goal_converted}
                collected_percentage={goal.collected_percentage}
                id={goal.id}
                goal={goal.goal}
                collected_converted={goal.collected_converted}
                isBlocked={index + 1 > limit}
              />
            ))
          )}
        </div>
        {!isLimitReached && !isPending && (
          <div className="bottom-0 bg-[#121212] py-4">
            <button
              className="m-auto block w-auto h-auto"
              onClick={() => setCreatingGoal(true)}
              type="button"
            >
              <img src={plus_logo} className="!rounded-full" alt="add goal" />
            </button>
          </div>
        )}
      </div>

      {creatingGoal && (
        <Modal setActive={setCreatingGoal}>
          <CreateGoal setCreatingGoal={setCreatingGoal} />
        </Modal>
      )}
    </aside>
  );
};

export default GoalsWindow;
