import React, { useState, useCallback, useMemo } from "react";
import arrowIcon from "image/Cards/arrow_down.svg";
import refreshIcon from "image/Cards/Refresh.svg";
import arrowUpIcon from "image/Cards/WhiteArrowUp.svg";
import trashIcon from "image/Cards/trash.svg";
import currencyToSymbolMap from "currency-symbol-map";
import { BalanceDisplay } from "./subcomponents/BalanceDisplay";
import { LabeledValue } from "./subcomponents/LabeledValue";
import { ActionButton } from "./subcomponents/ActionButton";
import type { ConnectedBank } from "utils/types/response/CardsRes";
import smalltalk from "utils/libraries/smalltalk";
import { useMutation } from "@tanstack/react-query";
import CardsAPI from "services/API/Cards";
import showErrorMessage from "utils/constants/showErrorMessage";
import { queryClient } from "main";
import getSpaceId from "utils/constants/getSpaceId";
import { useTranslation } from "react-i18next";

interface Props {
  card: ConnectedBank;
}

const CardItem: React.FC<Props> = ({ card }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const spaceId = getSpaceId();

  const toggleDetails = useCallback(() => {
    setIsAnimating(true);
    setShowDetails((prev) => !prev);
  }, []);

  const handleAnimationEnd = useCallback(() => setIsAnimating(false), []);

  const DeleteAccountReq = useMutation({
    mutationKey: ["delete card"],
    mutationFn: () =>
      CardsAPI.deleteAccount({ accountId: card.accountId, spaceId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["get connected banks"],
      });
    },
    onError: showErrorMessage,
  });

  const handleRemove = () => {
    smalltalk
      .confirm(
        t("cardItem.confirmDeleteTitle"),
        t("cardItem.confirmDeleteMessage")
      )
      .then(() => {
        DeleteAccountReq.mutate();
      });
  };

  const RefreshAccountReq = useMutation({
    mutationKey: ["refresh card"],
    mutationFn: () =>
      CardsAPI.refreshAccount({ account_id: card.accountId, spaceId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["get connected banks"],
      });
    },
    onError: showErrorMessage,
  });

  const handleRefresh = () => {
    smalltalk
      .confirm(
        t("cardItem.confirmRefreshTitle"),
        t("cardItem.confirmRefreshMessage")
      )
      .then(() => {
        RefreshAccountReq.mutate();
      });
  };

  const currencySymbol = useMemo(
    () => currencyToSymbolMap(card.currency) || "",
    [card.currency]
  );

  return (
    <div className="bg-[#1E1E1E] p-8 rounded-3xl mb-3 transform transition-all duration-300 ease-in-out">
      <div className="flex justify-between items-center">
        <BalanceDisplay
          balance={card.balance}
          currencySymbol={currencySymbol}
          name={card.accountIban}
        />
        {!showDetails && (
          <button
            onClick={toggleDetails}
            className="text-red-500 p-2 rounded-full transform transition-all duration-200
                     hover:scale-110 hover:bg-[#2a2a2a] active:scale-95"
            aria-label={t("cardItem.showDetails")}
          >
            <img
              className="h-[18px] transition-transform duration-200 hover:rotate-180"
              src={arrowIcon}
              alt={t("cardItem.showDetails")}
            />
          </button>
        )}
      </div>

      <div
        className={`
          overflow-hidden transition-all duration-300 ease-in-out
          ${showDetails ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"}
          ${isAnimating ? "animate-slideDown" : ""}
        `}
        onTransitionEnd={handleAnimationEnd}
      >
        <div className="mt-4 text-lg transform transition-all duration-300 translate-y-0">
          <LabeledValue label={t("cardItem.currency")} value={card.currency} />
          <LabeledValue
            label={t("cardItem.incomes")}
            value={card.income?.toLocaleString() || "0"}
            prefix={`${currencySymbol} `}
          />
          <LabeledValue
            label={t("cardItem.spends")}
            value={card.expenses?.toLocaleString() || "0"}
            prefix={`${currencySymbol} `}
          />
          <LabeledValue
            label={t("cardItem.attachmentDate")}
            value={RefreshAccountReq.isPending ? "Invalid Date" : card.created}
          />

          <div className="flex justify-between mt-6 gap-4">
            <ActionButton
              icon={trashIcon}
              label={isDeleting ? t("cardItem.deleting") : t("cardItem.delete")}
              onClick={handleRemove}
            />
            <ActionButton
              icon={refreshIcon}
              disabled={RefreshAccountReq.isPending}
              label={t("cardItem.refresh")}
              onClick={handleRefresh}
            />
            <ActionButton
              icon={arrowUpIcon}
              label={t("cardItem.fold")}
              onClick={toggleDetails}
              iconSize="16px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CardItem.displayName = "CardItem";

export const cardItemStyles = {
  "@keyframes slideDown": {
    "0%": {
      transform: "translateY(-10px)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0)",
      opacity: 1,
    },
  },
  ".animate-slideDown": {
    animation: "slideDown 0.3s ease-out",
  },
};

export default CardItem;
