import trash_logo from "image/Goals/trash.svg";
import edit_logo from "image/Goals/edit.svg";
import cl from "./PeriodicSpends.module.scss";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "main";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { useState, type FC } from "react";
import Modal from "utils/components/Modal";
import showErrorMessage from "utils/constants/showErrorMessage";
import type { Spend } from "utils/types/response/PeriodicSpendsRes";
import safe from "image/expenses/Safe.svg";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import EditSpend from "./EditSpend";
import smalltalk from "utils/libraries/smalltalk";
import Block from "utils/components/Block/Block";
import { useTranslation } from "react-i18next";
import useStore from "store"; // Добавлен импорт useStore

interface Props extends Spend {
  isBlocked: boolean;
}

const Spend: FC<Props> = ({
  title,
  id,
  schedule,
  day,
  amount,
  currency,
  category_icon,
  category,
  account,
  isBlocked,
}) => {
  const { t } = useTranslation();
  const { setShowSpinner } = useStore(); // Добавлено получение setShowSpinner

  const { mutate, isPending } = useMutation({
    mutationKey: ["delete periodic spend"],
    mutationFn: (spendId) => {
      setShowSpinner(true); // Включаем спиннер
      return PeriodicSpendsAPI.deleteSpend(spendId);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["get periodic spends"] }),
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false), // Выключаем спиннер после завершения
  });
  const [editingSpend, setEditingSpend] = useState<boolean>(false);

  const actions = [
    { icon: edit_logo, onClick: () => setEditingSpend(true) },
    {
      icon: trash_logo,
      onClick: () => {
        smalltalk
          .confirm(
            t("spend.deleteConfirmationTitle"),
            t("spend.deleteConfirmationMessage")
          )
          .then(() => mutate(id));
      },
    },
  ];

  return (
    <>
      <section className={cl.container}>
        {isBlocked && <Block />}
        <>
          <div className="flex w-full rounded-t-2xl">
            <p
              className="bg-orange p-3 rounded-tl-2xl flex-1 text-black font-bold text-[25px] font-vela"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </p>
            {actions.map((el, i) => (
              <button
                key={el.icon}
                type="button"
                className={`bg-[#3c3600cb] duration-300 hover:bg-[#2b2a009f] py-4 px-5  ${
                  i === actions.length - 1 ? "rounded-tr-2xl" : ""
                }`}
                onClick={el.onClick}
              >
                <img className="h-[25px]" src={el.icon} alt="action button" />
              </button>
            ))}
          </div>
          <article className="rounded-t-3xl mt-4">
            <p>{t("spend.periodicity")}</p>
            <p>{schedule}</p>
            <p className="ml-2">{day}</p>
          </article>
          <article>
            <p>{t("spend.amount")}</p>
            <p className="!bg-orange !text-black">{amount}</p>
            <p className="ml-2">{currency}</p>
          </article>
          <article className="rounded-b-3xl">
            <p>{t("spend.info")}</p>
            <p className="!inline">
              <img
                src={safe}
                className="h-6 inline relative top-[10%] mr-2 mx-auto"
                alt="safe logo"
              />
              {account}
            </p>
            <p className="ml-2 !inline">
              <img
                src={`https://spendsplif.com/icons/${category_icon}.svg`}
                className="h-6 inline relative top-[10%] mr-2 mx-auto"
                alt="category logo"
              />
              {category}
            </p>
          </article>
        </>
      </section>
      {editingSpend && (
        <Modal setActive={setEditingSpend}>
          <EditSpend
            spendAmount={amount}
            spendTitle={title}
            spendId={id}
            setEditingSpend={setEditingSpend}
            accountName={account}
            categoryName={category}
          />
        </Modal>
      )}
    </>
  );
};

export default Spend;
