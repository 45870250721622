import { useSpring, animated } from "@react-spring/web";
import type { CustomAlertOptions } from "CusomAlertContext";
import type { Dispatch, SetStateAction, FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  setActive: Dispatch<SetStateAction<boolean | "limit alert">>;
  text: string;
  AdditionalButton?: React.ReactNode;
}

const SubscriptionLimitMessage: FC<Props> = ({
  setActive,
  text,
  AdditionalButton,
  handleUpdateClick,
}) => {
  const springs = useSpring({
    from: { transform: "scale(0)" },
    to: { transform: "scale(1)" },
  });
  const { t } = useTranslation();

  return (
    <animated.main
      className="text-white p-5 text-xl rounded-2xl grid gap-x-3 gap-y-2 grid-cols-[repeat(3,auto)]"
      style={{ ...springs }}
    >
      <p className="max-w-[500px] w-[70dvw] col-[1/4]">{text}</p>
      {AdditionalButton && <>{AdditionalButton}</>}
      <button
        onClick={() => {
          setActive(false);
          handleUpdateClick();
        }}
        className="hover:bg-[#e09300] duration-300 bg-orange text-lg text-black rounded-xl px-5 col-[3/4] py-1 "
      >
        {t("update")}
      </button>
    </animated.main>
  );
};
export default SubscriptionLimitMessage;
