import { useMutation, useQuery } from "@tanstack/react-query";
import airplane from "image/Tickets/airplane.svg";
import { TicketsAPI } from "services/API/Tickets";
import { type SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import MessagesPart from "./MessagesPart";
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  useContext,
} from "react";
import { queryClient } from "main";
import { compareAsc, parseISO } from "date-fns";
import { ProfileAPI } from "services/API/Profile";
import useStore from "store";
import LocalLoader from "utils/components/LocalLoader";
import showErrorMessage from "utils/constants/showErrorMessage";
import CustomAlertContext from "CusomAlertContext";

interface Input {
  text: string;
}

const TicketChat = ({
  onBack,
  id,
  title,
  ePalLogo,
}: {
  onBack: () => void;
  id: number | undefined;
  title: string | undefined;
  ePalLogo: string;
}) => {
  const { setShowSpinner } = useStore();
  const [pendingForMessages, setPendingForMessages] = useState(true);
  const [pendingForProfile, setPendingForProfile] = useState(true);
  const showCustomAlert = useContext(CustomAlertContext);

  const { data } = useQuery({
    enabled: !!id,
    queryFn: () =>
      TicketsAPI.getChat(id as number)
        .then((data) => data.data)
        .finally(() => setPendingForMessages(false)),
    queryKey: ["get a ticket chat"],
  });

  const { data: email } = useQuery({
    queryFn: () =>
      ProfileAPI.ProfileView()
        .then((res) => res.data.email)
        .finally(() => setPendingForProfile(false)),
    queryKey: ["get profile for ticket chat"],
  });

  const { mutate, isPending } = useMutation({
    mutationFn: TicketsAPI.sendMessage,
    onError: showErrorMessage,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["get a ticket chat"],
      });
      setShowSpinner(false);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Input>({
    resolver: zodResolver(
      z.object({
        text: z
          .string()
          .max(800, { message: "the message is too large" })
          .min(2, { message: "the message is too short" }),
      }),
    ),
  });

  useEffect(() => {
    if (Object.keys(errors).length) {
      showCustomAlert(Object.values(errors)[0].message as string);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<Input> = ({ text }) => {
    setShowSpinner(true);
    mutate({ text, id: id as number });
    reset();
  };

  const messagesRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [data]);

  const sortedDates = useMemo(
    () =>
      data
        ? Object.keys(data).sort((a, b) => compareAsc(parseISO(a), parseISO(b)))
        : [],
    [data],
  );

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const onTextAreaChange = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "56px";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  if (pendingForProfile || pendingForMessages) {
    return <LocalLoader beforeBg="24,24,24" />;
  }

  return (
    <div className="h-full flex items-start flex-col relative">
      <section className="flex justify-between text-2xl w-full items-center">
        <button onClick={onBack}>←</button>
        <p className="font-bold">{title}</p>
        <span className="bg-[#161616] py-2 px-4 rounded-xl">{ePalLogo}</span>
      </section>
      <section
        ref={messagesRef}
        style={{
          scrollbarWidth: "none",
        }}
        className="flex flex-col gap-y-4 overflow-y-auto overflow-x-hidden mt-auto w-full"
      >
        {email &&
          sortedDates.map((date, i) => (
            <Fragment key={`messagePart${i}`}>
              <MessagesPart
                ePalLogo={ePalLogo}
                email={email}
                date={date}
                messages={data[date]}
              />
            </Fragment>
          ))}
      </section>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full py-4 flex items-center"
        autoComplete="off"
      >
        <textarea
          className="bg-[#323232] w-full outline-none h-14 resize-none text-white placeholder:text-white p-3 text-center rounded-xl text-lg"
          placeholder="Type something..."
          style={{
            scrollbarWidth: "none",
          }}
          {...register("text", { onChange: onTextAreaChange })}
          ref={(e) => {
            register("text").ref(e);
            textAreaRef.current = e;
          }}
          minLength={2}
          maxLength={800}
          disabled={isPending}
        />
        <button
          disabled={isPending}
          type="submit"
          className="h-[60%] max-h-9 pl-3 disabled:cursor-wait"
        >
          <img className="size-full" src={airplane} />
        </button>
      </form>
    </div>
  );
};

export default TicketChat;
