import { useEffect, useState, type FC, type RefObject } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ProfileAPI } from "services/API/Profile";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { IProfileEditReq } from "utils/types/request/ProfileReq";
import { queryClient } from "main";
import useStore from "store/store";
import Settings from "./Settings";
import { valSchema } from "utils/validators/Profile/EditProfile";
import { zodResolver } from "@hookform/resolvers/zod";
import showErrorMessage from "utils/constants/showErrorMessage";
import smalltalk from "utils/libraries/smalltalk";
import { useTranslation } from "react-i18next";
import {
  invertedCurrensies,
  type currencyType,
  currencies,
} from "utils/constants/currencies";
import DOMPurify from "dompurify";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import SpaceAPI from "services/API/Space";
import getSpaceId from "utils/constants/getSpaceId";
import eventEmitter from "services/EventEmitter";
import usePanelResizing from "utils/hooks/usePanelResizing.ts";

export interface IFormInputs {
  "E-mail": string;
  Password: string;
  username: string;
  currency: currencyType;
}

const SettingsContainer: FC<{
  settingsRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}> = ({ settingsRef, onBack }) => {
  const navigate = useNavigate();

  const { data, isPending } = useQuery({
    queryKey: ["profile view"],
    queryFn: () => ProfileAPI.ProfileView().then((res) => res.data),
  });
  const { username, email, tag, language } = data ? data : {};
  const { setLoggedIn } = useStore();
  const { t } = useTranslation();

  const logoutReq = useMutation({
    mutationKey: ["logout"],
    mutationFn: () => ProfileAPI.Logout(),
    onSuccess: () => {
      window.localStorage.setItem("logout", Date.now());
      localStorage.clear();
      setLoggedIn(false);
      navigate("/login");
    },
    onError: showErrorMessage,
  });
  const logout = () => {
    smalltalk.confirm(t("you_sure"), t("logout")).then(logoutReq.mutate);
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // If it is true, the ConfirmEmailContainer modal is displayed
  const [confirmingEmail, setConfirmingEmail] = useState<boolean>(false);
  // If it is true, the ConfirmPasswordChangeContainer modal is displayed
  const [emailVerification, setEmailVerification] = useState<boolean>(false);

  const editReq = useMutation({
    mutationKey: ["edit profile"],
    mutationFn: (newProfileData: IProfileEditReq) =>
      ProfileAPI.EditProfile(newProfileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile view"] });
      setIsEditing(false);
    },
    onError: showErrorMessage,
  });

  const changeCurrencyReq = useMutation({
    mutationFn: SpaceAPI.updateSpace,
    onError: showErrorMessage,
    // if the currency was updated cool - we get a list of spaces,
    // get the updated space from it and write it to the localStorage
    // also run event to update the currency symbol in the circles
    onSuccess: async () => {
      const spaceId = getSpaceId();
      // updatedSpace - array that contains the same space with updated currency
      const updatedSpace = await SpaceAPI.getMySpaces()
        .then((data) => data.data)
        .then((spaces) => spaces.filter((space) => space.id === spaceId));
      if (updatedSpace.length) {
        localStorage.setItem(
          "space",
          JSON.stringify(sanitizeObject(updatedSpace[0])),
        );
        eventEmitter.emit("spaceWasUpdated");
      }
      setIsEditing(false);
    },
  });

  const currencyCode = JSON.parse(
    DOMPurify.sanitize(localStorage.getItem("space") as string),
  ).currency;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema()),
  });
  // when rendering data is undefined, so then after the query completes,
  // when the date contains data, I set it to the default values ​​of the form
  useEffect(() => {
    reset({
      username,
      "E-mail": email,
      Password: "",
      currency: invertedCurrensies[currencyCode] as currencyType,
    });
  }, [username, email, reset]);
  const [newEmail, setNewEmail] = useState(undefined);
  const onSubmit: SubmitHandler<IFormInputs> = async (newData) => {
    const isChangingEmail = newData["E-mail"] !== email;
    const isChangingPassword = newData.Password !== "";
    const currencyFromDataCode = currencies[newData.currency];
    const isChangingCurrency = currencyFromDataCode !== currencyCode;
    if (isChangingCurrency) {
      changeCurrencyReq.mutate({
        spaceId: getSpaceId() as number,
        data: {
          currency: currencyFromDataCode,
          // TODO: ask backenders to make the title field optional when changing currency
          title: JSON.parse(DOMPurify.sanitize(localStorage.getItem("space")))
            .title,
        },
      });
    }

    await editReq
      .mutateAsync({
        ...(newData.username !== username && { username: newData.username }),
        ...(isChangingEmail && { email: newData["E-mail"] }),
        ...(isChangingPassword && { password: newData.Password }),
      })
      .then(() => {
        if (isChangingEmail) {
          setConfirmingEmail(true);
          setNewEmail(newData["E-mail"]);
        } else if (isChangingPassword) {
          setEmailVerification(true);
        }
      });
  };

  usePanelResizing(settingsRef);

  return (
    <Settings
      isLoading={
        isPending ||
        editReq.isPending ||
        logoutReq.isPending ||
        changeCurrencyReq.isPending
      }
      watch={watch}
      settingsRef={settingsRef}
      handleSubmit={handleSubmit}
      username={username}
      register={register}
      tag={tag}
      email={email}
      language={language}
      onSubmit={onSubmit}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      logout={logout}
      confirmingEmail={confirmingEmail}
      setConfirmingEmail={setConfirmingEmail}
      newEmail={newEmail}
      errors={errors}
      onBack={onBack}
      emailVerification={emailVerification}
      setEmailVerification={setEmailVerification}
      setValue={setValue}
    />
  );
};

export default SettingsContainer;
