import React from "react";
import { useTranslation } from "react-i18next";

export const Header: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center mb-4 relative">
      <button
        onClick={onBack}
        type="button"
        className="absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
      >
        ←
      </button>
      <h2 className="text-[20px] text-center flex-grow">{t("header.cards")}</h2>
    </div>
  );
};
