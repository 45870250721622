import React, {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import lightning from "image/Shop/lightning.svg";
import star from "image/Shop/star.svg";
import basic from "image/Shop/basic.svg";
import cl from "./ShopWindow.module.scss";
import Subsction from "./Subscription";
import type { UseFormSetValue, UseFormWatch } from "react-hook-form";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./ShopWindowContainer";
import withErrorShow from "utils/hoc/withErrorShow";
import { useTranslation } from "react-i18next";
import PlanElement from "components/AdminPanelPage/Subscription/PlanElement";
import LicenseElement from "components/AdminPanelPage/Subscription/LicenseElement";
import { motion, AnimatePresence } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "utils/components/Loader/Loader";

interface Props extends IFormProps<IFormInputs> {
  featureRef: MutableRefObject<null>;
  standard_price: string;
  standardPeriod: string;
  standardAction: string;
  standardDetails: string | ReactNode;
  setValue: UseFormSetValue<IFormInputs>;
  count: number;
  setCount: (value: number) => void;
  premium_price: string;
  premiumPeriod: string;
  premiumAction: string;
  premiumDetails: string | ReactNode;
  basicDetails: string | ReactNode;
  watch: UseFormWatch<IFormInputs>;
  subscription: undefined | "standard" | "premium";
  isBuying: boolean;
  setIsBuying: Dispatch<SetStateAction<boolean>>;
  paymentData: {
    session_id: string;
    ephemeralKey: string;
    customer: string;
    publishableKey: string;
  };
  isPending: boolean;
  onBack: () => void;
}

const ShopWindow: React.FC<Props> = ({
  featureRef,
  handleSubmit,
  onSubmit,
  standard_price,
  standardPeriod,
  standardAction,
  standardDetails,
  setValue,
  premium_price,
  premiumPeriod,
  premiumAction,
  premiumDetails,
  watch,
  subscription,
  register,
  isBuying,
  setIsBuying,
  paymentData,
  isPending,
  basicDetails,
  count,
  setCount,
  onBack,
}) => {
  const { t } = useTranslation();
  const choosedSubscription = watch("subscription");
  const contentRef = useRef<HTMLDivElement | null>(null);

  const slideAnimation = {
    initial: { x: "100%" },
    animate: { x: 0 },
    exit: { x: "100%" },
    transition: { type: "spring", stiffness: 300, damping: 30 },
  };

  const stripePromise = loadStripe(
    "pk_test_51QeGGjDwblSo7CmzpbLkYjnFrOVggqKbxJiiBeGH0L6gEhmTKdes0TqiZwmEILay5sZ8C6oWFc5aebej4MSY3GG800WNwDAZdI"
  );

  useEffect(() => {
    const handlePayment = async () => {
      try {
        if (!isBuying || isPending) {
          return;
        }

        const stripe = await stripePromise;
        if (!stripe) {
          console.error("Stripe failed to initialize");
          return;
        }

        const { error } = await stripe.redirectToCheckout({
          sessionId: paymentData.session_id,
        });

        if (error) {
          console.error("Stripe redirect error:", error.message);
          setIsBuying(false);
        }
      } catch (error) {
        console.error("Payment handling error:", error);
        setIsBuying(false);
      }
    };

    handlePayment();
  }, [isBuying, isPending, setIsBuying]);

  if (isBuying) {
    return <Loader />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={featureRef}
        className={`${cl.container} bg-[#121212] px-4 py-8 `}
      >
        <div
          ref={contentRef}
          className={`h-full transition-all duration-300 ${
            choosedSubscription ? "w-[75%]" : "w-full"
          }`}
        >
          <div className="relative grid grid-cols-3 gap-x-8 h-full overflow-y-auto px-8 max-w-8xl mx-auto">
            <Subsction
              image={basic}
              desription={t("shop.basicDescription")}
              title="Basic"
              cost="FREE"
              costBg="#242424"
              costTextColor="white"
              buttonBg="gray"
              titleColor="gray"
              buttonTextColor="black"
              plan=""
              period={standardPeriod}
              action={standardAction}
              containerClass=" w-[100%] mb-3"
              details={basicDetails}
            />
            <Subsction
              image={star}
              cost={100}
              costBg="#003445"
              title="Business Plan"
              titleColor="#00c2ff"
              desription={t("shop.premiumDescription")}
              costTextColor="white"
              buttonBg="#00c2ff"
              buttonTextColor="white"
              plan={t("subscription.plan")}
              period={standardPeriod}
              action={standardAction}
              containerClass="w-[100%]"
              details={standardDetails}
              handleButtonClick={() => setValue("subscription", "standard")}
            />
            <Subsction
              image={lightning}
              desription={t("shop.standardDescription")}
              title="Business License"
              cost={500}
              costBg="#3e2901"
              costTextColor="white"
              buttonBg="#fea800"
              titleColor="#FFA800"
              buttonTextColor="black"
              plan={t("subscription.lic")}
              period={premiumPeriod}
              action={premiumAction}
              containerClass=" w-[100%] mb-3"
              details={premiumDetails}
              handleButtonClick={() => setValue("subscription", "premium")}
            />
          </div>
        </div>

        <AnimatePresence>
          {choosedSubscription && (
            <motion.div
              className="col-span-1 h-full bg-[#181818] flex flex-col items-center justify-center px-4 fixed right-0 top-0 w-[25%] border-l border-[#323232]"
              {...slideAnimation}
            >
              <div className="absolute top-0 text-center left-0 w-full py-8 text-2xl inline">
                <button
                  onClick={() => setValue("subscription", undefined)}
                  type="button"
                  className="absolute left-6 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
                >
                  ←
                </button>
                {t("shop.title")}
              </div>

              {subscription === "premium" ? (
                <div>
                  <LicenseElement text={t("plans.yours")} cost={500 * count} />
                </div>
              ) : (
                <div className="">
                  <PlanElement text={t("plans.yours")} />
                </div>
              )}

              <div className="my-12 text-xl space-y-3">
                {subscription === "premium" ? (
                  <>
                    <div>{t("shop.licenseCount")}</div>
                    <div className="text-sm leading-relaxed text-gray-500 font-normal">
                      {t("shop.licenseCountDescription")}
                    </div>
                  </>
                ) : (
                  <>
                    <div>{t("shop.licenseCount")}</div>
                    <div className="text-sm leading-relaxed text-gray-500 font-normal">
                      {t("shop.licenseCountDescription")}
                    </div>
                  </>
                )}
                {subscription === "premium" && (
                  <div className="flex items-center text-white text-lg rounded-md">
                    <button
                      onClick={() => setCount(count > 1 ? count - 1 : 1)}
                      className="px-4 h-12 py-2 rounded-l-md border border-[#3e3e3e] bg-[#181818]"
                      type="button"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={count}
                      onChange={(e) =>
                        setCount(Math.max(1, parseInt(e.target.value) || 1))
                      }
                      className="w-16 h-12 text-center border-t border-b border-[#3e3e3e] bg-[#181818] text-[#FFA800] outline-none"
                    />
                    <button
                      onClick={() => setCount(count + 1)}
                      className="px-4 h-12 py-2 rounded-r-md border border-[#3e3e3e] bg-[#181818]"
                      type="button"
                    >
                      +
                    </button>
                  </div>
                )}
              </div>

              <div className="w-full flex flex-col items-center">
                <div className="w-full flex items-center justify-start">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="terms-of-purchase"
                      className="hidden"
                      {...register("agreement")}
                    />
                    <label
                      className={`duration-300 border text-center select-none rounded-2xl py-3 px-4 text-xl bg-[#181818] mr-4 ${
                        watch("agreement")
                          ? "text-orange border-orange"
                          : "text-[#7f7f7f] border-[#3e3e3e]"
                      }`}
                      htmlFor="terms-of-purchase"
                    >
                      Okay
                    </label>
                  </div>

                  <p className="text-[#7f7f7f] text-[14px] ml-4">
                    {t("shop.iAgree")}{" "}
                    <a
                      className="!underline cursor-pointer text-[#7f7f7f]"
                      href="http://localhost:5173/terms-of-purchase/"
                    >
                      {t("shop.termsOfPurchase")}
                    </a>
                  </p>
                </div>
                <button
                  className={`mt-6 disabled:cursor-not-allowed w-full duration-300 py-4 rounded-2xl text-xl ${
                    subscription === "premium"
                      ? "bg-[#3e2a01] text-[#cc8d11]"
                      : subscription === "standard"
                      ? "bg-[#003445] text-[#16a3d2]"
                      : "bg-[#181818] text-[#6a6a6a]"
                  }`}
                  type="submit"
                  disabled={!choosedSubscription}
                >
                  {!choosedSubscription
                    ? t("shop.chooseSubscriptionPrompt")
                    : `${t("shop.buy")}`}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </>
  );
};

export default withErrorShow(ShopWindow);
