import type { RefObject, Dispatch, SetStateAction } from "react";
import Spend from "./Spend";
import plus_logo from "image/Goals/plus.svg";
import Modal from "utils/components/Modal";
import CreatePayment from "./CreatePayment";
import { Spend as SpendInterface } from "utils/types/response/PeriodicSpendsRes";
import { useTranslation } from "react-i18next";
import LittleLoader from "utils/components/Loader/LittleLoader";

interface PeriodicSpendsWindowProps {
  featureRef: RefObject<HTMLElement>;
  onBack: () => void;
  isPending: boolean;
  data: SpendInterface[] | undefined;
  creatingPayment: boolean;
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
  isLimitReached: boolean;
  limit: number;
}

const PeriodicSpendsWindow = ({
  featureRef,
  onBack,
  isPending,
  data,
  creatingPayment,
  setCreatingPayment,
  isLimitReached,
  limit,
}: PeriodicSpendsWindowProps) => {
  const { t } = useTranslation();

  return (
    <aside
      ref={featureRef}
      className="bg-[#121212] overflow-auto relative top-[2.3%] left-[1.5%] rounded-3xl flex flex-col items-center shadow-md shadow-[#FFA80080]"
    >
      <div className="flex font-vela items-center justify-between w-full p-4">
        <button
          onClick={onBack}
          className="text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
        >
          ←
        </button>
        <h2 className="text-[20px] text-white flex-grow text-center">
          {t("preiodic")}
        </h2>
      </div>
      <div className="flex flex-col items-center w-full">
        {isPending ? (
          <LittleLoader />
        ) : (
          data?.map(
            (
              {
                title,
                schedule,
                category,
                account,
                id,
                day,
                amount,
                currency,
                category_icon,
              },
              index,
            ) => (
              <Spend
                title={title}
                schedule={schedule}
                id={id}
                day={day}
                key={`${title} payment`}
                amount={amount}
                currency={currency}
                account={account}
                category={category}
                category_icon={category_icon}
                isBlocked={index + 1 > limit}
              />
            ),
          )
        )}
      </div>
      {!isLimitReached && !isPending &&(
        <button
          className="mt-4 mb-4"
          onClick={() => setCreatingPayment(true)}
          type="button"
        >
          <img src={plus_logo} className="!rounded-full" alt="add payment" />
        </button>
      )}
      {creatingPayment && (
        <Modal setActive={setCreatingPayment}>
          <CreatePayment setCreatingPayment={setCreatingPayment} />
        </Modal>
      )}
    </aside>
  );
};

export default PeriodicSpendsWindow;
