import { queryClient } from "main";
import { useQuery } from "@tanstack/react-query";
import getSpaceId from "utils/constants/getSpaceId";
import updatesCheck from "services/API/UpdatesCheck";
import { useLocation } from "react-router-dom";
import useStore from "store";

const useUpdateData = () => {
  const { loggedIn } = useStore();

  useQuery({
    queryKey: ["check for updates"],
    queryFn: () =>
      updatesCheck(getSpaceId()).then((res) => {
        if (res.data.message === "There's been a change") {
          queryClient.invalidateQueries({
            queryKey: ["get accounts"],
          });
          queryClient.invalidateQueries({
            queryKey: ["categories", getSpaceId()],
          });
        }

        return res;
      }),
    refetchInterval: 10000,
    refetchIntervalInBackground: false,
    enabled: useLocation().pathname === "/" && loggedIn && !!getSpaceId(),
  });
};

export default useUpdateData;
