import React, { type RefObject } from "react";
import Accounts from "./Accounts";
import ConverterFormContainer from "./ConverterFormContainer";
import { useTranslation } from "react-i18next"; // Импорт useTranslation
import usePanelResizing from "utils/hooks/usePanelResizing";

interface ConverterProps {
  converterRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}

const Converter: React.FC<ConverterProps> = ({ converterRef, onBack }) => {
  const { t } = useTranslation(); // Инициализация переводчика
  usePanelResizing(converterRef);

  return (
    <div
      ref={converterRef}
      className="text-white p-5 font-vela overflow-auto font-bold bg-[#101010] rounded-3xl text-[20px] relative top-[2.3%] left-[1.5%] shadow-md shadow-[#FFA80080] flex flex-col"
    >
      <div className={"flex items-center justify-center mb-4 relative"}>
        <button
          onClick={onBack}
          type="button"
          className={
            "absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
          }
        >
          ←
        </button>
        <h2 className={"text-[20px] text-center flex-grow"}>
          {t("converter.title")}
        </h2>
      </div>
      <ConverterFormContainer />
      {/* <p className="text-[20px] mt-8 mb-2">{t("converter.accounts")}</p>{" "}
      <Accounts /> */}
    </div>
  );
};

export default Converter;
