const Spinner = ({ className }: { className?: string }) => {
  return (
    <span
      id="spinner"
      className={`size-[50px] aspect-square block rounded-full animate-spin ${
        className || ""
      }`}
      style={{
        background: `radial-gradient(farthest-side,gray 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,gray)`,
        WebkitMask:
          "radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0)",
      }}
    />
  );
};

export default Spinner;
