import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ProfileAPI } from "services/API/Profile";

import en from "./locales/en/translation.json";
import cs from "./locales/cs/translation.json";

const handleLanguageChange = async (lng: string) => {
  const token = localStorage.getItem("token");

  if (!token) return;

  const languageForAPI = lng === "en" ? "ENGLISH" : "CZECH";
  if (lng === "en" || lng === "cs") {
    await ProfileAPI.EditProfile({
      language: languageForAPI,
    });
    console.log("Language updated to:", languageForAPI);
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      cs: { translation: cs },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on("languageChanged", handleLanguageChange);

export const changeLanguage = (language: "en" | "cs") => {
  i18n.changeLanguage(language);
  localStorage.setItem("preferred-language", language);
};

export default i18n;
