import utilCl from "utils/Classes.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import cl from "./Form.module.scss";
import { useTranslation } from "react-i18next";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./FormContainer";
import type { Dispatch, FC, SetStateAction } from "react";

interface Props extends IFormProps<IFormInputs> {
  setCreatingGoal: Dispatch<SetStateAction<boolean>>;
  submitButtonText: string;
  isPending: boolean;
}

const Form: FC<Props> = ({
  handleSubmit,
  onSubmit,
  register,
  setCreatingGoal,
  submitButtonText = "Create",
  isPending,
}) => {
  const { t } = useTranslation();

  return (
    <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
      <input
        autoComplete="off"
        placeholder={t("form.titlePlaceholder")}
        {...register("title")}
      />
      <input
        autoComplete="off"
        placeholder={t("form.amountPlaceholder")}
        {...register("goal")}
      />
      <button
        type="button"
        onClick={() => setCreatingGoal(false)}
        className={`${utilCl.black_button} rounded-l-xl py-3`}
      >
        {t("form.cancelButton")}
      </button>
      <button
        disabled={isPending}
        className={`${utilCl.yellow_button} rounded-r-xl disabled:cursor-not-allowed disabled:bg-[#858176] duration-300`}
        type="submit"
      >
        {submitButtonText}
      </button>
    </form>
  );
};

export default withErrorShow(Form);
