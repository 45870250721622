import { Dispatch, SetStateAction } from "react";
import CustomAlert from "utils/components/CustomAlert";
import useStore from "store";
import eventEmitter from "services/EventEmitter";
import { useTranslation } from "react-i18next";

const AlertAboutLimit = ({
  setActive,
}: {
  setActive: Dispatch<SetStateAction<boolean | "limit alert">>;
}) => {
  const { subscription } = useStore();
  const { t } = useTranslation();

  const isMaxSubscription = subscription === "business_lic" || subscription === "business_member_lic";

  const handleUpgradeClick = () => {
    setActive(false);

    eventEmitter.emit("openShop");
  };

  return (
    <CustomAlert
      text={
        isMaxSubscription
          ? t("reached")
          : t("reached2")
      }
      setActive={setActive}
      AdditionalButton={
        !isMaxSubscription && (
          <button
            onClick={handleUpgradeClick}
            className="bg-[#191a1c] px-5 py-1 rounded-xl col-[2/3] "
          >
            {t("plans.upgrade")}
          </button>
        )
      }
    />
  );
};

export default AlertAboutLimit;
