export interface AssetColor {
  base: string;
  highlight: string;
}

export const ASSET_COLORS: AssetColor[] = [
  { base: "#322e28", highlight: "#ffa800" },
  { base: "#342323", highlight: "#ff6a6a" },
  { base: "#152c34", highlight: "#00c2ff" },
  { base: "#1b251f", highlight: "#338150" },
];

export const TABLE_HEADERS = ['asset', 'data', 'price', 'status', 'updated'];
