import React, { useState, useEffect, type SyntheticEvent, useRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { currencies } from "../../../../utils/constants/currencies.ts";
import styles from "./auto_complete.module.scss";
import { useOnClickOutside } from "usehooks-ts";

interface Props {
  register: UseFormRegister<any>;
  placeholder: string;
  onClickElement: (e: SyntheticEvent<HTMLLIElement>) => void;
  fieldName?: string;
  inputClass?: string;
  ulClass?: string;
  liClass?: string;
}

const AutoComplete: React.FC<Props> = ({
  register,
  placeholder,
  onClickElement,
  fieldName = "currency",
  inputClass,
  ulClass,
  liClass,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredValues, setFilteredValues] = useState<[string, string][]>([]);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  useEffect(() => {
    setFilteredValues(
      Object.entries(currencies).filter(([key, value]) => {
        const lowercaseInput = inputValue.toLowerCase();
        return (
          key.toLowerCase().includes(lowercaseInput) ||
          value.toLowerCase().includes(lowercaseInput)
        );
      }),
    );
  }, [inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleElementChoose = (
    e: React.KeyboardEvent,
    el: [string, string],
  ) => {
    const key = e.key;
    const target = e.target as HTMLLIElement;
    if (key === "Enter") {
      setInputValue(el[0]);
      onClickElement(e);
    } else if (key === "ArrowDown") {
      (target.nextSibling as HTMLLIElement).focus();
    } else if (key === "ArrowUp") {
      (target.previousSibling as HTMLLIElement).focus();
    } else if (key === "Tab") {
      e.preventDefault();
      (target.nextSibling as HTMLLIElement).focus();
    }
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(ulRef, () => setInputFocus(false));
  return (
    <div className={styles.autocomplete_container} ref={containerRef}>
      <input
        {...register(fieldName)}
        id={fieldName}
        placeholder={placeholder}
        onChange={handleInputChange}
        autoComplete="off"
        onFocus={() => setInputFocus(true)}
        onClick={() => setInputFocus(true)}
        className={inputClass}
        onKeyDown={(event) => {
          if (
            (event.key === "Tab" && liRef.current) ||
            (event.key === "ArrowDown" && liRef.current)
          ) {
            event.preventDefault();
            liRef.current.focus();
          }
        }}
      />

      {inputFocus &&
        (inputValue || window.ReactNativeWebView) &&
        inputValue !== filteredValues[0]?.[0] && (
          <ul
            className={`${styles.autocomplete_list} ${ulClass ? ulClass : ""}`}
            ref={ulRef}
          >
            {filteredValues.map((el) => (
              <li
                className={`${styles.autocomplete_item} ${
                  liClass ? liClass : ""
                }`}
                key={el[0]}
                onClick={(e) => {
                  setInputValue(el[0]);
                  onClickElement(e);
                }}
                tabIndex={0}
                onFocus={() => setInputFocus(true)}
                onKeyDown={(e) => handleElementChoose(e, el)}
              >
                {el[0]}
              </li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default AutoComplete;
