import React from "react";
import { useTranslation } from "react-i18next";
import CardItem from "./CardItem";
import type { ConnectedBank } from "utils/types/response/CardsRes";
import smalltalk from "utils/libraries/smalltalk";
import CardsAPI from "services/API/Cards";
import { queryClient } from "main";
import { SpaceAPI } from "services/API";
import showErrorMessage from "utils/constants/showErrorMessage";
import { useMutation } from "@tanstack/react-query";
import getSpaceId from "utils/constants/getSpaceId";
import trashIcon from "image/Cards/trashRed.svg";
import refreshIcon from "image/Cards/RefreshOrange.svg";
import eventEmitter from "services/EventEmitter";

export const CardsList: React.FC<{ cards: ConnectedBank[] }> = ({ cards }) => {
  const { t } = useTranslation();
  const spaceId = getSpaceId();

  const DeleteAccountReq = useMutation({
    mutationKey: ["delete card"],
    mutationFn: (bankId: number) =>
      CardsAPI.deleteBankConnection({ bankId, spaceId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["get connected banks"],
      });
    },
    onError: showErrorMessage,
  });

  const handleRemove = (connectionId: number) => {
    smalltalk
      .confirm(
        t("cardItem.confirmDeleteTitle"),
        t("cardItem.confirmDeleteConnectionMessage"),
      )
      .then(() => {
        DeleteAccountReq.mutate(connectionId);
      });
  };

  // Группировка карт по bankConnectionName
  const groupedCards = cards.reduce(
    (acc, card) => {
      const key = card.bankConnectionName;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(card);
      return acc;
    },
    {} as Record<string, ConnectedBank[]>,
  );

  const RefreshAccountReq = useMutation({
    mutationKey: ["refresh card"],
    mutationFn: (bank_connection_id: number) =>
      SpaceAPI.updateConnection(spaceId, {
        bank_connection_id: bank_connection_id,
      }),
    onSuccess: async (data) => {
      window.location.href = data.url;
    },
    onError: () => {
      eventEmitter.emit("setLoading", false);
      showErrorMessage;
    },
  });

  const handleRefresh = (connectionId: number) => {
    smalltalk
      .confirm(
        t("cardItem.confirmDeleteTitle"),
        t("cardItem.confirmRefreshConnectionMessage"),
      )
      .then(() => {
        RefreshAccountReq.mutate(connectionId);
        eventEmitter.emit("setLoading", true);
      });
  };

  return (
    <div className="overflow-y-auto">
      {cards.length > 0 ? (
        Object.entries(groupedCards).map(([connectionName, cardsInGroup]) => (
          <div key={connectionName}>
            <div className="p-2 text-gray-700 flex justify-between items-center">
              {connectionName}
              <div className="flex space-x-3">
                <button
                  onClick={() =>
                    handleRefresh(cardsInGroup[0].bankConnectionId)
                  }
                >
                  <img className="w-5" alt="refresh" src={refreshIcon} />
                </button>
                <button
                  onClick={() => handleRemove(cardsInGroup[0].bankConnectionId)}
                >
                  <img className="w-5" alt="trash" src={trashIcon} />
                </button>
              </div>
            </div>
            {cardsInGroup.map((card, i) => (
              <CardItem key={`card${i}`} card={card} />
            ))}
          </div>
        ))
      ) : (
        <p className="text-gray-500 text-center">{t("cards.noCardsMessage")}</p>
      )}
    </div>
  );
};
