import { useState } from "react";
import cl from "./ShopWindow.module.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ShopAPI } from "services/API/Shop";
import DOMPurify from "dompurify";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ShopWindow from "./ShopWindow";
import Loader from "utils/components/Loader/Loader";
import { instance } from "services/API/instances";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PrivacyMessageWrapper from "./PrivacyMessage";

export interface IFormInputs {
  agreement: boolean;
  subscription: "standard" | "premium";
}

const ShopWindowContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBack = () => {
    navigate("/");
  };
  const [count, setCount] = useState<number>(1);

  const basicDetails = (
    <div className={cl.details}>
      <p>
        - <span>{t("basicDetails.categories")}</span>{" "}
        {t("basicDetails.flexibleExpenses")}
      </p>
      <p>
        - <span>{t("basicDetails.accounts")}</span>{" "}
        {t("basicDetails.assetsTracking")}
      </p>
      <p>
        - <span>{t("basicDetails.goals")}</span>{" "}
        {t("basicDetails.financialPlans")}
      </p>
      <p>
        - <span>{t("basicDetails.regularExpenses")}</span>{" "}
        {t("basicDetails.recurringPayments")}
      </p>
      <p>
        - <span>{t("basicDetails.excel")}</span> {t("basicDetails.excelOpen")}
      </p>
      <p>
        - <span>{t("basicDetails.cards")}</span> {t("basicDetails.cardsOpen")}
      </p>
    </div>
  );

  const standardDetails = (
    <div className={cl.details}>
      <p>
        - <span>{t("planDetails.categories")}</span>{" "}
        {t("planDetails.flexibleExpenses")}
      </p>
      <p>
        - <span>{t("planDetails.accounts")}</span>{" "}
        {t("planDetails.assetsTracking")}
      </p>
      <p>
        - <span>{t("planDetails.goals")}</span>{" "}
        {t("planDetails.financialPlans")}
      </p>
      <p>
        - <span>{t("planDetails.regularExpenses")}</span>{" "}
        {t("planDetails.recurringPayments")}
      </p>
      <p>
        - <span>{t("planDetails.excel")}</span> {t("planDetails.excelOpen")}
      </p>
      <p>
        - <span>{t("planDetails.cards")}</span> {t("planDetails.cardsOpen")}
      </p>
      <p>
        <span>{t("planDetails.also")}</span>
      </p>
      <p>
        - <span>{t("planDetails.project")}</span> {t("planDetails.projectOpen")}
      </p>
      <p>
        - <span>{t("planDetails.collabs")}</span> {t("planDetails.collabsOpen")}
      </p>
      <p>
        - <span>{t("planDetails.tikets")}</span> {t("planDetails.tiketsOpen")}
      </p>
    </div>
  );

  const premiumDetails = (
    <div className={cl.details}>
      <p>
        - <span>{t("licDetails.categories")}</span>{" "}
        {t("licDetails.flexibleExpenses")}
      </p>
      <p>
        - <span>{t("licDetails.accounts")}</span>{" "}
        {t("licDetails.assetsTracking")}
      </p>
      <p>
        - <span>{t("licDetails.goals")}</span> {t("licDetails.financialPlans")}
      </p>
      <p>
        - <span>{t("licDetails.regularExpenses")}</span>{" "}
        {t("licDetails.recurringPayments")}
      </p>
      <p>
        - <span>{t("licDetails.excel")}</span> {t("licDetails.excelOpen")}
      </p>
      <p>
        - <span>{t("licDetails.cards")}</span> {t("licDetails.cardsOpen")}
      </p>
      <p>
        <span>{t("licDetails.also")}</span>
      </p>
      <p>
        - <span>{t("licDetails.project")}</span> {t("licDetails.projectOpen")}
      </p>
      <p>
        - <span>{t("licDetails.collabs")}</span> {t("licDetails.collabsOpen")}
      </p>
      <p>
        - <span>{t("licDetails.tikets")}</span> {t("licDetails.tiketsOpen")}
      </p>
      <p>
        - <span>{t("licDetails.service")}</span> {t("licDetails.serviceOpen")}
      </p>
    </div>
  );

  const { data } = useQuery({
    queryKey: ["get prices"],
    queryFn: () => ShopAPI.getPrices().then((res) => res.data),
  });

  const regex = /~(.*?)~/g;
  const { premium_period, premium_price, standard_period, standard_price } =
    data ? data : {};
  const [standardPeriod, standardAction] =
    DOMPurify.sanitize(standard_period).split(regex);
  const [premiumPeriod, premiumAction] =
    DOMPurify.sanitize(premium_period).split(regex);

  const {
    data: paymentData,
    isPending,
    mutateAsync,
  } = useMutation({
    mutationKey: ["create_checkout_session"],
    mutationFn: ({
      plan,
      count,
    }: {
      plan: "standard" | "premium";
      count: number;
    }) =>
      instance
        .post("store/create_checkout_session/", { plan, count })
        .then((res) => res.data),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IFormInputs>({
    resolver: zodResolver(
      z.object({
        agreement: z.literal(true, {
          errorMap: () => ({
            message: t("form.errors.agreement"),
          }),
        }),
        subscription: z
          .string({ message: t("form.errors.subscription") })
          .refine((val) => val === "standard" || val === "premium", {
            message: t("form.errors.subscription"),
          }),
      }),
    ),
  });

  const [isBuying, setIsBying] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    await mutateAsync({ plan: data.subscription, count });
    setIsBying(true);
  };

  const subscription = watch("subscription");

  if (isPending) {
    return <Loader />;
  }

  return (
    <>
      <PrivacyMessageWrapper />
      <ShopWindow
        isPending={isPending}
        paymentData={paymentData}
        isBuying={isBuying}
        setIsBuying={setIsBying}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        watch={watch}
        subscription={subscription}
        setValue={setValue}
        standard_price={standard_price}
        standardPeriod={standardPeriod}
        standardAction={standardAction}
        standardDetails={standardDetails}
        basicDetails={basicDetails}
        premium_price={premium_price}
        premiumPeriod={premiumPeriod}
        premiumAction={premiumAction}
        premiumDetails={premiumDetails}
        onBack={onBack}
        count={count}
        setCount={setCount}
      />
    </>
  );
};

export default ShopWindowContainer;
