import { instance } from "./instances";
import type { Bank, ConnectedBank } from "utils/types/response/CardsRes";

const CardsAPI = {
  addCard: (spaceId: number) =>
    instance.get(`/my_spaces/${spaceId}/user-bank/`),
  addPhone: ({ phone, spaceId }: { phone: string; spaceId: number }) =>
    instance.post(`/my_spaces/${spaceId}/user-bank/`, {
      phone,
    }),
  refreshAccount: ({
    account_id,
    spaceId,
  }: { account_id: number; spaceId: number }) =>
    instance.post(`/my_spaces/${spaceId}/account/refresh/`, {
      account_id,
    }),
  banksView: (spaceId: number) =>
    instance.get<Bank[]>(`/my_spaces/${spaceId}/banks/`),
  bankConnection: ({
    spaceId,
    data,
  }: {
    spaceId: number;
    data: {
      bankConnectionName: string;
      bank_id: number;
    };
  }) =>
    instance.post<{ url: string }>(
      `/my_spaces/${spaceId}/bank/connection/`,
      data,
    ),
  deleteAccount: ({
    accountId,
    spaceId,
  }: {
    accountId: number;
    spaceId: number;
  }) =>
    instance.delete<{ url: string }>(
      `/my_spaces/${spaceId}/delete_bank_account/`,
      { data: { accountId } },
    ),
  deleteBankConnection: ({
    bankId,
    spaceId,
  }: {
    bankId: number;
    spaceId: number;
  }) =>
    instance.delete<{ url: string }>(
      `/my_spaces/${spaceId}/my_connections/delete/`,
      { data: { bankId } },
    ),
  getConnectedBanks: (spaceId: number) =>
    instance.get<ConnectedBank[]>(`/my_spaces/${spaceId}/my_connections/`),
};

export default CardsAPI;
