// LoginContainer.jsx
import { useEffect, useRef, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { getValSchema } from "utils/validators/Login/Login";
import useStore from "store";
import useLogin from "utils/hooks/useLogin";
import Login from "./Login";
import utilCl from "utils/Classes.module.scss";
import { useTour } from "components/Tour/Hooks/useTour";

interface Inputs {
  email: string;
  password: string;
  license?: boolean;
}

const LoginContainer = () => {
  const { startTour } = useTour();
  const { setEmail, email, setPassword, password, currency } = useStore(
    (store) => store,
  );
  const [isLogining, setIsLogining] = useState<boolean>(true);
  const { mutateAsync, isPending } = useLogin(!isLogining);
  const [shownPassword, setShownPassword] = useState<boolean>(false);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage("Here");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<Inputs>({
    resolver: zodResolver(getValSchema(isLogining)),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (email && password && currency) {
      mutateAsync();
      startTour();
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setEmail(data.email);
    setPassword(data.password);
    mutateAsync().catch(() => {
      if (!isLogining) {
        navigate("confirm-password");
      }
    });
  };

  const handleGoogleLogin = () => {
    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage("Google Auth");
    //   return null;
    // }
    window.location.href =
      "https://api.spendsplif.com/api/v1/auth/google/login/";
    // localStorage.setItem("token", " ");
    // navigate("/");
  };
  const errorMessageRef = useRef<HTMLParagraphElement>();
  const handleError = async () => {
    const result = await trigger();
    if (!result) {
      errorMessageRef.current?.classList.add(utilCl.errorMessageAnimation);
      setTimeout(() => {
        errorMessageRef.current?.classList.remove(utilCl.errorMessageAnimation);
      }, 500);
    }
  };

  return (
    <Login
      errorMessageRef={errorMessageRef}
      handleError={handleError}
      isLogining={isLogining}
      setIsLogining={setIsLogining}
      shownPassword={shownPassword}
      setShownPassword={setShownPassword}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isPending}
      googleLogin={handleGoogleLogin}
    />
  );
};

export default LoginContainer;
