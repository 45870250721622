import { useMutation } from "@tanstack/react-query";
import NotificationsWindow from "./NotificationsWindow";
import { NotificationsAPI } from "services/API/Notifications";
import { useEffect, useState, type MutableRefObject } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { queryClient } from "main";
import type { IUpdateSeenReq } from "utils/types/request/NotificationsReq";
import usePanelResizing from "utils/hooks/usePanelResizing";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";

const NotificationsWindowContainer = ({
  NotificationsRef,
}: {
  NotificationsRef: MutableRefObject<null>;
}) => {
  const {
    data,
    isPending,
    mutate: getNotifications,
  } = useMutation({
    mutationKey: ["get notifications"],
    mutationFn: (count = 40) => NotificationsAPI.getNotifications(count),
  });
  const updateSeen = useMutation({
    mutationKey: ["update seen notifications"],
    mutationFn: (data: IUpdateSeenReq) => NotificationsAPI.updateSeen(data),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["how many notifications unseen"],
      }),
  });

  const [notificationsCount, setNotificationsCount] = useState<number>(40);
  const [displayData, setDisplayData] = useState([]);

  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 0,
  });

  useEffect(() => {
    getNotifications(notificationsCount);
  }, []);

  useEffect(() => {
    if (data) {
      setDisplayData([...data.data]);
    }
    const unSeenNotifications = data?.data
      .filter((el) => !el.seen)
      .map((el) => ({ id: el.id, type: el.type }));
    if (unSeenNotifications?.length) {
      updateSeen.mutate(unSeenNotifications);
    }
  }, [data?.data]);

  useEffect(() => {
    if (isIntersecting) {
      getNotifications(notificationsCount + 40);
      setNotificationsCount((prev) => prev + 40);
    }
  }, [isIntersecting]);

  usePanelResizing(NotificationsRef);
  useSetCoolScroll(NotificationsRef);

  return (
    <NotificationsWindow
      NotificationsRef={NotificationsRef}
      isLoading={notificationsCount === 40 && isPending}
      //I use displayData instead of data?.data 'cause if use the second one -
      // component will be completely rerender
      data={displayData}
      refForLastNotification={ref}
    />
  );
};

export default NotificationsWindowContainer;
