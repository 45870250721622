import { useState } from "react";
import React from "react";
import whiteArrowUp from "image/Account/WhiteArrowUp.svg";
import whiteArrowDown from "image/Account/WhiteArrowDown.svg";
import formatLargeNumber from "utils/functions/formatLargeNumber";
import { useTranslation } from "react-i18next";

interface TotalTransactionProps {
  data: number;
  currencySymbol: string;
  transactionType: "Expenses" | "Incoming";
}

const TotalTransaction = React.memo(
  ({ data, currencySymbol, transactionType }: TotalTransactionProps) => {
    const { t } = useTranslation();
    const [showFullIncome, setShowFullIncome] = useState<boolean>(false);

    const isIncoming = transactionType === "Incoming";

    return (
      <>
        <img
          src={isIncoming ? whiteArrowUp : whiteArrowDown}
          className={`w-[16px] justify-self-center ${isIncoming ? "row-[1/3]" : "row-[3/5]"}`}
          alt="up arrow"
        />
        <div
          onMouseEnter={() => setShowFullIncome(true)}
          onMouseLeave={() => setShowFullIncome(false)}
          className={`flex-col flex relative text-[10px] ${isIncoming ? "text-[#32CD32]" : "text-[#FF3D00]"}`}
        >
          {showFullIncome && (
            <p className="!absolute -top-3.5 bg-[#19191A] px-0.5 rounded-[3px]">
              {currencySymbol + data}
            </p>
          )}
          <p className="ml-[1px] h-[12px]">
            {currencySymbol + formatLargeNumber(data)}
          </p>
        </div>
        <p className="ml-[1px] text-[5px] text-[#686868]">
          {transactionType === "Expenses"
            ? t("showTotalBalance.expenses")
            : t("showTotalBalance.incoming")}
        </p>
      </>
    );
  },
);

export default TotalTransaction;
