import React from "react";
import cl from "./Table.module.scss";

interface ProgressBarProps {
  current: number;
  total: number;
  barColor: string;
  barColorWithTransparency: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  current,
  total,
  barColor,
  barColorWithTransparency,
}) => {
  const percentage = Math.round((current / total) * 100);

  return (
    <div
      style={{ backgroundColor: barColor }}
      className={cl.progressBarContainer}
    >
      {percentage > 0 && (
        <div
          className={cl.progressBar}
          style={{
            width: `${percentage}%`,
            backgroundColor: barColorWithTransparency,
            boxShadow: `0 0 15px ${barColorWithTransparency}`,
          }}
        />
      )}
    </div>
  );
};
