import type { CallBackProps } from "react-joyride";

export const runNextStep = (
  handleCallback: (data: CallBackProps & { simulation?: boolean }) => void,
  index: number,
) => {
  handleCallback({
    action: "next",
    index,
    type: "step:after",
    simulation: true,
  });
  handleCallback({
    action: "next",
    index: index + 1,
    type: "step:before",
    simulation: true,
  });
  handleCallback({
    action: "update",
    index: index + 1,
    type: "tooltip",
    simulation: true,
  });
};
