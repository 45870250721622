import { memo } from "react";

const Message = memo(
  ({
    isOwnMessage,
    text,
    time,
    ePalLogo,
  }: {
    isOwnMessage: boolean;
    text: string;
    time: string;
    ePalLogo: string;
  }) => {
    return (
      <article className={`${isOwnMessage ? "self-end " : ""} flex gap-x-2`}>
        {!isOwnMessage && (
          <span className="bg-[#161616] size-11 rounded-xl flex justify-center items-center self-end">
            {ePalLogo}
          </span>
        )}
        <div
          className={`${isOwnMessage ? "bg-orange rounded-bl-xl rounded-br-sm" : "rounded-bl-sm rounded-br-xl bg-[#121212]"} rounded-t-xl break-all  py-2 px-1.5`}
        >
          <p
            className={`${isOwnMessage ? "text-black" : "text-white"} text-lg`}
          >
            {text}
          </p>
          <span className="text-[#8C8098] text-sm select-none">
            {time.substring(0, 5)}
          </span>
        </div>
      </article>
    );
  },
);

export default Message;
