import { Dispatch, SetStateAction } from "react";
import CustomAlert from "utils/components/AlertAboutLimit copy/CustomAlert/CustomAlert";
import useStore from "store";
import eventEmitter from "services/EventEmitter";
import { useTranslation } from "react-i18next";

interface ICustomAlertUpdateBankProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  handleUpdateClick: () => void;
  data: {
    bank: string;
    connection: string;
  };
}

const CustomAlertUpdateBank = ({
  setActive,
  handleUpdateClick,
  data,
}: ICustomAlertUpdateBankProps) => {
  const { t } = useTranslation();

  const handleLaterClick = () => {
    setActive(false);
  };

  return (
    <CustomAlert
      text={t("bankUpdateRequired", {
        bank: data.bank || "",
        connection: data.connection || "",
      })}
      setActive={setActive}
      handleUpdateClick={handleUpdateClick}
      AdditionalButton={
        <button
          onClick={handleLaterClick}
          className="bg-[#191a1c] px-5 py-1 rounded-xl col-[2/3] "
        >
          {t("later")}
        </button>
      }
    />
  );
};

export default CustomAlertUpdateBank;
