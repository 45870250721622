import React, { useEffect } from "react";
import type { Dispatch, ReactNode, RefObject, SetStateAction } from "react";

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  Ref?: RefObject<HTMLDivElement>;
}

const Modal: React.FC<Props> = ({ setActive, children, Ref }) => {
  // exit on esc click
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setActive(false);
      }
    };
    document.addEventListener("keyup", handleEsc);
    return () => {
      document.removeEventListener("keyup", handleEsc);
    };
  }, []);

  return (
    <div
      id="modal-window"
      ref={Ref}
      className="h-[100dvh] w-[100vw] left-0 top-0 z-20 !fixed 
    bg-[rgba(0,0,0,0.5)] flex justify-center items-center"
      onMouseDown={() => setActive(false)}
    >
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className="bg-[#101010] rounded-3xl"
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
