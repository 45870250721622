import type { FC } from "react";
import cl from "./ChooseNick.module.scss";
import user_logo from "image/login_p/choose_nick/user_logo.svg";
import withErrorShow from "utils/hoc/withErrorShow";
import type {
  UseFormHandleSubmit,
  UseFormRegister,
  SubmitHandler,
} from "react-hook-form";
import withLoader from "utils/hoc/withLoader";
import { useTranslation } from "react-i18next";

interface Input {
  nick: string;
}

interface Props {
  handleSubmit: UseFormHandleSubmit<Input>;
  register: UseFormRegister<Input>;
  onSubmit: SubmitHandler<Input>;
}

const ChooseNick: FC<Props> = ({ handleSubmit, register, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cl.title}>
        <img src={user_logo} alt="User logo" />
        <div className="w-[50%] max-w-[234px]">
          <p className="mb-[14px]">{t("chooseNick.title")}</p>
          <p className="text-[14px] text-[#6E6E6E]">
            {t("chooseNick.description")}
          </p>
        </div>
      </div>
      <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("nick")}
          placeholder={t("chooseNick.placeholder")}
        />
        <button id="chooseNickBut" type="submit">
          {t("chooseNick.done")}
        </button>
      </form>
    </>
  );
};

export default withErrorShow(withLoader(ChooseNick));
