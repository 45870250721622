import type { StateCreator } from "zustand";
import type { Subsription } from "utils/types/response/ProfileRes";

class userRestrictionsClass {
  constructor(
    public readonly categories: 3 | 50 | 126,
    public readonly accounts: 2 | 25 | 150,
    public readonly periodicSpends: 1 | 25 | 150,
    public readonly goals: 1 | 25 | 150,
    public readonly DowtAdvices: 1 | 25 | 150,
    public readonly spaces: 0 | 25 | 150,
    public readonly cards: 0 | 25 | 150,
  ) {}
}

interface State {
  subscription: Subsription;
  userRestrictions: userRestrictionsClass;
  isEmployee: boolean;
  isFree: boolean;
  setIsEmployee: (isEmployee: State["isEmployee"]) => void;
  setIsFree: (isFree: State["isFree"]) => void;
}

interface Actions {
  setSubscription: (subscription: Subsription) => void;
}

export interface SubsriptionSlice extends State, Actions {}

export const createSubscriptionSlice: StateCreator<SubsriptionSlice> = (
  set,
) => ({
  subscription: "free",
  isEmployee: false,
  isFree: true,
  setIsFree: (isFree) => set({ isFree }),
  setIsEmployee: (isEmployee) => set({ isEmployee }),
  userRestrictions: new userRestrictionsClass(3, 2, 1, 1, 1, 0, 0),
  setSubscription: (subscription) => {
    set({ subscription, isFree: subscription === "free" });
    switch (subscription) {
      case "business_lic":
      case "business_member_lic":
        set({
          userRestrictions: new userRestrictionsClass(
            126,
            150,
            150,
            150,
            150,
            150,
            150,
          ),
        });
        break;
      case "business_plan":
      case "business_member_plan":
        set({
          userRestrictions: new userRestrictionsClass(
            126,
            25,
            25,
            25,
            25,
            25,
            25,
          ),
        });
        break;
      case "free":
        set({
          userRestrictions: new userRestrictionsClass(3, 2, 1, 1, 1, 0, 0),
        });
        break;
    }
  },
});
