import { useCallback, useState, type ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import PrivacyMessage from "./PrivacyMessage";

const PrivacyMessageWrapper = () => {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState(
    !localStorage.getItem("disableStoreMessage"),
  );

  const [isAccepted, setIsAccepted] = useState(false);
  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setIsAccepted(e.target.checked);
    }, []);

  const handleOKClick = useCallback(() => {
    setShowMessage(false);
    localStorage.setItem("disableStoreMessage", "true");
  }, []);

  return showMessage ? (
    <PrivacyMessage
      t={t}
      handleCheckboxChange={handleCheckboxChange}
      isAccepted={isAccepted}
      handleOKClick={handleOKClick}
    />
  ) : null;
};

export default PrivacyMessageWrapper;
