import { AdminAPI } from "services/API/Admin";
import Slots4Caloborators from "./Slots4Caloborators";
import TotalDeposits from "./TotalDeposits";
import getSpaceId from "utils/constants/getSpaceId";
import { useQuery } from "@tanstack/react-query";
import getSpaceSlots from "utils/constants/getSpaceSlots";

interface MiniStatisticsProps {
  usersCount: number;
}

const MiniStatistics = ({ usersCount }: MiniStatisticsProps) => {
  const spaceId = getSpaceId();
  const slots = getSpaceSlots();

  const { data } = useQuery({
    queryKey: ["totalDeposits", spaceId],
    queryFn: async () => {
      const response = await AdminAPI.ViewDeposits(spaceId);
      return response.data;
    },
  });

  return (
    <div className="flex flex-row gap-4 mb-4">
      <Slots4Caloborators current={usersCount} max={slots ?? 1} />
      <TotalDeposits deposits={data ?? []} />
    </div>
  );
};

export default MiniStatistics;
