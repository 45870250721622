import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import CustomAlertContext from "CusomAlertContext";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardsAPI from "services/API/Cards";
import getSpaceId from "utils/constants/getSpaceId";
import showErrorMessage from "utils/constants/showErrorMessage";
import { z } from "zod";

const validator = z.object({
  phone: z
    .string()
    .max(50, { message: "enterPhone.errors.tooLong" }) 
    .min(2, { message: "enterPhone.errors.tooShort" }) 
    .transform((val) => val.replace(/ /g, ""))
    .refine(
      (val) => {
        const regex = /^\+\d{1,4}\d{6,14}$/;
        return regex.test(val);
      },
      { message: "enterPhone.errors.invalidFormat" },
    ),
});

interface Input {
  phone: string;
}

const EnterPhone = ({ handleSuccess }: { handleSuccess: () => void }) => {
  const { t } = useTranslation(); 
  const { mutate } = useMutation({
    mutationFn: CardsAPI.addPhone,
    onError: showErrorMessage,
    onSuccess: handleSuccess,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    resolver: zodResolver(validator),
  });

  const onSubmit: SubmitHandler<Input> = ({ phone }) => {
    mutate({ phone, spaceId: getSpaceId() });
  };

  const showCustomAlert = useContext(CustomAlertContext);

  useEffect(() => {
    if (errors.phone) {
      showCustomAlert(t(errors.phone.message as string)); 
    }
  }, [errors]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-4 flex gap-y-3 flex-col font-sans text-2xl"
    >
      <p className="text-3xl">{t("enterPhone.title")}</p>
      <input
        type="tel"
        placeholder={t("enterPhone.placeholder")}
        maxLength={50}
        minLength={2}
        className="bg-[#181818] w-full rounded-xl px-1 py-0.5 text-center border border-orange placeholder:text-[#6E6E6E]"
        {...register("phone")}
      />
      <button className="bg-orange font-normal text-black px-3 ml-auto rounded-xl duration-300 hover:bg-[#cf8800]">
        {t("enterPhone.button")} 
      </button>
    </form>
  );
};

export default EnterPhone;