import React from "react";
import cl from "./Loader.module.scss";

const LittleLoader: React.FC = () => {
  return (
    <div className={cl.loader}>
      <div className={cl.spinner}></div>
    </div>
  );
};

export default LittleLoader;
