import { FC, useState } from "react";
import cl from "./License.module.scss";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IProps {
  register: UseFormRegister<any>;
}

const License: FC<IProps> = ({ register }) => {
  const { t } = useTranslation();

  return (
    <div className={cl.form_license}>
      <input
        className="hidden"
        id="agreeLicense"
        {...register("license")}
        type="checkbox"
      />
      <label htmlFor="agreeLicense" id="confLicense">
        Okay
      </label>
      <p className="max-w-[294px] py-[9px] pl-5 text-[12px] text-[#6E6E6E]">
        {t("license.textPart1")}{" "}
        <a
          href="https://spendsplif.com/terms-and-privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("license.termsOfService")}
        </a>
        {t("license.textPart2")}
      </p>
    </div>
  );
};

export default License;
