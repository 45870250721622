import { type FC } from "react";
import type { UseFormRegister, FieldErrors } from "react-hook-form";
import cl from "./Field.module.scss";
import errorImg from "/src/image/login_p/login/error.svg";

interface IProps {
  register: UseFormRegister<any>;
  type: "email" | "text" | "password";
  placeholder: string;
  formValue: string;
  className?: string;
  errors: FieldErrors;
  autoComplete?: "off" | "on" | "new-password";
  id?: string;
}

const Field: FC<IProps> = ({
  register,
  formValue,
  type,
  placeholder,
  className,
  errors,
  autoComplete = "on",
  id,
}) => {
  return (
    <div className={cl.field}>
      {autoComplete === "off" ? (
        <textarea
          className={className}
          placeholder={placeholder}
          {...register(formValue)}
          {...(id && { id })}
        />
      ) : (
        <input
          className={className}
          type={type}
          placeholder={placeholder}
          {...register(formValue)}
          autoComplete={autoComplete}
          {...(id && { id })}
        />
      )}
      {errors[formValue] && <img src={errorImg} alt="error" />}
    </div>
  );
};

export default Field;
