import React, { type RefObject } from "react";
import TwoFA from "./TwoFA";
import ProfilePreview from "./ProfilePreview/ProfilePreview";
import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import type { IFormInputs } from "./SettingsContainer";
import withErrorShow from "utils/hoc/withErrorShow";
import Modal from "utils/components/Modal";
import ConfirmEmailContainer from "./ConfirmEmail";
import cl from "./Settings.module.scss";
import VerifyEmailContainer from "./VerifyEmailContainer.tsx";
import { useTranslation } from "react-i18next";
import AdditionalSettings from "./AdditionalSettings.tsx";
import LittleLoader from "utils/components/Loader/LittleLoader.tsx";

interface SettingsProps {
  settingsRef: RefObject<HTMLDivElement>;
  onBack: () => void;
  watch: UseFormWatch<IFormInputs>;
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  username: string;
  register: UseFormRegister<IFormInputs>;
  setValue: UseFormSetValue<IFormInputs>;
  tag: string;
  email: string;
  language: string;
  onSubmit: SubmitHandler<IFormInputs>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
  confirmingEmail: boolean;
  setConfirmingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  emailVerification: boolean;
  setEmailVerification: React.Dispatch<React.SetStateAction<boolean>>;
  newEmail: string;
  isLoading: boolean;
}

const Settings: React.FC<SettingsProps> = ({
  settingsRef,
  onBack,
  watch,
  handleSubmit,
  username,
  register,
  tag,
  email,
  language,
  onSubmit,
  isEditing,
  setIsEditing,
  logout,
  confirmingEmail,
  setConfirmingEmail,
  emailVerification,
  setEmailVerification,
  newEmail,
  isLoading,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <aside ref={settingsRef} className={cl.container}>
      {isLoading ? (
        <LittleLoader />
      ) : (
        <>
          <div
            className="flex-grow  p-5  overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            <div className={"flex items-center justify-centermb-4 relative"}>
              <button
                onClick={onBack}
                type="button"
                className={
                  "absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
                }
              >
                ←
              </button>
              <h2 className={"text-[20px] text-center flex-grow"}>
                {t("settings.title")}
              </h2>
            </div>
            <ProfilePreview
              watch={watch}
              handleSubmit={handleSubmit}
              username={username}
              register={register}
              tag={tag}
              email={email}
              language={language}
              onSubmit={onSubmit}
              isEditing={isEditing}
            />
            <AdditionalSettings
              isEditing={isEditing}
              setValue={setValue}
              register={register}
            />
            <TwoFA />
            {isEditing ? (
              <div className="mt-4 flex justify-between">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className={cl.cancelButton}
                >
                  {t("settings.cancel")}
                </button>
                <button
                  type="submit"
                  form="Account-form"
                  className={cl.saveButton}
                >
                  {t("settings.saveChanges")}
                </button>
              </div>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setIsEditing(true)}
                  className={cl.editButton}
                >
                  {t("settings.edit")}
                </button>
                <p className={cl.logoutText} onClick={logout}>
                  {t("settings.logout")}
                </p>
              </>
            )}
          </div>
          {confirmingEmail && (
            <Modal setActive={setConfirmingEmail}>
              <ConfirmEmailContainer
                oldEmail={email}
                setConfirmingEmail={setConfirmingEmail}
                newEmail={newEmail}
              />
            </Modal>
          )}
          {emailVerification && (
            <Modal setActive={setEmailVerification}>
              <VerifyEmailContainer
                setEmailVerification={setEmailVerification}
              />
            </Modal>
          )}
        </>
      )}
    </aside>
  );
};

export default withErrorShow(Settings);
