import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./home_page.module.scss";
import Expenses from "./Expenses";
import Overlay from "./Overlay";
import { SpaceAPI } from "services/API";
import DOMPurify from "dompurify";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import smalltalk from "utils/libraries/smalltalk";
import { useTranslation } from "react-i18next";
import getSpaceId from "utils/constants/getSpaceId";
import CustomAlertUpdateBank from "utils/components/AlertAboutLimit copy/CustomAlertUpdateBank";
import Modal from "utils/components/Modal";
import Loader from "utils/components/Loader/Loader.tsx";
import eventEmitter from "services/EventEmitter";
import useStore from "store";
import { AccountAPI } from "services/API/Account";
import { useQuery } from "@tanstack/react-query";

interface IProps {
  loggedIn: boolean;
}

interface IData {
  bank: string;
  connection: string;
  connection_id: number;
}

const HomePage: React.FC<IProps> = ({ loggedIn }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [showingCustomAlert, setShowingCustomAlert] = useState(false);
  const [data, setData] = useState<IData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = getSpaceId();

  useEffect(() => {
    const handleSetLoading = (loading: boolean) => {
      setIsLoading(loading);
    };

    eventEmitter.on("setLoading", handleSetLoading);

    return () => {
      eventEmitter.off("setLoading", handleSetLoading);
    };
  }, []);

  const { accounts, totalBalance, setAccounts, setTotalBalance } = useStore();

  const { data: space } = useQuery({
    queryKey: ["get active space"],
    queryFn: () => SpaceAPI.getActiveSpace(),
  });

  useEffect(() => {
    if (space) {
      localStorage.setItem("space", JSON.stringify(sanitizeObject(space)));
      // after logining through google - accounts and totalBalance - undefined
      if (!accounts || !totalBalance) {
        setIsLoading(true);
        AccountAPI.ViewAccounts(space.id)
          .then((res) => {
            setAccounts(res.data.Account);
            setTotalBalance(res.data.TotalBalance);
          })
          .finally(() => setIsLoading(false));
      }
    }
  }, [space]);

  useEffect(() => {
    spaceId &&
      SpaceAPI.spaceStatus(spaceId)
        .then((res) => {
          if (!res.active) {
            navigate("/admin-panel/");
            smalltalk.alert(t("admin.service"), t("admin.yougottapay"));
          } else {
            SpaceAPI.isBankActionRequired(spaceId)
              .then((res) => {
                if (res.status === 204) {
                } else if (res.status === 200) {
                  setData(res.data);
                  setShowingCustomAlert(true);
                }
              })
              .catch((error) => {
                console.error("Error fetching spaces:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching spaces:", error);
        });
  }, []);

  useEffect(() => {
    if (id) {
      navigate("/home", { state: { spaceId: DOMPurify.sanitize(id) } });
    }
  }, [id, navigate]);

  const handleUpdateClick = () => {
    if (!data) return;

    setIsLoading(true);

    SpaceAPI.updateConnection(spaceId, {
      bank_connection_id: data.connection_id,
    })
      .then((res) => {
        if (res) {
          window.location.href = res.url;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching spaces:", error);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.main}>
      <Expenses />
      <Overlay />
      {showingCustomAlert && data && (
        <Modal
          setActive={setShowingCustomAlert as Dispatch<SetStateAction<boolean>>}
        >
          <CustomAlertUpdateBank
            data={data}
            handleUpdateClick={handleUpdateClick}
            setActive={setShowingCustomAlert}
          />
        </Modal>
      )}
    </div>
  );
};

export default HomePage;
