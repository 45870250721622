import type { Dispatch, FC, SetStateAction } from "react";
import Plus from "image/expenses/plus.svg";
import LeftArrow from "image/expenses/LeftArrow.svg";
import RightArrow from "image/expenses/RightArrow.svg";
import Modal from "utils/components/Modal";
import { createPortal } from "react-dom";
import CreateAccountContainer from "./CreateAccount";
import ShowAccount from "./ShowAccount";
import type {
  ITotalBalance,
  OneAccount,
} from "utils/types/response/AccountRes";
import ShowTotalBalance from "./ShowTotalBalance";

interface Props {
  setAccountNumber: Dispatch<SetStateAction<number>>;
  Account: OneAccount | undefined;
  currencySymbol: string;
  setCreatingAccount: Dispatch<SetStateAction<boolean>>;
  creatingAccount: boolean;
  showTotalBalance: boolean;
  TotalBalance: ITotalBalance;
  accountsLength: number;
  isLimitReached: boolean;
  blockedCurrentAccount: boolean;
  // TODO : fix types
  showCustomAlert: (text: string, options: { itsLimitAlert: boolean }) => void;
}

const AccountCircle: FC<Props> = ({
  setAccountNumber,
  Account,
  currencySymbol,
  setCreatingAccount,
  creatingAccount,
  showTotalBalance,
  TotalBalance,
  accountsLength,
  isLimitReached,
  blockedCurrentAccount,
  showCustomAlert,
}) => {
  return (
    <>
      <div
        id="CenterCircle"
        className="bg-circle_account border-[#FF7528] border-4 bg-[length:85%]
    bg-no-repeat bg-[center_top_0.5rem] flex justify-between 
    items-center size-[300px] bg-[#141414] rounded-full"
      >
        <button
          type="button"
          id="arrowAccount2"
          onClick={() =>
            setAccountNumber((prev) => (prev > -1 ? prev - 1 : -1))
          }
          className="w-6 mx-2 z-20"
        >
          <img src={LeftArrow} alt="left arrow" />
        </button>
        {Account ? (
          <ShowAccount
            currencySymbol={currencySymbol}
            disabled={blockedCurrentAccount}
            Account={Account}
            blockedCurrentAccount={blockedCurrentAccount}
          />
        ) : showTotalBalance ? (
          <ShowTotalBalance TotalBalance={TotalBalance} />
        ) : (
          <button
            id="plusAccount"
            type="button"
            disabled={blockedCurrentAccount}
            onClick={() => {
              isLimitReached
                ? showCustomAlert("_", { itsLimitAlert: true })
                : setCreatingAccount(true);
            }}
            className="z-10 disabled:cursor-not-allowed w-16 h-16 rounded-full"
          >
            <img src={Plus} alt="plus" />
          </button>
        )}
        <button
          id="arrowAccount"
          type="button"
          onClick={() =>
            setAccountNumber((prev) =>
              prev < accountsLength + 1 ? prev + 1 : accountsLength + 1
            )
          }
          className="w-6 mx-2 z-20"
        >
          <img src={RightArrow} alt="right arrow" />
        </button>
      </div>
      {/* I'm using createPortal because the TransformWrapper 
      conflicts with the full screen rendering of this modal */}
      {creatingAccount &&
        createPortal(
          <Modal setActive={setCreatingAccount}>
            <CreateAccountContainer setModal={setCreatingAccount} />
          </Modal>,
          document.body
        )}
    </>
  );
};

export default AccountCircle;
