import { StatisticsAPI } from "services/API/Statistics";
import { useQuery } from "@tanstack/react-query";
import StatisticsPage from "./StatisticsPage";
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from "react";
import type { StatisticsRes } from "utils/types/response/StatisticsResponse";
import { useNavigate } from "react-router-dom";

type Period = "month" | "three_month" | "week" | "year";

export const StatisticsContext = createContext<{
  data: StatisticsRes | undefined;
  period: Period;
  percent: boolean;
  setPercent: Dispatch<SetStateAction<boolean>>;
  setPeriod: Dispatch<SetStateAction<Period>>;
  currency: string | undefined;
}>({} as any);

const getSpaceIdFromLocalStorage = (): number | undefined => {
  const space = localStorage.getItem("space");
  if (!space) return undefined;
  try {
    const parsedSpace = JSON.parse(space);
    return parsedSpace.id ? Number(parsedSpace.id) : undefined;
  } catch (e) {
    console.error("Failed to parse space from localStorage", e);
    return undefined;
  }
};

const getCurrencyFromLocalStorage = (): string | undefined => {
  const space = localStorage.getItem("space");
  if (!space) return undefined;
  try {
    const parsedSpace = JSON.parse(space);
    return parsedSpace.currency || undefined;
  } catch (e) {
    console.error("Failed to parse space from localStorage", e);
    return undefined;
  }
};

export default () => {
  window.ReactNativeWebView?.postMessage("Here");
  const navigate = useNavigate();
  if (
    encodeURIComponent(window.location.pathname).includes("mobileStatistics") &&
    !window.ReactNativeWebView
  ) {
    navigate("/statistics");
  }

  const spaceId = getSpaceIdFromLocalStorage();
  const currencyFromStorage = getCurrencyFromLocalStorage();

  const { data, isPending } = useQuery({
    queryKey: ["get statistics", spaceId],
    queryFn: () => {
      if (!spaceId) throw new Error("Space ID not found in localStorage");
      return StatisticsAPI.get(spaceId).then((res) => res.data);
    },
    enabled: !!spaceId,
  });

  const currency = currencyFromStorage || 
    (data?.Expenses.Year
      ? typeof data.Expenses.Year === "object"
        ? Object.values(data.Expenses.Year)[0].Loss.match(/[a-zA-Z]+$/)?.[0]
        : data.Expenses.Year.match(/[a-zA-Z]+$/)?.[0]
      : undefined);

  const [period, setPeriod] = useState<Period>("month");
  const [percent, setPercent] = useState<boolean>(true);

  return (
    <StatisticsContext.Provider
      value={{ data, period, setPeriod, percent, setPercent, currency }}
    >
      <StatisticsPage isLoading={isPending} />
    </StatisticsContext.Provider>
  );
};