import type { Step } from "react-joyride";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type TourStepConfig = Omit<Step, "content"> & { translationKey: string };

const TOUR_STEP_CONFIG: TourStepConfig[] = [
  {
    target: "#CenterCircle",
    translationKey: "tour.centerCircle",
    disableBeacon: true,
  },
  {
    target: "#IncomingAndOutgoing",
    translationKey: "tour.incomeOutgoing",
    disableBeacon: true,
  },
  {
    target: "#arrowAccount",
    translationKey: "tour.arrowAccount",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#CenterCircle",
    translationKey: "tour.totalBalance",
    disableBeacon: true,
  },
  {
    target: "#arrowAccount",
    translationKey: "tour.newAccount",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#CenterCircle",
    translationKey: "tour.createAccount",
    disableBeacon: true,
  },
  {
    target: "#createAccountField",
    translationKey: "tour.createAccountField",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#categoryItem",
    translationKey: "tour.categoryItem",
    disableBeacon: true,
  },
  {
    target: "#categoryItem",
    translationKey: "tour.categoryItemHover",
    disableBeacon: true,
  },
  {
    target: "#SpendModal",
    translationKey: "tour.spendModal",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#notifications",
    translationKey: "tour.notificationButton",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#notificationWindow",
    translationKey: "tour.notification",
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#MenuOpenBtn",
    translationKey: "tour.menuButton",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#settings",
    translationKey: "tour.settings",
    disableBeacon: true,
    placement: "right-start",
  },
  {
    target: "#converter",
    translationKey: "tour.converter",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#goals",
    translationKey: "tour.goals",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#statistics",
    translationKey: "tour.statistics",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#history",
    translationKey: "tour.history",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#shop",
    translationKey: "tour.shop",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#ExcelButton",
    translationKey: "tour.excel",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#ExcelImportButton",
    translationKey: "tour.importExcel",
    disableBeacon: true,
    placement: "top",
  },
  {
    target: "#ExcelExportButton",
    translationKey: "tour.exportExcel",
    disableBeacon: true,
    placement: "top",
  },
  {
    target: "#ids\\.cards",
    translationKey: "tour.cards",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#ids\\.periodic_spends",
    translationKey: "tour.periodic_spends",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#adminpanel",
    translationKey: "tour.admin",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#tickets",
    translationKey: "tour.ticket",
    disableBeacon: true,
    placement: "right-end",
  },
];

export const useTourSteps = () => {
  const { t } = useTranslation();

  const tourSteps = useMemo(
    () =>
      TOUR_STEP_CONFIG.map((step) => ({
        ...step,
        content: t(step.translationKey),
      })),
    [t],
  );

  return tourSteps;
};

export default useTourSteps;
