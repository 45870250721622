import { useEffect, useState, useMemo, useContext } from "react";
import currencyToSymbolMap from "currency-symbol-map";
import AccountCircle from "./AccountCircle";
import useStore from "store";
import { CustomAlertContext } from "CusomAlertContext";
import eventEmitter from "services/EventEmitter";

const AccountCircleContainer = () => {
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const {
    accounts,
    totalBalance,
    userRestrictions: { accounts: limit },
  } = useStore();

  const showCustomAlert = useContext(CustomAlertContext);

  const isLimitReached = useMemo(
    () => (accounts ? accounts.length >= limit : false),
    [accounts, limit]
  );

  const [accountNumber, setAccountNumber] = useState<number>(
    Number.parseInt(localStorage.getItem("accountNumber")) || 0
  );

  // Подписываемся на событие изменения accountNumber
  useEffect(() => {
    const handleSetAccountNumber = (number: number) => {
      setAccountNumber(number);
      localStorage.setItem("accountNumber", number.toString());
    };

    // Подписка на событие
    eventEmitter.on("setAccountNumber", handleSetAccountNumber);

    // Очистка подписки при размонтировании
    return () => {
      eventEmitter.off("setAccountNumber", handleSetAccountNumber);
    };
  }, []);

  const Account = accounts ? accounts[accountNumber] : undefined;

  const blockedCurrentAccount = useMemo(() => {
    return accounts && accounts[accountNumber] && accountNumber + 1 > limit
      ? true
      : false;
  }, [accounts, accountNumber, limit]);

  const currencySymbol = Account && currencyToSymbolMap(Account?.currency);
  const showTotalBalance =
    totalBalance &&
    accounts?.length !== 0 &&
    accounts?.length === accountNumber;
  const TotalBalance = totalBalance ? totalBalance[0] : undefined;

  return (
    <AccountCircle
      setAccountNumber={setAccountNumber}
      Account={Account}
      currencySymbol={currencySymbol}
      setCreatingAccount={setCreatingAccount}
      creatingAccount={creatingAccount}
      showTotalBalance={showTotalBalance}
      TotalBalance={TotalBalance}
      accountsLength={accounts?.length}
      isLimitReached={isLimitReached}
      blockedCurrentAccount={blockedCurrentAccount}
      showCustomAlert={showCustomAlert}
    />
  );
};

export default AccountCircleContainer;
