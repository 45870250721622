import React, { createContext, useContext, useState, useCallback } from "react";
import type { CallBackProps } from "react-joyride";
import eventEmitter from "services/EventEmitter";

interface TourContextType {
  tourRunning: boolean;
  stepIndex: number;
  startTour: () => void;
  stopTour: () => void;
  handleTourCallback: (data: CallBackProps) => void;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TourContext = createContext<TourContextType | undefined>(undefined);

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tourRunning, setTourRunning] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const startTour = useCallback(() => {
    const checkElementAndStartTour = () => {
      const arrowElement = document.querySelector(
        "#arrowAccount2",
      ) as HTMLElement | null;

      if (!arrowElement) {
        setTimeout(checkElementAndStartTour, 100);
        return;
      }

      setTourRunning(true);
      setStepIndex(0);
    };

    checkElementAndStartTour();
  }, []);

  const reStartTour = useCallback(() => {
    const checkElementAndStartTour = () => {
      eventEmitter.emit("setNothFalse");

      eventEmitter.emit("setMenuFalse");

      eventEmitter.emit("setAccountNumber", 0);

      setTourRunning(true);
      setStepIndex(0);
    };

    checkElementAndStartTour();
  }, []);

  const stopTour = useCallback(() => {
    setTourRunning(false);
    eventEmitter.emit("setNothFalse");
    eventEmitter.emit("setMenuFalse");
    setStepIndex(0);
  }, []);

  const handleTourCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, type } = data;

      if (type === "step:after") {
        switch (action) {
          case "next":
            if (index !== 25) {
              setStepIndex(index + 1);
            } else {
              stopTour();
            }
            break;
          case "prev":
            setStepIndex(index - 1);
            break;
          default:
            stopTour();
            break;
        }
      }
    },
    [stopTour],
  );

  const contextValue = React.useMemo(
    () => ({
      tourRunning,
      stepIndex,
      startTour,
      stopTour,
      handleTourCallback,
      setStepIndex,
      reStartTour,
    }),
    [
      tourRunning,
      stepIndex,
      startTour,
      stopTour,
      handleTourCallback,
      reStartTour,
    ],
  );

  return (
    <TourContext.Provider value={contextValue}>{children}</TourContext.Provider>
  );
};

export const useTour = (): TourContextType => {
  const context = useContext(TourContext);

  if (context === undefined) {
    throw new Error("useTour must be used within a TourProvider");
  }

  return context;
};
