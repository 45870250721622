import React, { useState } from "react";
import plus_logo from "image/Cards/plus.svg";
import getSpaceId from "utils/constants/getSpaceId";
import CardsAPI from "services/API/Cards";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import Modal from "utils/components/Modal";
import EnterPhone from "./subcomponents/EnterPhone";
import Banks from "./subcomponents/Banks";
import smalltalk from "utils/libraries/smalltalk";
import useStore from "store/store";
import { useTranslation } from "react-i18next";

interface NumberMissingRes {
  message: "No record found";
}

const TinkCardConnection: React.FC = () => {
  const [enteringPhone, setEnteringPhone] = useState(false);
  const [selectingBank, setSelectingBank] = useState(false);
  const { isFree } = useStore();
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationFn: CardsAPI.addCard,
    onSuccess: () => setSelectingBank(true),
    onError: (e: AxiosError<NumberMissingRes | undefined>) => {
      if (e.response?.data?.message === "No record found") {
        setEnteringPhone(true);
      }
    },
  });

  const handleClick = () => {
    if (isFree) {
      smalltalk.alert(t("alert.title_cards"), t("alert.desc_cards")).then();
      return;
    }
    mutate(getSpaceId());
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <button className="mt-4 mb-4" onClick={handleClick} type="button">
          <img src={plus_logo} className="!rounded-full" alt="add payment" />
        </button>
      </div>
      {enteringPhone && (
        <Modal setActive={setEnteringPhone}>
          <EnterPhone handleSuccess={() => setEnteringPhone(false)} />
        </Modal>
      )}
      {selectingBank && (
        <Modal setActive={setSelectingBank}>
          <Banks handleCancelClick={() => setSelectingBank(false)} />
        </Modal>
      )}
    </>
  );
};

export default TinkCardConnection;
