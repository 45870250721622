import { useState } from "react";
import TabPort_logo from "image/p_menu/TabPort.svg";
import { animated, useTransition } from "@react-spring/web";
import AdditionalButtons from "./AdditionalButtons";

const ExcelButton = () => {
  // state responds for showing the import/export buttons
  const [showAdditional, setShowAdditional] = useState(false);

  const transitions = useTransition(showAdditional, {
    from: {
      transform: "scaleX(0)",
      transformOrigin: "left",
      opacity: 0,
    },
    enter: {
      transform: "scaleX(1)",
      transformOrigin: "left",
      opacity: 1,
    },
    leave: {
      transform: "scaleX(0.6)",
      transformOrigin: "left",
      opacity: 0,
    },
    config: { tension: 220, friction: 20 },
  });

  return (
    <span className="flex w-[13.4rem] h-[82px] items-center justify-between">
      <button
        id="ExcelButton"
        type="button"
        title="Import/export Excel table"
        className="flex h-[3.5rem] w-[60px] items-center justify-center
                  rounded-[1rem] border border-[#727171]
                  bg-[#262626] hover:border-[#ffa800]"
        onClick={() => setShowAdditional((prev) => !prev)}
      >
        <img src={TabPort_logo} alt="button" />
      </button>

      {transitions((style, item) =>
        item ? (
          <animated.span
            style={style}
            className="flex w-[9rem] rounded-r-2xl bg-[#1c1c1c] bg-opacity-70 p-1.5 justify-between"
          >
            <AdditionalButtons />
          </animated.span>
        ) : null,
      )}
    </span>
  );
};

export default ExcelButton;
