import Import_logo from "image/p_menu/ImportExcel.svg";
import Export_logo from "image/p_menu/ExportExcel.svg";
import { Link } from "react-router-dom";

const AdditionalButtons = () => {
  return (
    <>
      <Link
        to="/import-excel"
        title="Import"
        id="ExcelImportButton"
        className="flex h-[3.7rem] w-[60px] items-center justify-center
                  rounded-[1rem] border border-[#727171]
                  bg-[#262626] hover:border-[#ffa800]"
      >
        <img src={Import_logo} alt="button" />
      </Link>
      <Link
        to="/export-excel"
        title="Export"
        id="ExcelExportButton"
        className="flex h-[3.7rem] w-[60px] items-center justify-center
                  rounded-[1rem] border border-[#727171]
                  bg-[#262626] hover:border-[#ffa800]"
      >
        <img src={Export_logo} alt="button" />
      </Link>
    </>
  );
};

export default AdditionalButtons;
