import EmployeeTickets from "./employee";
import UserTickets from "./user";
import useStore from "store";
import usePanelResizing from "utils/hooks/usePanelResizing";
import { useRef } from "react";

const TicketsWindow = ({ onBack }: { onBack: () => void }) => {
  const { isEmployee } = useStore();
  const ticketsRef = useRef(null);
  usePanelResizing(ticketsRef);

  return (
    <aside
      ref={ticketsRef}
      style={{
        scrollbarWidth: "none",
      }}
      className="bg-[#181818] top-[2.3%] left-1.5 shadow-md shadow-[#FFA80080] absolute flex flex-col gap-y-3 overflow-auto text-white p-4 font-vela rounded-3xl"
    >
      {isEmployee ? (
        <EmployeeTickets onBack={onBack} />
      ) : (
        <UserTickets onBack={onBack} />
      )}
    </aside>
  );
};

export default TicketsWindow;
