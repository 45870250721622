import { useTranslation } from "react-i18next";
import cl from "./TwoFA.module.scss";

const Switcher = () => (
  <label className="flex items-center row-[1/3] justify-between text-lg text-white w-full relative">
    <div className="relative">
      {/* When 2FA will be available we will add it */}
      {/* <input
        disabled
        type="checkbox"
        id='just'
        className={`none hidden ${cl.input}`}
      /> */}
      <label
        // htmlFor='just'
        className={cl.toggle}
      >
        <span className="absolute cursor-not-allowed transition-[all_0.2s_ease] shadow-[0_3px_8px_rgba(154,153,153,0.5)] top-0 left-[-10px] w-[20px] h-[20px] block bg-white rounded-[10px]" />
      </label>
    </div>
  </label>
);

const Option = ({
  title,
  description,
  sectionClass,
}: {
  title: string;
  description: string;
  sectionClass?: string;
}) => (
  <section
    className={`text-white grid grid-cols-[minmax(0,8fr)_minmax(0,1fr)] grid-rows-2 border border-[#121212] bg-[#1b1b1b] py-3 pl-6 ${
      sectionClass ? sectionClass : ""
    }`}
  >
    <p className="text-[22px]">{title}</p>
    <p className="text-[#636363] row-[2/3] text-[14px]">{description}</p>
    <Switcher />
  </section>
);

const TwoFA = () => {
  const { t } = useTranslation(); // Хук для перевода

  const optionDescription = t("twoFA.description");
  return (
    <section className="mt-6">
      <p className="text-white border-[#121212] border rounded-t-3xl text-[24px] bg-[#1b1b1b] py-4 pl-6">
        {t("twoFA.title")}
      </p>
      <Option
        sectionClass="rounded-b-3xl"
        title={t("twoFA.email")}
        description={optionDescription}
      />
      {/* <Option
        
        title={t("twoFA.mobile")}
        description={optionDescription}
      /> */}
    </section>
  );
};

export default TwoFA;
