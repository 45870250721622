import { CustomAlertContext } from "CusomAlertContext";
import Lock from "image/lock";
import { useContext } from "react";

const Block = () => {
  const showCustomAlert = useContext(CustomAlertContext);
  return (
    <button
      className="w-full h-full bg-[rgba(0,0,0,0.6)] flex left-0 top-0 absolute rounded-2xl z-10"
      onClick={() => showCustomAlert("_", { itsLimitAlert: true })}
    >
      <span className="m-auto size-14">
        <Lock color="#FFA800" />
      </span>
    </button>
  );
};

export default Block;
