import NotificationsSvg from "/src/image/notifications.svg";
import cl from "./Notifications.module.scss";
import NotificationsWindowContainer from "./NotificationsWindow";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { NotificationsAPI } from "services/API/Notifications";
import { useQuery } from "@tanstack/react-query";
import { useTour } from "components/Tour/Hooks/useTour";
import eventEmitter from "services/EventEmitter";

const Notifications = () => {
  const [showingNotifications, setShowingNotifications] = useState(false);
  const NotificationsRef = useRef(null);
  const { tourRunning } = useTour();
  useOnClickOutside(NotificationsRef, () => {
    if (!tourRunning) {
      setShowingNotifications(false);
    }
  });
  const { data } = useQuery({
    queryKey: ["how many notifications unseen"],
    queryFn: () => NotificationsAPI.howManyUnseen(),
  });
  const unseen_count = data?.data.unseen_count;

  useEffect(() => {
    const handleNothTrue = () => {
      setShowingNotifications(true);
    };

    const handleNothFalse = () => {
      setShowingNotifications(false);
    };

    eventEmitter.on("setNothTrue", handleNothTrue);

    eventEmitter.on("setNothFalse", handleNothFalse);
  }, []);

  return (
    <>
      <button
        type="button"
        id="notifications"
        onClick={() => setShowingNotifications(true)}
        className={cl.button}
      >
        <img src={NotificationsSvg} alt="notifications" />
        {unseen_count ? (
          <span className="bg-orange absolute bottom-0 left-0 px-2 !w-auto !h-auto rounded-full">
            {unseen_count}
          </span>
        ) : (
          <></>
        )}
      </button>
      {showingNotifications && (
        <NotificationsWindowContainer NotificationsRef={NotificationsRef} />
      )}
    </>
  );
};

export default Notifications;
