import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { IFormInputs } from "../SettingsContainer.tsx";
import LanguageSelector from "./LanguageSelector";

interface InfProps {
  title: "E-mail" | "Password" | "Language";
  content: string;
  index: number;
  isEditing: boolean;
  register: UseFormRegister<IFormInputs>;
  watch: UseFormWatch<IFormInputs>;
  isLanguageField?: boolean;
  currentLanguage?: string;
  onLanguageChange?: (lang: string) => void;
}

const Inf = ({
  title,
  content,
  index,
  isEditing,
  register,
  watch,
  isLanguageField,
  currentLanguage,
  onLanguageChange,
}: InfProps) => {
  return (
    <section
      className={`${
        index === 0 ? "rounded-tr-3xl" : ""
      } ${index === 2 ? "rounded-br-3xl" : ""}`}
    >
      <p className="text-white text-[22px]">{title}</p>
      <div className="w-full h-[24px]">
        {isLanguageField ? (
          <LanguageSelector
            currentLanguage={currentLanguage}
            onLanguageChange={onLanguageChange}
          />
        ) : isEditing ? (
          <input
            className="w-full bg-transparent text-[#636363] text-[16px] outline-none"
            autoComplete={title === "Password" ? "off" : "on"}
            {...register(title)}
          />
        ) : (
          <p className="w-full text-[#636363] text-[16px]">{content}</p>
        )}
      </div>
    </section>
  );
};

export default Inf;
