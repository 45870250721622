import EventEmitter from "eventemitter3";

interface Events {
  setAccountNumber: (accountNumber: number) => void;
  spaceWasUpdated: () => void;
  setNothFalse: () => void;
  setMenuFalse: () => void;
}

const eventEmitter = new EventEmitter<Events>();

export default eventEmitter;
