import { z } from "zod";
import { currencyObj } from "../defObjects";
import { titleObj } from "../defObjects";

export const valSchema = z.object({
  title: titleObj,
  includeTotalBal: z.boolean(),
  currency: currencyObj,
  balance: z
    .string()
    .refine(
      (value) => {
        if (value === "") {
          return true;
        }
        const parsedValue = Number.parseFloat(value);
        return !Number.isNaN(parsedValue) && parsedValue >= 0;
      },
      { message: "the balance must be a positive number" },
    )
    .refine((value) => value === "" || Number.parseFloat(value) < 99000000000, {
      message: "the balance is too big",
    })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The balance contains prohibited characters",
    }),
});
