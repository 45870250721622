import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import checklist_logo from "image/Goals/сhecklist.svg";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";
import type { Dispatch, SetStateAction } from "react";

const CreateGoal = ({
  setCreatingGoal,
}: { setCreatingGoal: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title
        header={t("createGoal.header")}
        title={t("createGoal.title")}
        image={checklist_logo}
        className="rounded-tr-3xl py-8"
      />
      <FormContainer setCreatingGoal={setCreatingGoal} />
    </>
  );
};

export default CreateGoal;
