import { useState, type RefObject } from "react";
import { useQuery } from "@tanstack/react-query";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import PeriodicSpendsWindow from "./PeriodicSpends";
import useStore from "store";
import usePanelResizing from "utils/hooks/usePanelResizing";

interface PeriodicSpendsWindowProps {
  featureRef: RefObject<HTMLElement>;
  onBack: () => void;
}

const PeriodicSpendsContainer = ({
  featureRef,
  onBack,
}: PeriodicSpendsWindowProps) => {
  const { data, isPending } = useQuery({
    queryKey: ["get periodic spends"],
    queryFn: () => PeriodicSpendsAPI.getSpends().then((res) => res.data),
  });

  const [creatingPayment, setCreatingPayment] = useState<boolean>(false);

  useSetCoolScroll(featureRef);
  usePanelResizing(featureRef);

  const {
    userRestrictions: { periodicSpends: limit },
  } = useStore();
  const isLimitReached = data?.length >= limit;

  return (
    <PeriodicSpendsWindow
      isLimitReached={isLimitReached}
      featureRef={featureRef}
      onBack={onBack}
      isPending={isPending}
      creatingPayment={creatingPayment}
      setCreatingPayment={setCreatingPayment}
      data={data}
      limit={limit}
    />
  );
};

export default PeriodicSpendsContainer;
