import { useMutation, useQuery } from "@tanstack/react-query";
import CardsAPI from "services/API/Cards";
import getSpaceId from "utils/constants/getSpaceId";
import SearchField from "utils/components/SearchField";
import { useContext, useMemo, useRef, useState, type FC } from "react";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import type { Bank } from "utils/types/response/CardsRes";
import { z } from "zod";
import CustomAlertContext from "CusomAlertContext";
import showErrorMessage from "utils/constants/showErrorMessage";
import { useTranslation } from "react-i18next";
import LittleLoaderElement from "utils/components/Loader/LittleLoaderElement";
import eventEmitter from "services/EventEmitter";
import smalltalk from "utils/libraries/smalltalk";

interface Props {
  handleCancelClick: () => void;
}

const connectionSchema = z
  .string()
  .max(50, { message: "banks.errors.tooLong" })
  .refine((val) => val.trim() === "" || val.length >= 2, {
    message: "banks.errors.tooShort",
  })
  .refine((val) => !/[<>]/.test(val), "banks.errors.invalidCharacters");

const Banks: FC<Props> = ({ handleCancelClick }) => {
  const { t } = useTranslation();
  const [isAgreed1, setIsAgreed1] = useState(false);
  const [isAgreed2, setIsAgreed2] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["get banks"],
    queryFn: () => CardsAPI.banksView(getSpaceId()).then((res) => res.data),
  });

  const { mutate } = useMutation({
    mutationFn: CardsAPI.bankConnection,
    onSuccess: (res) => {
      eventEmitter.emit("setLoading", true);
      window.location.href = res.data.url;
    },
    onError: (error) => {
      showErrorMessage(error);
      eventEmitter.emit("setLoading", false);
    },
  });

  const [search, setSearch] = useState("");
  const [selectedBank, setSelectedBank] = useState<undefined | Bank>(undefined);
  const [connectionName, setConnectionName] = useState("");

  const filtredData = useMemo(
    () =>
      data?.filter((bank) =>
        bank.name.toLowerCase().includes(search.toLowerCase()),
      ),
    [data, search],
  );

  const listRef = useRef<HTMLUListElement>(null);
  useSetCoolScroll(listRef);

  const showCustomAlert = useContext(CustomAlertContext);
  const handleSubmit = () => {
    if (!isAgreed1 || !isAgreed2) {
      smalltalk.alert(t("noAgreed"), t("noAgreeDes")).then();
      return;
    }

    try {
      connectionSchema.parse(connectionName);
      // selectedBank is not undefined because the submit button is disabled when it is
      const { id, name } = selectedBank as Bank;
      eventEmitter.emit("setLoading", true);
      mutate({
        spaceId: getSpaceId(),
        data: {
          bank_id: id,
          bankConnectionName: connectionName.trim() || name,
        },
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        showCustomAlert(t(err.errors[0].message));
      }
      eventEmitter.emit("setLoading", false);
    }
  };

  return (
    <main className="p-5 max-w-4xl font-sans text-2xl min-w-[712px] flex flex-col gap-4">
      <p className="text-4xl">{t("banks.title")}</p>
      <input
        className="bg-[#191a1c] pl-4 w-full py-2 px-1.5 rounded-md placeholder:text-[#727272] font-semibold"
        placeholder={t("banks.connectionNamePlaceholder")}
        onChange={(e) => setConnectionName(e.target.value)}
        value={connectionName}
        minLength={2}
        maxLength={50}
      />
      <SearchField
        value={search}
        handleClear={() => setSearch("")}
        handleChange={(e) => setSearch(e.target.value)}
      />
      <ul
        ref={listRef}
        className="gap-1 overflow-auto max-h-[23dvh] grid grid-cols-3"
      >
        {isLoading ? (
          <LittleLoaderElement />
        ) : filtredData && filtredData.length ? (
          filtredData.map(({ name, id }) => (
            <button
              className={`bg-[#181818] w-56 p-2 rounded-md duration-300 ${id === selectedBank?.id ? "bg-orange text-black" : ""}`}
              onClick={() => setSelectedBank({ id, name })}
              key={`bank ${id}`}
            >
              {name}
            </button>
          ))
        ) : (
          <p className="mx-auto text-3xl h-10 col-[1/4]">
            {t("banks.notFound")}
          </p>
        )}
      </ul>
      <div className="text-gray-500 flex items-center select-none">
        <input
          className="rounded-[4px] my-auto relative checked:bg-orange checked:border-orange checked:before:top-[50%] checked:before:absolute checked:before:left-[50%]  checked:before:translate-x-[-50%] checked:before:text-[#181818]  checked:before:translate-y-[-50%] checked:before:content-['✔'] duration-300 appearance-none border-2 border-[#CED4DA] size-5"
          type="checkbox"
          checked={isAgreed1}
          onChange={(e) => setIsAgreed1(e.target.checked)}
        />
        <p className="font-normal ml-2 text-xl">{t("iAgreeWithDataSeen")}</p>
      </div>
      <div className="text-gray-500 flex items-center select-none">
        <input
          className="rounded-[4px] my-auto relative checked:bg-orange checked:border-orange checked:before:top-[50%] checked:before:absolute checked:before:left-[50%]  checked:before:translate-x-[-50%] checked:before:text-[#181818]  checked:before:translate-y-[-50%] checked:before:content-['✔'] duration-300 appearance-none border-2 border-[#CED4DA] size-5"
          type="checkbox"
          checked={isAgreed2}
          onChange={(e) => setIsAgreed2(e.target.checked)}
        />
        <p className="font-normal ml-2 text-xl">
          {t("iAgreeWithProcess1")}
          <a
            href="https://spendsplif.com/bank-connection-process"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("iAgreeWithProcess")}
          </a>
          {t("iAgreeWithProcess2")}
        </p>
      </div>
      <div className="ml-auto">
        <button
          onClick={handleCancelClick}
          className="bg-[#191a1c] px-5 py-1 rounded-xl text-xl font-normal"
        >
          {t("banks.cancel")}
        </button>
        <button
          disabled={!selectedBank}
          className="hover:bg-[#e09300] disabled:cursor-not-allowed duration-300 bg-orange ml-3 font-normal text-xl text-black rounded-xl px-5  py-1"
          onClick={handleSubmit}
        >
          {t("banks.connect")}
        </button>
      </div>
    </main>
  );
};

export default Banks;
