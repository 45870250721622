import { memo } from "react";
import type { BalanceDisplayProps } from "./types.ts";

export const BalanceDisplay = memo(
  ({ balance, currencySymbol, name }: BalanceDisplayProps) => (
    <div className="transform transition-all duration-200 hover:translate-x-2">
      <h3 className="text-white font-extrabold text-2xl group">
        <span className="text-orange inline-block transition-all duration-200 group-hover:scale-110 group-hover:rotate-12">
          {currencySymbol}
        </span>
        <span className="ml-1 inline-block transition-all duration-200 group-hover:translate-x-1">
          {balance.toLocaleString() || "0"}
        </span>
      </h3>
      <div className="text-gray-500 text-base transition-all duration-200 hover:text-white">
        <p>{name}</p>
      </div>
    </div>
  ),
);
