import { useCallback } from "react";
import eventEmitter from "services/EventEmitter";

export const useTourTriggers = () => {
  const triggerArrowClick = useCallback((selector: string) => {
    const arrowElement = document.querySelector(selector) as HTMLElement;
    if (arrowElement) {
      arrowElement.click();
    }
  }, []);

  const triggerPlusAccountClick = useCallback(() => {
    const plusAccountElement = document.querySelector(
      "#plusAccount",
    ) as HTMLElement;
    const arrowElement = document.querySelector(
      "#arrowAccount2",
    ) as HTMLElement;

    if (plusAccountElement && arrowElement) {
      plusAccountElement.click();
      arrowElement.click();
      arrowElement.click();
    }
  }, []);

  const triggerEventEmitters = useCallback(
    (eventName: string, value?: boolean) => {
      const events: { [key: string]: () => void } = {
        setHoveredTrue: () => eventEmitter.emit("setHoveredTrue"),
        setHoveredFalse: () => eventEmitter.emit("setHoveredFalse"),
        setSpendTrue: () => eventEmitter.emit("setSpendTrue"),
        setSpendFalse: () => eventEmitter.emit("setSpendFalse"),
        setNothTrue: () => eventEmitter.emit("setNothTrue"),
        setNothFalse: () => eventEmitter.emit("setNothFalse"),
        setMenuTrue: () => eventEmitter.emit("setMenuTrue"),
        setMenuFalse: () => eventEmitter.emit("setMenuFalse"),
      };

      events[eventName]?.();
    },
    [],
  );

  return {
    triggerArrowClick,
    triggerPlusAccountClick,
    triggerEventEmitters,
  };
};
