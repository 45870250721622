import React, { useRef, type RefObject } from "react";
import TinkCardConnection from "./TinkCardConnection";
import { Header } from "./subcomponents/Header";
import { CardsList } from "./CardList";
import usePanelResizing from "utils/hooks/usePanelResizing";
import { useQuery } from "@tanstack/react-query";
import CardsAPI from "services/API/Cards";
import getSpaceId from "utils/constants/getSpaceId";
import LittleLoader from "utils/components/Loader/LittleLoader";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";

interface CardsProps {
  cardRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}

const CardsWindow: React.FC<CardsProps> = ({ cardRef, onBack }) => {
  usePanelResizing(cardRef);
  useSetCoolScroll(cardRef);

  const { data, isLoading } = useQuery({
    queryKey: ["get connected banks"],
    queryFn: () => CardsAPI.getConnectedBanks(getSpaceId()),
  });

  return (
    <div
      ref={cardRef}
      className="text-white overflow-hidden p-5 font-vela font-bold bg-[#101010] rounded-3xl text-[20px] relative top-[2.3%] left-[1.5%] shadow-md shadow-[#FFA80080] flex flex-col"
    >
      <Header onBack={onBack} />
      {isLoading ? (
        <LittleLoader />
      ) : (
        <div
          ref={cardRef}
          className="relative flex-grow overflow-y-auto"
        >
          {data && <CardsList cards={data.data} />}
          <TinkCardConnection />
        </div>
      )}
    </div>
  );
};

export default CardsWindow;
