import { useTranslation } from "react-i18next";
import { ChartOptions } from "./Charts";

export const generateChartOptions = (maxValue: number): ChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 15,
      right: 15,
      top: 0,
      bottom: 70,
    },
  },
  plugins: {
    legend: { display: false },
    tooltip: { enabled: true },
  },
  scales: {
    x: {
      grid: { display: false },
      ticks: { font: { size: 13 } },
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: maxValue,
      ticks: {
        stepSize: Math.ceil(maxValue / 4),
        font: { size: 13 },
      },
      grid: { color: "rgba(255, 255, 255, 0.1)" },
    },
  },
});

export const prepareChartData = (
  data: Record<string, string>,
  months: string[],
) => {
  const datasetData = months.map((month) =>
    data?.[month] ? parseFloat(data[month]) : 0,
  );
  const { t } = useTranslation();

  return {
    labels: months,
    datasets: [
      {
        type: "bar" as const,
        label: t("stats.month"),
        data: datasetData,
        backgroundColor: "#FFA800",
        borderRadius: 8,
        barPercentage: 0.4,
        categoryPercentage: 0.6,
      },
    ],
  };
};
