import { type FC, memo } from "react";

interface Props {
  icon: string;
  label: string;
  onClick: () => void;
  iconSize?: string;
  disabled?: boolean;
}

export const ActionButton: FC<Props> = memo(
  ({ icon, label, onClick, iconSize = "32px", disabled }) => (
    <div className="text-gray-500 text-base flex flex-col items-center group">
      <button
        disabled={disabled}
        onClick={onClick}
        className="h-[75px] w-[75px] m-1 bg-gradient-to-br from-[#FF7F00] to-[#FFA800] 
                text-white p-4 rounded-full flex justify-center items-center 
                transform transition-all duration-200 ease-in-out
                hover:scale-110 hover:shadow-lg hover:shadow-[#FFA80040]
                active:scale-95 
                disabled:from-[#858176] disabled:to-[#858176] disabled:shadow-none disabled:scale-100
                disabled:cursor-not-allowed group"
      >
        <img
          className={`h-[${iconSize}]  transform transition-transform duration-200 group-hover:rotate-12 
          group-disabled:animate-spin-reverse  group-disabled:transform-none group-disabled:transition-none`}
          src={icon}
          alt={label}
        />
      </button>
      <p className="mt-2 transform transition-all duration-200 group-hover:text-white group-hover:scale-105">
        {label}
      </p>
    </div>
  ),
);
