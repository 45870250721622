import Modal from "utils/components/Modal";
import cl from "./PrivacyMessage.module.scss";
import { type FC, type ChangeEventHandler, memo } from "react";
import type { TFunction } from "i18next";

interface Props {
  isAccepted: boolean;
  handleCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  t: TFunction;
  handleOKClick: () => void;
}

const PrivacyMessage: FC<Props> = memo(
  ({ isAccepted, handleCheckboxChange, t, handleOKClick }) => {
    return (
      <Modal setActive={() => {}}>
        <main className={cl.container}>
          <p>{t("shop.privacyMessage")}</p>
          <div>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>{t("shop.iAgreeWithThis")}</p>
            <button
              disabled={!isAccepted}
              title={isAccepted ? undefined : t("shop.youHaveAgree")}
              onClick={handleOKClick}
            >
              OK
            </button>
          </div>
        </main>
      </Modal>
    );
  }
);

export default PrivacyMessage;
