import { useQuery } from "@tanstack/react-query";
import { TicketsAPI } from "services/API/Tickets";
import { useMemo, useRef, useState } from "react";
import TicketChat from "../TicketChat";
import { queryClient } from "main";
import TicketInf from "../TicketInfType";
import Tickets from "../Tickets";

const EmployeeTickets = ({ onBack }: { onBack: () => void }) => {
  const { data: waitingTickets } = useQuery({
    queryFn: TicketsAPI.getWaitingTickets,
    queryKey: ["get waiting tickets"],
  });

  // tickets with a "in_process"/"closed" status
  const { data: notWaitingTickets } = useQuery({
    queryFn: TicketsAPI.getTickets,
    queryKey: ["get tickets"],
  });

  const takenTickets = useMemo(
    () =>
      notWaitingTickets?.data.filter(
        (ticket) => ticket.status === "in_process"
      ),
    [notWaitingTickets]
  );

  const data = useMemo(
    () =>
      waitingTickets && takenTickets
        ? [...takenTickets, ...waitingTickets.data]
        : [],
    [takenTickets, waitingTickets]
  );
  const [showChat, setShowChat] = useState(false);
  const chatInf = useRef<TicketInf | null>(null);

  const handleChatBack = () => {
    setShowChat(false);
    queryClient.invalidateQueries({ queryKey: ["get tickets"] });
    queryClient.invalidateQueries({ queryKey: ["get waiting tickets"] });
  };

  return showChat ? (
    <TicketChat
      title={chatInf.current?.title}
      id={chatInf.current?.id}
      onBack={handleChatBack}
      ePalLogo={(chatInf.current as TicketInf).employee[0]}
    />
  ) : (
    <>
      <section className="flex justify-between  text-2xl items-center">
        <button onClick={onBack}>←</button>
        <p>Ticket</p>
      </section>
      <Tickets
        data={data}
        handleOpenClick={(ticketInf: TicketInf) => {
          chatInf.current = ticketInf;
          setShowChat(true);
        }}
      />
    </>
  );
};

export default EmployeeTickets;
