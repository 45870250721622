import { createContext } from "react";

export interface CustomAlertOptions {
  itsLimitAlert?: boolean;
  onClose?: () => void;
}

export const CustomAlertContext = createContext(
  (text: string, options?: CustomAlertOptions) => {}
);

export default CustomAlertContext;
