import React, { useState } from "react";
import cl from "./ElementLicense.module.scss";
import Cercle from "/src/image/AdminPanel/Cercle.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import smalltalk from "utils/libraries/smalltalk";
import { ShopAPI } from "services/API/Shop";
import { useNavigate } from "react-router-dom"; // Добавлено для навигации

interface LicenseElementProps {
  text?: string;
  cost?: number;
}

const LicenseElement: React.FC<LicenseElementProps> = ({
  text,
  cost = 500,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate(); // Хук для навигации

  const handleClick = () => {
    if (text === t("plans.yours")) {
      smalltalk
        .confirm(t("cancel_sub.title"), t("cancel_sub.message"))
        // .then(() => ShopAPI.cancelSubscription());
    } else if (text === t("plans.upgrade")) {
      smalltalk.alert(t("cancel_sub.upgrade"), t("cancel_sub.message2")).then();
    }
  };

  const handleDetailsClick = () => {
    navigate("/shop"); // Переход на страницу shop
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <motion.div
      className={cl.businessLicenseCard}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.005,
        transition: { duration: 0.3 },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={cl.topContent}>
        <h2 className={cl.businessLicenseTitle}>
          Business {t("plans.license")}
        </h2>
        <p className={cl.businessLicensePrice}>
          €{cost} + {t("plans.service")}
        </p>
      </div>

      <motion.img
        className={cl.centeredIcon}
        src={Cercle}
        alt="is loading"
        style={{
          opacity: isHovered && text ? 0.25 : 1,
          transition: "opacity 0.5s ease",
        }}
      />

      <AnimatePresence>
        {isHovered && text && (
          <motion.div
            className={cl.planDescription}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 50 }}
            transition={{ duration: 0.3 }}
          >
            <p>{t("plans.description2")}</p>
            <button
              className={cl.blinkingText}
              onClick={handleDetailsClick} // Добавлен обработчик для перехода
            >
              {t("plans.details")}
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      {text !== t("plans.yours") && (
        <div className={cl.bottomSection}>
          <motion.button
            className={cl.actionButton}
            onClick={handleClick}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.3 },
              originX: -0.05,
              originY: 1,
            }}
          >
            <span className={cl.buttonText}>{text}</span>
          </motion.button>
        </div>
      )}
    </motion.div>
  );
};

export default LicenseElement;
