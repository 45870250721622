import cl from "./AdminPanelPage.module.scss";
import ServiceAndOverview from "./Service&Overview";
import SubscriptionAndCollabs from "./Subscription&Collabs";

const AdminPanelPage = () => {
  return (
    <div className={cl.container}>
      <ServiceAndOverview />
      <SubscriptionAndCollabs />
    </div>
  );
};

export default AdminPanelPage;
