import { memo } from "react";

export const LabeledValue = memo(
  ({
    label,
    value,
    prefix = "",
  }: {
    label: string;
    value: string | number;
    prefix?: string;
  }) => (
    <div className="flex justify-between mb-0 hover:bg-[#2a2a2a] p-2 rounded-lg transition-colors duration-200">
      <span className="text-white">{label}:</span>
      <span className="text-gray-500">
        {prefix}
        {value}
      </span>
    </div>
  ),
);