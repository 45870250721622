import note_logo from "image/Tickets/note.svg";
import arrow_down from "image/CreateCategory/down-arrow.svg";
import { useMemo, useRef, useState } from "react";
import CreatingTicket from "./CreatingTicket";
import SearchField from "utils/components/SearchField";
import { useQuery } from "@tanstack/react-query";
import { TicketsAPI } from "services/API/Tickets";
import Tickets from "../Tickets";
import type { Ticket } from "utils/types/response/TicketsRes";
import TicketChat from "../TicketChat";
import { queryClient } from "main";
import LocalLoader from "utils/components/LocalLoader";
import TicketInf from "../TicketInfType";
import { useTranslation } from "react-i18next";
import LittleLoader from "utils/components/Loader/LittleLoader";
import smalltalk from "utils/libraries/smalltalk";
import useStore from "store/store";

const filterTickets = (tickets: Ticket[], title: string) =>
  tickets.filter((ticket) => ticket.title.includes(title));

const UserTickets = ({ onBack }: { onBack: () => void }) => {
  const { t } = useTranslation();
  const [showCancelled, setShowCancelled] = useState(false);
  const [search, setSearch] = useState("");
  const { isFree } = useStore();
  const { data, isPending } = useQuery({
    queryFn: TicketsAPI.getTickets,
    queryKey: ["get tickets"],
  });

  const [closedTickets, notClosedTickets] = useMemo(() => {
    const closed: Ticket[] = [];
    const notClosed: Ticket[] = [];
    data?.data.forEach((ticket) =>
      (ticket.status === "closed" ? closed : notClosed).push(ticket),
    );

    return [filterTickets(closed, search), filterTickets(notClosed, search)];
  }, [data, search]);

  const [mode, setMode] = useState<
    "default" | "creating ticket" | "ticket chat"
  >("default");

  // used for the chat
  const chatInf = useRef<TicketInf | null>(null);

  // used for the ← button in the chat and creating ticket
  const handleBackClick = () => {
    setMode("default");
    queryClient.invalidateQueries({
      queryKey: ["get tickets"],
    });
  };

  const handleCreateTicketClick = () => {
    if (isFree) {
      smalltalk.alert(t("alert.title_tickets"), t("alert.desc_tickets")).then();
      return;
    }
    setMode("creating ticket");
  };

  if (isPending) {
    return (
      <section className="flex justify-between text-2xl items-center">
        <button onClick={onBack}>←</button>
        <p>{t("ticket")}</p>
        <button onClick={handleCreateTicketClick}>
          <img src={note_logo} />
        </button>
        <LittleLoader />
      </section>
    );
  }

  return mode === "default" ? (
    <>
      <section className="flex justify-between text-2xl items-center">
        <button onClick={onBack}>←</button>
        <p>{t("ticket")}</p>
        <button onClick={handleCreateTicketClick}>
          <img src={note_logo} />
        </button>
      </section>
      <SearchField
        handleClear={() => setSearch("")}
        handleChange={(e) => setSearch(e.target.value)}
        placeholder={t("search_by_tickets")}
        value={search}
      />
      {
        <Tickets
          handleOpenClick={(ticketInf: TicketInf) => {
            setMode("ticket chat");
            chatInf.current = ticketInf;
          }}
          data={notClosedTickets}
        />
      }
      <p className="text-[#808080] font-semibold ml-1 flex justify-between items-center">
        {t("closed_tickets")}
        <button
          onClick={() => setShowCancelled((prev) => !prev)}
          className={`text-2xl size-5 duration-300 ${
            showCancelled ? "" : "-rotate-90"
          } flex items-center`}
        >
          <img src={arrow_down} />
        </button>
      </p>
      {showCancelled && <Tickets data={closedTickets} />}
    </>
  ) : mode === "creating ticket" ? (
    <CreatingTicket handleBackClick={handleBackClick} />
  ) : (
    <TicketChat
      ePalLogo={(chatInf.current as TicketInf).employee[0]}
      title={(chatInf.current as TicketInf).title}
      id={(chatInf.current as TicketInf).id}
      onBack={handleBackClick}
    />
  );
};

export default UserTickets;
