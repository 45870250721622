import type { FC, Dispatch, SetStateAction } from "react";
import CookieMessage from "./CookieMessage";
import { useTranslation } from "react-i18next";

interface Props {
  setShowCookieMessage: Dispatch<SetStateAction<boolean>>;
}

const CookieMessageContainer: FC<Props> = ({ setShowCookieMessage }) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    localStorage.setItem("disableCookieMessage", "true");
    setShowCookieMessage(false);
  };

  return <CookieMessage handleButtonClick={handleButtonClick} t={t} />;
};

export default CookieMessageContainer;
