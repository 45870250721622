import AutoComplete from "components/LoginPage/ChooseCurrency/AutoComplete";
import cl from "../../Circles/AccountCircle/CreateAccount/Form.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { IFormInputs } from "./ConverterFormContainer";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import reverseArrow from "image/Converter/ReverseArrow.svg";

const CurrencyField = ({
  setValue,
  register,
  fieldName,
}: {
  setValue: UseFormSetValue<IFormInputs>;
  register: UseFormRegister<IFormInputs>;
  fieldName: keyof IFormInputs;
}) => {
  const { t } = useTranslation();
  return (
    <AutoComplete
      placeholder={t("converter.currencyPlaceholder")}
      inputClass={`bg-[#1b1b1b] ${cl.field} w-full max-w-[470px] h-[46px] !rounded-lg bg-cash_logo duration-300`}
      ulClass="!left-0 !w-auto !w-[80vw] !max-w-[377px]"
      register={register}
      fieldName={fieldName}
      onClickElement={(e) =>
        setValue(fieldName, e.target.innerText, {
          shouldValidate: true,
        })
      }
    />
  );
};

interface Props {
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  register: UseFormRegister<IFormInputs>;
  setValue: UseFormSetValue<IFormInputs>;
  onSubmit: SubmitHandler<IFormInputs>;
  result?: string;
  reversed: boolean;
  handleReverseClick: () => void;
}

const ConverterForm: FC<Props> = ({
  handleSubmit,
  register,
  setValue,
  onSubmit,
  result,
  reversed,
  handleReverseClick,
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-[#141414] h-auto flex gap-y-5 flex-col p-5 rounded-xl"
    >
      <div className="flex items-center gap-x-3">
        <div className="flex flex-col items-center justify-evenly h-full">
          <span
            className={`size-5 rounded-full border-2 border-orange ${
              reversed ? "bg-orange" : ""
            } duration-300`}
          />
          <button onClick={handleReverseClick} type="button">
            <img className="size-8" src={reverseArrow} />
          </button>
          <span
            className={`size-5 rounded-full border-2 border-orange ${
              !reversed ? "bg-orange" : ""
            } duration-300`}
          />
        </div>
        <div className="flex flex-col gap-y-5">
          <CurrencyField
            setValue={setValue}
            register={register}
            fieldName={!reversed ? "from_currency" : "to_currency"}
          />
          <CurrencyField
            setValue={setValue}
            register={register}
            fieldName={reversed ? "from_currency" : "to_currency"}
          />
        </div>
      </div>
      <input
        type="number"
        step="0.01"
        required
        placeholder={t("converter.amountPlaceholder")}
        className={`${cl.field} w-full max-w-[470px] h-[46px] self-start !rounded-lg bg-balance_logo bg-[#1b1b1b]`}
        {...register("amount")}
      />
      <button
        className="bg-[#FFA800] self-start w-full max-w-[470px] text-black h-[48px] rounded-xl"
        type="submit"
      >
        {t("converter.convertButton")}
      </button>
      <p
        className={`bg-[#1b1b1b] py-2.5 rounded-lg pl-3 text-[#6E6E6E] align-middle w-full max-w-[470px] ${
          result ? "!text-white" : ""
        }`}
      >
        {result ? result : t("converter.resultPlaceholder")}
      </p>
    </form>
  );
};

export default withErrorShow(ConverterForm);
