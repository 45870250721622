import type { UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import cl from "./Form.module.scss";
import clip from "image/Tickets/clip.svg";
import type { Inputs } from "./FormContainer";
import type { RefObject } from "react";
import { useTranslation } from "react-i18next";

const Form = ({
  onSubmit,
  register,
  textAreaRef,
  onTextAreaChange,
}: {
  register: UseFormRegister<Inputs>;
  onSubmit: ReturnType<UseFormHandleSubmit<Inputs>>;
  textAreaRef: RefObject<HTMLElement | null>;
  onTextAreaChange: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full text-ellipsis flex-col gap-y-3"
    >
      <section>
        <p className="text-[#727272] flex pb-1 justify-between border-b border-[#808080]">
          {t("help_with_project")}
          <input
            type="checkbox"
            className={cl.toggle}
            id="neededHelp"
            {...register("neededHelp")}
          />
          <label htmlFor="neededHelp" />
        </p>
        <input
          type="text"
          className="placeholder:text-[#727272] text-ellipsis pb-1 border-b border-[#808080] w-full"
          placeholder={t("title")}
          {...register("title")}
          maxLength={40}
        />
        <textarea
          placeholder={t("subject")}
          maxLength={800}
          className="resize-none h-[28px] pb-1 overflow-y-hidden border-b text-ellipsis border-[#808080] placeholder:text-[#727272] block w-full  outline-none"
          {...register("message", { onChange: onTextAreaChange })}
          ref={(el) => {
            register("message").ref(el);
            textAreaRef.current = el;
          }}
        />
      </section>
      <section className="mt-auto w-full flex justify-between">
        <button className="bg-orange text-[#181818] w-[90%] text-start pl-4 py-2 rounded-md">
          {t("send")}
        </button>
        <button className="ml-auto cursor-not-allowed" disabled>
          <img src={clip} className="h-full" />
        </button>
      </section>
    </form>
  );
};

export default Form;
