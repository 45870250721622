import type { Dispatch, SetStateAction } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { valSchema } from "utils/validators/Account/CreateAccount";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import type { ICreateAccountReq } from "utils/types/request/AccountRequest";
import { currencies } from "utils/constants/currencies";
import { queryClient } from "main";
import type { IFormInputs } from "../CreateAccount/CreateAccountContainer";
import EditAccount from "./EditAccount";
import type { OneAccount } from "utils/types/response/AccountRes";
import showErrorMessage from "utils/constants/showErrorMessage";
import useStore from "store";
import smalltalk from "utils/libraries/smalltalk";
import { useTranslation } from "react-i18next";

const EditAccountContainer: React.FC<{
  setModal: Dispatch<SetStateAction<boolean>>;
  Account: OneAccount;
}> = ({ setModal, Account }) => {
  const { t } = useTranslation();
  const { accounts } = useStore();
  const DeleteAccountReq = useMutation({
    mutationKey: ["delete account"],
    mutationFn: () => AccountAPI.DeleteAccount(Account.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["get accounts"] });
      setModal(false);
    },
    onError: showErrorMessage,
  });
  const handleDeleteAccount = () => {
    if (accounts?.length > 1) {
      return DeleteAccountReq.mutate();
    }
    smalltalk.alert(
      t("handleDeleteAccount.errorTitle"),
      t("handleDeleteAccount.errorMessage"),
    );
  };
  // All this is used for editing account
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      includeTotalBal: true,
      title: Account.title,
      balance: Number.parseFloat(Account.balance).toString(),
      currency: Object.keys(currencies).find(
        (key) => currencies[key] === Account.currency,
      ),
    },
    resolver: zodResolver(valSchema),
  });
  // It's request editing account
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["create account"],
    mutationFn: (data: ICreateAccountReq) =>
      AccountAPI.EditAccount(Account.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["get accounts"] });
      setModal(false);
    },
    onError: showErrorMessage,
  });
  const onSubmit: SubmitHandler<IFormInputs> = ({
    title,
    balance,
    currency,
    includeTotalBal,
  }) => {
    mutateAsync({
      title,
      balance: balance === "" ? 0 : Number.parseFloat(balance),
      currency: currencies[currency],
      include_in_total_balance: includeTotalBal,
    });
  };
  return (
    <EditAccount
      onSubmit={onSubmit}
      setValue={setValue}
      errors={errors}
      handleSubmit={handleSubmit}
      setModal={setModal}
      accountId={Account.id}
      register={register}
      handleDeleteAccount={handleDeleteAccount}
      isLoading={isPending || DeleteAccountReq.isPending}
    />
  );
};

export default EditAccountContainer;
