import type { SyntheticEvent, FC } from "react";
import cl from "./ChooseCurrency.module.scss";
import currency_logo from "image/login_p/choose_currency/currency_logo.svg";
import CurrencyMenu from "./CurrencyMenu";
import AutoComplete from "./AutoComplete";
import type {
  UseFormRegister,
  UseFormHandleSubmit,
  SubmitHandler,
} from "react-hook-form";
import withLoader from "utils/hoc/withLoader";
import withErrorShow from "utils/hoc/withErrorShow";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // добавляем useTranslation для перевода

interface Input {
  currency: string;
}

interface Props {
  handleSubmit: UseFormHandleSubmit<Input>;
  onSubmit: SubmitHandler<Input>;
  currenciesNames: string[];
  register: UseFormRegister<Input>;
  onMenuClick: (e: SyntheticEvent<HTMLLIElement>) => void;
  throughtGoogle?: true;
}

const ChooseCurrency: FC<Props> = ({
  handleSubmit,
  onSubmit,
  currenciesNames,
  register,
  onMenuClick,
  throughtGoogle,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // добавляем хук перевода

  return (
    <div className={cl.container}>
      <div>
        <div className={cl.title}>
          <img src={currency_logo} alt="Currency logo" />
          <div className="w-[60vw] text-[20px] sm:text-[32px] max-w-[15rem]">
            <p className="mb-[14px]">
              {t("chooseCurrency.chooseFavoriteCurrency")}
            </p>
            <p className="text-[12px] sm:text-[14px] text-[#6E6E6E]">
              {t("chooseCurrency.currencyDescription")}
            </p>
          </div>
        </div>
        <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
          <AutoComplete
            onClickElement={onMenuClick}
            values={currenciesNames}
            register={register}
            placeholder={t("chooseCurrency.currency")}
            inputClass="w-[80dvw] max-w-[24.5rem]"
          />
          <div className={cl.buttons}>
            {!throughtGoogle && (
              <button
                className="rounded-l-[12px] bg-[#181818]"
                type="button"
                onClick={() => navigate("/login/confirm-email")}
              >
                {t("chooseCurrency.back")}
              </button>
            )}
            <button
              className={`${
                throughtGoogle
                  ? "!w-[80vw] !sm:text-[22px] !rounded-[12px] !max-w-[392px]"
                  : ""
              }
              rounded-r-[12px] bg-[#FFA800] font-bold text-[black]`}
              type="submit"
            >
              {t("chooseCurrency.done")}
            </button>
          </div>
        </form>
      </div>
      <CurrencyMenu
        style={{ height: throughtGoogle ? "30.8rem" : "26.875rem" }}
        onClick={onMenuClick}
      />
    </div>
  );
};

export default withErrorShow(withLoader(ChooseCurrency));
