import { useQuery } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import { useEffect } from "react";
import useStore from "store";
import getSpaceId from "utils/constants/getSpaceId";

const useSetAccounts = () => {
  const { setAccounts, setTotalBalance, setCards, loggedIn } = useStore();

  const { data, isLoading } = useQuery({
    queryKey: ["get accounts"],
    queryFn: () => AccountAPI.ViewAccounts(getSpaceId()),
    enabled: loggedIn && !!getSpaceId(),
  });

  useEffect(() => {
    if (data) {
      setAccounts(data.data.Account);
      setTotalBalance(data.data.TotalBalance);
    }
  }, [data, setAccounts]);

  return isLoading;
};
export default useSetAccounts;
