import { z } from "zod";
import { useTranslation } from "react-i18next";
import { currencyObj } from "../defObjects";

export const valSchema = () => {
  const { t } = useTranslation();

  return z.object({
    "E-mail": z
      .string()
      .email({ message: t("validation.email.invalid") })
      .max(50, { message: t("validation.email.tooLong") })
      .min(5, { message: t("validation.email.tooShort") })
      .refine((value) => !value.includes("<") && !value.includes(">"), {
        message: t("validation.email.prohibitedChars"),
      })
      .optional(),
    Password: z
      .string()
      // Small step towards XSS protection
      .refine((value) => !value.includes("<") && !value.includes(">"), {
        message: t("validation.password.prohibitedChars"),
      })
      .refine(
        (value) => {
          if (value === "") {
            return true;
          }

          if (value.length < 8 || value.length > 24) {
            return false;
          }

          if (/^\d+$/.test(value)) {
            return false;
          }

          const letters = value.match(/[a-zA-Z]/g) || [];
          const numbers = value.match(/\d/g) || [];
          return letters.length >= 4 && numbers.length >= 1;
        },
        {
          message: t("validation.password.requirements"),
        },
      )
      .optional(),
    username: z
      .string()
      .max(30, { message: t("validation.username.tooLong") })
      .min(2, { message: t("validation.username.tooShort") })
      .refine((value) => !value.includes("<") && !value.includes(">"), {
        message: t("validation.username.prohibitedChars"),
      }),

    currency: currencyObj,
  });
};
