import type { ChangeEventHandler } from "react";

const SearchField = ({
  handleChange,
  handleClear,
  value,
  placeholder = "Search",
  className = "",
}: {
  handleClear: () => void;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  placeholder?: string;
  className?: string;
}) => {
  return (
    <div className={`relative ${className}`}>
      <input
        onChange={handleChange}
        placeholder={placeholder}
        className="bg-[#191a1c] bg-search bg-[0.94rem] bg-no-repeat bg-[length:1.5rem] pl-12 w-full py-2 px-1.5 rounded-md placeholder:text-[#727272] font-semibold"
        value={value}
        maxLength={40}
      />
      <span
        onClick={handleClear}
        className="absolute top-[50%] select-none cursor-pointer -translate-y-[50%] right-3 font-bold text-[#8E8E93]"
      >
        ✕
      </span>
    </div>
  );
};

export default SearchField;
