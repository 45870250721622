import { useState, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

interface LanguageSelectorProps {
  currentLanguage?: string;
  onLanguageChange?: (lang: string) => void;
}

const LanguageSelector = ({
  currentLanguage,
  onLanguageChange,
}: LanguageSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const languages = [
    { code: "en", label: "English" },
    { code: "cs", label: "Čeština" },
  ];

  const getCurrentLanguageLabel = () => {
    return (
      languages.find((lang) => lang.code === currentLanguage)?.label ||
      "English"
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-24 text-left text-[#636363] text-[16px] hover:text-[#FF9F1C] transition-colors"
      >
        {getCurrentLanguageLabel()}
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-1 bg-[#2D2D2D] rounded-md shadow-lg py-1 z-10">
          {languages.map((lang) => (
            <button
              key={lang.code}
              type="button"
              onClick={() => {
                onLanguageChange?.(lang.code);
                setIsOpen(false);
              }}
              className={`block w-full px-4 py-2 text-left ${
                currentLanguage === lang.code
                  ? "text-[#FF9F1C]"
                  : "text-[#636363]"
              } hover:text-[#FF9F1C] transition-colors`}
            >
              {lang.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
