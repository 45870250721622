import type { AxiosResponse } from "axios";
import axios from "axios";
import type {
  RegisterResponse,
  LoginResponse,
} from "utils/types/response/AuthResponse";
import type {
  RegisterRequest,
  LoginRequest,
} from "utils/types/request/AuthRequest";
import type { CategoryInputs } from "components/HomePage/Overlay/CreateCategory/PC/CreateCategory.tsx";
import type { OneCategory } from "utils/types/response/CategoriesRes";
import { instance } from "./API/instances.ts";
import getSpaceId from "utils/constants/getSpaceId.ts";

const serverURL = "http://localhost:8000/api/v1";

//we need the authInstance because interceptors on the instance interfere correct work login
const authInstance = axios.create({
  baseURL: serverURL,
  withCredentials: true,
});

export const AuthAPI = {
  async register(
    data: RegisterRequest,
  ): Promise<AxiosResponse<RegisterResponse>> {
    return await authInstance.post<RegisterResponse>("/register/", {
      ...data,
    });
  },

  async login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return await authInstance.post<LoginResponse>("/token/", { ...data });
  },

  async verifyEmail(code: string, currency: string): Promise<AxiosResponse> {
    return await authInstance.post("/verify_email/", {
      verify_code: code,
      currency: currency,
    });
  },
  //Ето нужно будет типизировать когда refresh токен начнется прикреплятса как кука
  async checkAuth() {
    return await authInstance.get("/token/refresh/");
  },
};

export const SpaceAPI = {
  async getMySpaces() {
    try {
      const response = await instance.get("/my_spaces/");
      return response.data;
    } catch (error) {
      console.error("Error fetching spaces:", error);
      throw error;
    }
  },

  async getActiveSpace() {
    try {
      const response = await instance.get("/my_active_space/");
      return response.data;
    } catch (error) {
      console.error("Error fetching spaces:", error);
      throw error;
    }
  },

  async spaceStatus(spaceId: number) {
    try {
      const response = await instance.get(`/my_spaces/${spaceId}/status/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching spaces:", error);
      throw error;
    }
  },

  async isBankActionRequired(spaceId: number) {
    try {
      const response = await instance.get(
        `/my_spaces/${spaceId}/my_connections/action/`,
      );
      return response;
    } catch (error) {
      console.error("Error fetching:", error);
      throw error;
    }
  },

  async createSpace(data: { title: string; currency: string }) {
    try {
      const response = await instance.post("/create_space/", data);
      return response.data;
    } catch (error) {
      console.error("Error creating space:", error);
      throw error;
    }
  },

  async updateConnection(
    spaceId: number,
    data: { bank_connection_id: number },
  ) {
    try {
      const response = await instance.post(
        `/my_spaces/${spaceId}/my_connections/update/`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error updating:", error);
      throw error;
    }
  },

  async getSpace(spaceId: number) {
    try {
      const response = await instance.get(`/my_spaces/${spaceId}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching space:", error);
      throw error;
    }
  },

  async updateSpace(
    spaceId: number,
    data: { title: string; currency: string },
  ) {
    try {
      const response = await instance.put(`/my_spaces/${spaceId}/`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating space:", error);
      throw error;
    }
  },

  async getSpaceMembers(spaceId: number) {
    try {
      const response = await instance.get(`/my_spaces/${spaceId}/members/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching space members:", error);
      throw error;
    }
  },

  async addMemberToSpace(spaceId: number, userEmail: string) {
    try {
      const response = await instance.put(`/my_spaces/${spaceId}/add_member/`, {
        user_email: userEmail,
      });
      return response.data;
    } catch (error) {
      console.error("Error adding member:", error);
      throw error;
    }
  },

  async getMemberPermissions(spaceId: number, memberId: number) {
    try {
      const response = await instance.get(
        `/my_spaces/${spaceId}/edit_member/${memberId}/`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching member permissions:", error);
      throw error;
    }
  },

  async updateMemberPermissions(
    spaceId: number,
    memberId: number,
    permissions: any,
  ) {
    try {
      const response = await instance.put(
        `/my_spaces/${spaceId}/edit_member/${memberId}/`,
        permissions,
      );
      return response.data;
    } catch (error) {
      console.error("Error updating member permissions:", error);
      throw error;
    }
  },

  async deleteSpace(spaceId: number) {
    try {
      const response = await instance.delete(`/delete_space/${spaceId}/`);
      return response.data;
    } catch (error) {
      console.error("Error deleting space:", error);
      throw error;
    }
  },

  async removeMember(spaceId: number, email: string) {
    try {
      const response = await instance.put(
        `/my_spaces/${spaceId}/remove_member/`,
        {
          user_email: email,
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting space:", error);
      throw error;
    }
  },

  leaveFromSpace(spaceId: number) {
    return instance.post(`/my_spaces/${spaceId}/leave/`);
  },
};

export const CategoryAPI = {
  async create(data: CategoryInputs, spaceId: number) {
    return await instance.post(`/my_spaces/${spaceId}/create_category/`, {
      ...data,
    });
  },
  async getCategories(spaceId?: number): Promise<AxiosResponse<OneCategory[]>> {
    try {
      return instance.get(
        `/my_spaces/${spaceId || getSpaceId()}/my_categories/`,
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  },
  async getOne(spaceId: number, categoryId: number) {
    try {
      const response = await instance.get(
        `/my_spaces/${spaceId}/my_categories/${categoryId}/`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error;
    }
  },
  async update(spaceId: number, categoryId: number, data: CategoryInputs) {
    try {
      const response = await instance.put(
        `/my_spaces/${spaceId}/my_categories/${categoryId}/`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error updating category:", error);
      throw error;
    }
  },
  async delete(spaceId: number, categoryId: number) {
    try {
      const response = await instance.delete(
        `/my_spaces/${spaceId}/delete_category/${categoryId}/`,
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  },
};

export const SpendAPI = {
  spendAccount: (
    spaceId: number,
    data: {
      account_pk: number;
      category_pk?: number;
      amount: number;
      comment?: string;
    },
  ) =>
    instance.put(`/my_spaces/${spaceId}/spend/`, {
      ...data,
    }),
};
