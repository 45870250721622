import { useState, useCallback } from "react";
import { SpaceAPI } from "services/API";
import { validateEmail } from "utils/validators/Admin/validateEmail";
import getSpaceId from "utils/constants/getSpaceId";
import smalltalk from "utils/libraries/smalltalk";
import { useTranslation } from "react-i18next";
import useStore from "store/store";

interface User {
  email: string;
  username: string;
  tag: string;
}

export const useCollaborators = (
  users: User[],
  myEmail: string,
  refetchUsers: () => Promise<void>,
  slots: number,
) => {
  const { t } = useTranslation();
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [removingUsers, setRemovingUsers] = useState<string[]>([]);
  const { subscription } = useStore();

  const handleAddUser = useCallback(() => {
    if (users.length < slots) {
      setIsAddingUser(true);
    } else {
      let alertMessage: string;

      if (subscription === "free") {
        alertMessage = t("collaborators.buyPlan");
      } else if (
        subscription === "business_lic" ||
        subscription === "business_member_lic"
      ) {
        alertMessage = t("collaborators.license");
      } else {
        alertMessage = t("collaborators.contactForExtension");
      }

      smalltalk.alert(t("collaborators.limitReached"), alertMessage);
    }
  }, [users.length, subscription, slots, t]);

  const handleSaveUser = useCallback(async () => {
    if (!validateEmail(newMemberEmail)) {
      setEmailError(t("collaborators.invalidEmail"));
      return;
    }

    try {
      const spaceId = getSpaceId();
      await SpaceAPI.addMemberToSpace(spaceId, newMemberEmail);
      await refetchUsers();

      setIsAddingUser(false);
      setNewMemberEmail("");
      setEmailError("");
    } catch (err) {
      setEmailError(t("collaborators.addUserFailed"));
    }
  }, [newMemberEmail, refetchUsers, t]);

  const handleRemoveUser = useCallback((email: string) => {
    setRemovingUsers((prev) => [...prev, email]);
  }, []);

  const enhancedUsers = users
    .filter((user) => !removingUsers.includes(user.email))
    .map((user) => ({
      ...user,
      me: user.email === myEmail,
    }));

  return {
    isAddingUser,
    setIsAddingUser,
    newMemberEmail,
    setNewMemberEmail,
    emailError,
    setEmailError,
    handleAddUser,
    handleSaveUser,
    handleRemoveUser,
    enhancedUsers,
  };
};
