import React, { useState, useCallback, useRef } from "react";
import { HistoryAPI } from "services/API/History";
import type { HistoryOne } from "utils/types/response/HistoryRes";
import HistoryEditWindow from "./EditHistory/HistoryEditWindow.tsx";
import styles from "./HistoryWindow.module.scss";
import LossHeaderIcon from "image/History/Loss.svg";
import IncomeHeaderIcon from "image/History/Income.svg";
import useStore from "../../../../store";
import type { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "main.tsx";
import { useTranslation } from "react-i18next";
import i18n from "i18n/i18n.ts";
import usePanelResizing from "utils/hooks/usePanelResizing.ts";

interface HistoryWindowProps {
  historyRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const HistoryWindow: React.FC<HistoryWindowProps> = ({
  historyRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const historyDataLength = useRef(20);
  const [expandedComments, setExpandedComments] = useState<Set<string>>(
    new Set()
  );
  const [editingEntry, setEditingEntry] = useState<HistoryOne | null>(null);
  const { setCategoryChanged } = useStore((state) => state);
  const { data, isPending } = useQuery({
    queryFn: () => HistoryAPI.getNotifications(historyDataLength.current),
    queryKey: ["get history"],
  });

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1;

      // Automatically load more data if the user has scrolled to the bottom
      if (isAtBottom && data?.data.length >= historyDataLength.current) {
        handleLoadMore();
      }
    },
    [data?.data.length],
  );

  const formatEntryId = (type: string, id: number) => {
    return `${type}-${id}`;
  };

  const handleEdit = (entry: HistoryOne) => {
    setEditingEntry(entry);
  };

  const handleDelete = async (formattedId: string, isCard: boolean) => {
    if (isCard) {
      alert("Записи с is_card равным true нельзя удалить.");
      return;
    }

    const [type, idString] = formattedId.split("-");
    const id = Number.parseInt(idString);
    try {
      let deleteFunction: (id: number) => Promise<AxiosResponse<void>>;
      switch (type) {
        case "income":
          deleteFunction = HistoryAPI.deleteIncome;
          break;
        case "expense":
          deleteFunction = HistoryAPI.deleteExpense;
          break;
        case "loss":
          deleteFunction = HistoryAPI.deleteExpense;
          break;
        default:
          throw new Error("Invalid type for deleting entry");
      }

      await deleteFunction(id);
      setCategoryChanged(true);
      queryClient.invalidateQueries({ queryKey: ["get history"] });
    } catch (error) {
      console.error(`Failed to delete entry with id: ${formattedId}`, error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString(
      i18n.language === "cs" ? "cs-CZ" : "en-US",
      { month: "long" }
    );

    if (i18n.language === "cs") {
      return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
    }

    return `${month} ${day}`;
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  };

  const getStyleHeader = (type: string) => {
    switch (type) {
      case "income":
        return styles.incomeHeader;
      case "loss":
        return styles.lossHeader;
      case "expense":
        return styles.expenseHeader;
    }
  };

  const renderIcon = (type: string, categoryTitle: string | undefined) => {
    if (type === "income") {
      return (
        <img
          className={`${styles.icon} ${styles.incomeIcon}`}
          src={IncomeHeaderIcon}
          alt={type}
        />
      );
    }
    if (type === "loss") {
      return (
        <img
          className={`${styles.icon} ${styles.lossIcon}`}
          src={LossHeaderIcon}
          alt={type}
        />
      );
    }
    if (type === "expense") {
      return (
        <img
          className={`${styles.icon} ${styles.expenseIcon}`}
          src={`https://spendsplif.com/icons/history/${categoryTitle}.svg`}
          alt={categoryTitle}
        />
      );
    }
  };

  const renderHeader = (
    type: string,
    categoryTitle: string | undefined,
    categoryIcon: string | undefined
  ) => {
    const headerClass = getStyleHeader(type);
    return (
      <div className={headerClass}>
        <div className={styles.headerText}>
          {type === "expense" ? categoryTitle : t(type)}
        </div>
        <div className={styles.headerIcon}>
          {renderIcon(type, categoryIcon)}
        </div>
      </div>
    );
  };

  const toggleExpand = (formattedId: string) => {
    setExpandedComments((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(formattedId)) {
        newState.delete(formattedId);
      } else {
        newState.add(formattedId);
      }
      return newState;
    });
  };

  const handleSave = () => {
    queryClient.invalidateQueries({ queryKey: ["get history"] });
    setEditingEntry(null);
  };

  const handleLoadMore = () => {
    historyDataLength.current = historyDataLength.current + 20;
    queryClient.invalidateQueries({ queryKey: ["get history"] });
  };

  usePanelResizing(historyRef);

  return (
    <div ref={historyRef} className={styles.container}>
      <div className={styles.titleAndBack}>
        <button type="button" onClick={onClose} className={styles.backButton}>
          ←
        </button>
        <h2 className={styles.title}>{t("history.title")}</h2>
      </div>
      <div className={styles.list} onScroll={handleScroll}>
        {data?.data.map((entry) => {
          const formattedDate = formatDate(entry.created_date);
          const formattedId = formatEntryId(entry.type, entry.id);
          return (
            <React.Fragment key={formattedId}>
              <div className={styles.dateHeader}>
                <span className={styles.date}>{formattedDate}</span>
                {!entry.is_card && (
                  <span className={styles.time}>
                    {formatTime(entry.created_time)}
                  </span>
                )}
              </div>
              <div className={styles.listItem}>
                {renderHeader(
                  entry.type,
                  entry.category_title,
                  entry.category_icon
                )}
                <div className={styles.detailsContainer}>
                  <div className={styles.detailItem}>
                    <div className={styles.amount}>
                      {entry.amount} {entry.currency}
                    </div>
                    <div className={styles.commentConverter}>
                      <div
                        className={styles.commentView}
                        onClick={() => toggleExpand(formattedId)}
                      >
                        <p
                          className={`${styles.commentText} ${
                            expandedComments.has(formattedId)
                              ? styles.expandedComment
                              : ""
                          }`}
                        >
                          {entry.comment || t("history.noComment")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailItem}>
                    <div className={styles.accountInfo}>
                      {entry.account} - {entry.account_balance} {entry.currency}
                    </div>
                  </div>
                </div>
                <div className={styles.actions}>
                  {entry.is_card ? (
                    <button
                      type="button"
                      onClick={() => handleEdit(entry)}
                      className={`${styles.editButton} ${styles.fullWidthButton}`}
                    >
                      <span className={styles.buttonText}>
                        {t("history.edit")}
                      </span>
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => handleEdit(entry)}
                        className={styles.editButton}
                      >
                        <span className={styles.buttonText}>
                          {t("history.edit")}
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDelete(formattedId, entry.is_card)}
                        className={styles.deleteButton}
                      >
                        <span className={styles.buttonText}>
                          {t("history.delete")}
                        </span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {isPending && (
        <div className={styles.loadingContainer}>
          <span>{t("history.loading")}</span>
        </div>
      )}
      {editingEntry && (
        <HistoryEditWindow
          entry={editingEntry}
          setActive={setEditingEntry}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default HistoryWindow;
