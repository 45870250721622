import DOMPurify from "dompurify";
import type { FC } from "react";
import type { INotification } from "utils/types/response/NotificationsRes";

interface Props extends INotification {
  refForLastNotification: any;
}

const formatText = (text: string) => {
  const regex = /~(.*?)~/g;
  return DOMPurify.sanitize(text)
    .split(regex)
    .map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span key={index} className="text-[#af2ba0] font-bold">
            @{part}
          </span>
        );
      }
      return part;
    });
};

const Notification: FC<Props> = ({
  message,
  importance,
  date,
  type,
  time,
  refForLastNotification,
}) => (
  <div style={{ direction: "ltr" }} ref={refForLastNotification}>
    <p className="text-[25px]">{date}</p>
    <section className="grid py-2 px-6 rounded-xl grid-cols-2 items-center grid-rows-[auto_auto_auto] bg-[#191919]">
      <p>{DOMPurify.sanitize(time)}</p>
      <p
        className={`text-[22px] rounded-3xl justify-self-end px-4 font-bold ${
          importance === "IMPORTANT"
            ? "bg-[#5f3437] text-[#c9787b]"
            : importance === "STANDARD"
              ? "bg-[#4a6e3d] text-[#8abf5d]"
              : "bg-[#5e5334] text-[#c9b178]"
        }`}
      >
        {DOMPurify.sanitize(importance)}
      </p>
      <article className="col-[1/3] text-[20px] bg-[#222222] rounded-t-xl rounded-bl-xl p-3 text-[#797979]">
        {formatText(message)}
      </article>
      <p className="bg-orange col-[2/3] px-4 text-black rounded-b-xl font-bold text-[24px]">
        {type === "notification" ? "personal" : "public"}
      </p>
    </section>
  </div>
);

export default Notification;
