import axios from "axios";
import { AuthAPI } from "./Auth";
import smalltalk from "utils/libraries/smalltalk";

const serverURL = "https://api.spendsplif.com/api/v1";

const createInstance = () =>
  axios.create({
    baseURL: serverURL,
    withCredentials: true,
  });

//we need the authInstance because interceptors on the instance interfer correct work loginization
export const authInstance = createInstance();

export const instance = createInstance();

// Automatically attaches an access token to each request
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

//Checks each response. If the error is 401, the access token has probably expired.
//A token refresh request occurs and repeats the previous request
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalReqest = error.config;
    const statusCode = error.response.status;

    if (statusCode === 401) {
      try {
        const response = await AuthAPI.checkAuth();
        localStorage.setItem("token", response.data.access);
        return instance.request(originalReqest);
      } catch {
        if (localStorage.getItem("token")) {
          smalltalk.alert("Error", "You`re not autorized");
        }
        window.location.href = "/welcome/";
      }
    } else if (statusCode === 500) {
      window.location.href = "/error";
    }
    throw error;
  },
);
