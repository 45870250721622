import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API";
import { sanitizeObject } from "utils/functions/sanitizeObject";

const ShopSuccessHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        SpaceAPI.getActiveSpace()
          .then((space) => {
            if (space) {
              localStorage.setItem(
                "space",
                JSON.stringify(sanitizeObject(space)),
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching spaces:", error);
          });
      } catch (error) {
        console.error("Error fetching spaces:", error);
      } finally {
        navigate("/admin-panel"); 
      }
    };

    fetchSpace();
  }, [navigate]);

  return null; // Компонент ничего не отображает
};

export default ShopSuccessHandler;
