import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import type { IConfirmNewEmail } from "utils/types/request/ProfileReq";
import cl from "./ConfirmEmail.module.scss";
import withLoader from "utils/hoc/withLoader";
import withErrorShow from "utils/hoc/withErrorShow";
import { useTranslation } from "react-i18next";

const ConfirmEmail = ({
  oldEmail,
  newEmail,
  register,
  handleSubmit,
  onSubmit,
}: {
  oldEmail: string;
  newEmail: string;
  handleSubmit: UseFormHandleSubmit<IConfirmNewEmail>;
  register: UseFormRegister<IConfirmNewEmail>;
  onSubmit: SubmitHandler<IConfirmNewEmail>;
}) => {
  const { t } = useTranslation();
  const isWebView = window.ReactNativeWebView;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cl.form}>
      <p>{t("confirmEmail.title")}</p>
      <input
        placeholder={t("confirmEmail.oldEmailPlaceholder", { email: oldEmail })}
        className="mb-3"
        {...register("verify_code")}
      />
      <input
        placeholder={t("confirmEmail.newEmailPlaceholder", { email: newEmail })}
        {...register("code_from_new_email")}
      />
      <button type="submit">{t("confirmEmail.sendButton")}</button>
      {isWebView && (
        <button
          type="button"
          onClick={() => window.ReactNativeWebView?.postMessage("Done")}
          className="!bg-[#101010] !text-white"
        >
          {t("confirmEmail.cancelButton")}
        </button>
      )}
    </form>
  );
};

export default withErrorShow(withLoader(ConfirmEmail));
