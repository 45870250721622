import { instance } from "./instances.ts";
import type { HistoryOne } from "utils/types/response/HistoryRes";
import getSpaceId from "../../utils/constants/getSpaceId.ts";
import type { AxiosResponse } from "axios";

export interface EditHistoryEntryData {
  account: string;
  category?: string;
  amount: number;
  comment: string;
}

export const HistoryAPI = {
  getNotifications: (limit: number): Promise<AxiosResponse<HistoryOne[]>> =>
    instance.get(`/my_spaces/${getSpaceId()}/my_history/?limit=${limit}`),

  deleteExpense: (id: number): Promise<AxiosResponse<void>> =>
    instance.delete(
      `/my_spaces/${getSpaceId()}/my_history/expenses/edit/${id}/`,
    ),

  deleteIncome: (id: number): Promise<AxiosResponse<void>> =>
    instance.delete(
      `/my_spaces/${getSpaceId()}/my_history/incomes/edit/${id}/`,
    ),

  editHistoryEntry: (
    id: number,
    type: string,
    data: EditHistoryEntryData,
  ): Promise<AxiosResponse<HistoryOne>> => {
    const endpoint = type === "income" ? "incomes" : "expenses";
    return instance.put(
      `/my_spaces/${getSpaceId()}/my_history/${endpoint}/edit/${id}/`,
      data,
    );
  },
};
