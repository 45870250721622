import React, { useState, lazy, Suspense, useEffect } from "react";
import { animated, useTransition } from "@react-spring/web";
import MonthsContainer from "./Months";

import classes from "../home_page.module.scss";

import Loader from "utils/components/Loader/Loader";
import PremiumMenu, { ShowingType } from "./PremiumMenu";
const CreateCategory = lazy(() => import("./CreateCategory/PC"));

import useStore from "store";

// Images
import MenuOpenBtnSvg from "/src/image/p_menu/MenuOpenBtn.svg";
import Notifications from "./Notifications/Notifications";
import Spinner from "utils/components/Spinner";

import eventEmitter from "services/EventEmitter";

interface MenuOpenBtnProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean | ShowingType>>;
  menuPos: { top: string; left: string };
}

const MenuOpenBtn = ({ setShowMenu, menuPos }: MenuOpenBtnProps) => {
  // in case if user gonna click on upgrade button in the alertAboutLimit
  const openShop = () => {
    setShowMenu("Shop");
  };

  useEffect(() => {
    eventEmitter.on("openShop", openShop);
    return () => {
      eventEmitter.off("openShop", openShop);
    };
  }, []);

  useEffect(() => {
    const handleMenuTrue = () => {
      setShowMenu(true);
    };

    const handleMenuFalse = () => {
      setShowMenu(false);
    };

    eventEmitter.on("setMenuTrue", handleMenuTrue);

    eventEmitter.on("setMenuFalse", handleMenuFalse);
  }, []);

  return (
    <button
      type="button"
      id="MenuOpenBtn"
      onClick={() => setShowMenu(true)}
      className="rounded-3xl scale-90 bg-[#1c1c1c] z-20 bg-opacity-70 p-[0.6rem] top-9"
    >
      <img
        src={MenuOpenBtnSvg}
        alt="MenuOpenBtn"
        className="bg-[#1d1f20] size-[60px] p-[9px] rounded-2xl border border-[#727171] hover:border-[#ffa800]"
      />
    </button>
  );
};

const Overlay = () => {
  const { showDialog, showSpinner } = useStore();

  const [showMenu, setShowMenu] = useState<boolean | ShowingType>(false);
  const [animate, setAnimate] = useState(true); 
  const menuPos: { left: string; top: string } = {
    left: "6",
    top: "6",
  };

  const transitions = useTransition(showMenu, {
    from: animate
      ? {
          transform: "scaleY(0)",
          transformOrigin: "top",
          opacity: 0,
        }
      : {
          transform: "scaleY(1)",
          transformOrigin: "top",
          opacity: 1,
        },
    enter: {
      transform: "scaleY(1)",
      transformOrigin: "top",
      opacity: 1,
    },
    leave: {
      transform: "scaleY(0.6)",
      transformOrigin: "top",
      opacity: 0,
    },
    config: { tension: 220, friction: 20 },
  });

  useEffect(() => {
    const handleMenuTrue = () => {
      setAnimate(false); // Disable animation when opening via handler
      setShowMenu(true);
    };

    const handleMenuFalse = () => {
      setAnimate(true); // Enable closing animation
      setShowMenu(false);
    };

    eventEmitter.on("setMenuTrue", handleMenuTrue);
    eventEmitter.on("setMenuFalse", handleMenuFalse);

    return () => {
      eventEmitter.off("setMenuTrue", handleMenuTrue);
      eventEmitter.off("setMenuFalse", handleMenuFalse);
    };
  }, []);

  return (
    <div className={classes.overlay}>
      <MonthsContainer />
      <Notifications />

      {transitions((style, item) =>
        item ? (
          <animated.div style={style} className={`absolute h-dvh z-10`}>
            <PremiumMenu
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              menuPos={menuPos}
            />
          </animated.div>
        ) : null,
      )}

      {showSpinner && (
        <div className="absolute right-32 top-10 z-30">
          <Spinner />
        </div>
      )}

      {!showMenu && <MenuOpenBtn setShowMenu={setShowMenu} menuPos={menuPos} />}
      {showDialog && (
        <Suspense fallback={<Loader />}>
          <CreateCategory />
        </Suspense>
      )}
    </div>
  );
};

export default Overlay;
