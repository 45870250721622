import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { SpaceAPI } from "services/API";
import getSpaceId from "utils/constants/getSpaceId";
import smalltalk from "utils/libraries/smalltalk";
import { useTranslation } from "react-i18next";

import User from "/src/image/AdminPanel/User.svg";
import Trash from "/src/image/AdminPanel/Trash.svg";

import cl from "./Collaborator.module.scss";
import { CollaboratorElementProps } from "./types";
import { useMutation } from "@tanstack/react-query";
import useStore from "store/store";

const CollaboratorElement: React.FC<CollaboratorElementProps> = ({
  username,
  email,
  tag,
  me,
  refetch,
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const spaceId = getSpaceId();
  const { subscription } = useStore();

  const handleTrashClick = () => {
    smalltalk
      .confirm(t("removeUserTitle"), t("removeUserMessage", { username, tag }))
      .then(() => mutate());
  };

  const { mutate, isPending } = useMutation({
    mutationFn: () => SpaceAPI.removeMember(spaceId, email),
    onSuccess: () => {
      refetch();
    },
  });

  const containerVariants = {
    initial: { opacity: 0, scale: 0.9, x: -20 },
    animate: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: { type: "spring", stiffness: 100 },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      x: 20,
      transition: { duration: 0.3 },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className={cl.collaboratorElement}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <motion.img
        src={User}
        alt={t("userIconAlt")}
        className={cl.userIcon}
        whileHover={{ scale: 1.05 }}
      />
      <div className={cl.userContainer}>
        <div className={cl.userInfo}>
          <motion.span
            className={cl.username}
            whileHover={{ color: "#FFA800" }}
          >
            {username}#{tag}
          </motion.span>
          <div className={cl.userSince}>{email}</div>
        </div>
        {!me && subscription !== "business_member_plan" && (
          <AnimatePresence>
            {isHovered && (
              <motion.div
                className={cl.iconsContainer}
                initial={{ opacity: 0, x: 10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 10 }}
              >
                <motion.button
                  className={cl.TrashButton}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleTrashClick}
                  disabled={isPending}
                >
                  <img
                    src={Trash}
                    alt={t("trashIconAlt")}
                    className={cl.TrashIcon}
                  />
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
    </motion.div>
  );
};

export default CollaboratorElement;
