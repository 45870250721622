import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AdminAPI } from "services/API/Admin";
import cl from "./Table.module.scss";
import getSpaceId from "utils/constants/getSpaceId";
import { ProgressBar } from "./ProgressBar";
import { ASSET_COLORS, TABLE_HEADERS } from "./tableConstants";
import { ErrorMessage, rowVariants } from "./TableUtils";
import { useTranslation } from "react-i18next";
import LittleLoader from "utils/components/Loader/LittleLoader";
import { loadStripe } from "@stripe/stripe-js";
import useStore from "store/store";

const TableComponent: React.FC = () => {
  const spaceId = getSpaceId();
  const { t } = useTranslation();
  const [assets, setAssets] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { subscription } = useStore();

  // Я использую useEffect, потому что useQuery работает не подходяще для этого элемента
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await AdminAPI.ViewTable(spaceId);
        setAssets(response.data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [spaceId]);

  const totalPrice = useMemo(() => {
    const sum = assets
      ? assets.reduce((sum, asset) => sum + asset.price, 0)
      : 0;
    const roundedSum = Math.round(sum * 100) / 100;
    return roundedSum < 1 ? 0 : roundedSum;
  }, [assets]);

  const stripePromise = loadStripe(
    "pk_test_51QeGGjDwblSo7CmzpbLkYjnFrOVggqKbxJiiBeGH0L6gEhmTKdes0TqiZwmEILay5sZ8C6oWFc5aebej4MSY3GG800WNwDAZdI",
  );

  const handlePayService = async () => {
    try {
      const paymentData = await AdminAPI.GetServiceSession(spaceId);

      if (!paymentData.data) {
        console.error("Payment data is not available");
        return;
      }

      const stripe = await stripePromise;
      if (!stripe) {
        console.error("Stripe failed to initialize");
        return;
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: paymentData.data.session_id,
      });

      if (error) {
        console.error("Stripe redirect error:", error.message);
      }
    } catch (error) {
      console.error("Payment handling error:", error);
    }
  };

  return (
    <div className="grow w-full">
      <p className="w-full px-6 flex justify-between">
        <span className={cl.tableTitle}>
          {t("stats.title")}
          <span className={cl.tooltip}>{t("stats.description2")}</span>
        </span>
        {totalPrice > 1.25 && subscription !== "business_plan" && subscription !== "business_member_plan"  && (
          <button className={cl.buyTitle} onClick={handlePayService}>
            Pay the {totalPrice}€
          </button>
        )}
      </p>

      {isLoading ? (
        <LittleLoader />
      ) : error ? (
        <ErrorMessage message={error.message} />
      ) : (
        <div className={cl.assetTable}>
          <div className="w-full grid grid-cols-5">
            {TABLE_HEADERS.map((header) => (
              <span key={header}>{t(`tableHeaders.${header}`)}</span>
            ))}
          </div>
          <AnimatePresence>
            {assets?.map((asset, index) => {
              const { base: barColor, highlight: barColorWithTransparency } =
                ASSET_COLORS[index % ASSET_COLORS.length];

              return (
                <motion.div
                  key={asset.id}
                  variants={rowVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  custom={index}
                >
                  <span>{asset.assets}</span>
                  <span>{asset.data}</span>
                  <span>€{asset.price.toLocaleString()}</span>
                  <span>
                    <div className={cl.statusContainer}>
                      <ProgressBar
                        current={asset.price}
                        total={totalPrice}
                        barColor={barColor}
                        barColorWithTransparency={barColorWithTransparency}
                      />
                    </div>
                  </span>
                  <span>{asset.updated_date}</span>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default TableComponent;
