export const getTourStyles = (stepIndex: number) => {
  const baseStyles = {
    options: {
      zIndex: 10000,
      primaryColor: "#FFA800",
      backgroundColor: "#181818",
      overlayColor: "rgba(0, 0, 0, 0.7)",
      textColor: "#FFFFFF",
      arrowColor: "#727272",
    },
  };

  const spotlightStyles = {
    1: { borderRadius: "15px" },
    2: { borderRadius: "5px" },
    4: { borderRadius: "5px" },
    6: { transform: "scale(0.97)", borderRadius: "24px" },
    9: { transform: "scale(0.97)", borderRadius: "24px" },
    11: { transform: "scale(0.97)", borderRadius: "24px" },
    12: { transform: "scale(0.80)", borderRadius: "24px" },
    13: { transform: "scale(0.90)", borderRadius: "24px", marginTop: "3px" },
  };

  const scaleStyles = {
    default: { transform: "scale(0.95)", borderRadius: "50%" },
    largeScaleSteps: { transform: "scale(0.90)", borderRadius: "24px" },
  };

  return {
    ...baseStyles,
    spotlight:
      spotlightStyles[stepIndex] ||
      (stepIndex >= 14 && stepIndex <= 25
        ? scaleStyles.largeScaleSteps
        : scaleStyles.default),
  };
};
