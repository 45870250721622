import { z } from "zod";

const emailSchema = z
  .string()
  .email()
  .max(50)
  .min(5)
  .refine((value) => !value.includes("<") && !value.includes(">"));

export const validateEmail = (email: string): boolean => {
  return emailSchema.safeParse(email).success;
};
