import utilCl from "utils/Classes.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import withLoader from "utils/hoc/withLoader";
import cl from "./Form.module.scss";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./FormContainer";
import type { Dispatch, FC, SetStateAction } from "react";
import type { OneAccount } from "utils/types/response/AccountRes";
import type { OneCategory } from "utils/types/response/CategoriesRes";
import ChooseDay from "./ChooseDay/ChooseDay";
import { useTranslation } from "react-i18next";

interface Props extends IFormProps<IFormInputs> {
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
  submitButtonText: string;
  accounts: OneAccount[];
  categories: OneCategory[];
}

const Form: FC<Props> = ({
  handleSubmit,
  onSubmit,
  register,
  setCreatingPayment,
  submitButtonText = "Create",
  accounts,
  categories,
}) => {
  const { t } = useTranslation();

  return (
    <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
      <ChooseDay register={register} />
      <input
        autoComplete="off"
        placeholder={t("form2.titlePlaceholder")}
        {...register("title")}
      />
      <input
        autoComplete="off"
        placeholder={t("form2.amountPlaceholder")}
        {...register("amount")}
      />
      <select {...register("account_pk")}>
        <option value="" disabled>
          {t("form2.selectAccount")}
        </option>
        {accounts.map((account) => (
          <option key={account.id} value={account.id}>
            {account.title} | {account.balance_converted} {account.currency}
          </option>
        ))}
      </select>
      <select {...register("category_pk")}>
        <option value="" disabled>
          {t("form2.selectCategory")}
        </option>
        {categories.map((category) => (
          <option key={category.id.toString()} value={category.id}>
            {category.title}
          </option>
        ))}
      </select>
      <button
        type="button"
        onClick={() => setCreatingPayment(false)}
        className={`${utilCl.black_button} rounded-l-xl py-3`}
      >
        {t("form2.cancel")}
      </button>
      <button className={`${utilCl.yellow_button} rounded-r-xl`} type="submit">
        {t(submitButtonText)}
      </button>
    </form>
  );
};

export default withErrorShow(Form);
