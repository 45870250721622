import React from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import getSpaceId from "utils/constants/getSpaceId";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import cl from "./Service.module.scss";
import { AdminAPI } from "services/API/Admin";

import { MONTHS } from "./Charts";
import { generateChartOptions, prepareChartData } from "./Utils";
import { useTranslation } from "react-i18next";
import LittleLoader from "utils/components/Loader/LittleLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const StatisticComponent = () => {
  const spaceId = getSpaceId();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["serviceStatistic", spaceId],
    queryFn: async () => {
      const response = await AdminAPI.ViewStatistic(spaceId);
      return response.data;
    },
  });

  const datasetData = MONTHS.map((month) =>
    data?.[month] ? parseFloat(data[month]) : 0,
  );

  const maxValue = Math.max(...datasetData);
  const chartData = prepareChartData(data || {}, MONTHS);
  const options = generateChartOptions(maxValue);

  const ErrorMessage = () => (
    <div className={cl.error}>
      {isError ? "Failed to load statistics" : "An unexpected error occurred"}
    </div>
  );

  return (
    <div className="w-full h-[50%]">
      <p className={cl.tableTitle}>
        {t("stats.service")}
        <span className={cl.tooltip}>{t("stats.description")}</span>
      </p>
      {isLoading ? (
        <LittleLoader />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <Bar data={chartData} options={options} />
      )}
    </div>
  );
};

export default StatisticComponent;
