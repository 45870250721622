import React, { type RefObject } from "react";
import cl from "./Login.module.scss";
import type {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { Link } from "react-router-dom";
// icons
import logInLogo from "image/login_p/login/log_in_logo.svg";
import signUpLogo from "image/login_p/login/sign_up_logo.svg";
import eye from "image/login_p/login/eye.svg";
import closedEye from "image/login_p/login/closed_eye.svg";
// custom components
import Field from "../Field";
import License from "./License";
import FormChoose from "./FormChoose";
import withLoader from "utils/hoc/withLoader";
import { useTranslation } from "react-i18next";

interface Inputs {
  email: string;
  password: string;
  license?: boolean;
}

interface Props {
  isLogining: boolean;
  setIsLogining: React.Dispatch<React.SetStateAction<boolean>>;
  shownPassword: boolean;
  setShownPassword: React.Dispatch<React.SetStateAction<boolean>>;
  errors: FieldErrors;
  register: UseFormRegister<Inputs>;
  handleSubmit: UseFormHandleSubmit<Inputs>;
  onSubmit: SubmitHandler<Inputs>;
  googleLogin: () => void;
  handleError: () => void;
  errorMessageRef: RefObject<HTMLParagraphElement>;
}

const Login: React.FC<Props> = ({
  isLogining,
  setIsLogining,
  shownPassword,
  setShownPassword,
  errors,
  handleSubmit,
  onSubmit,
  register,
  googleLogin,
  handleError,
  errorMessageRef,
}) => {
  const thereIsError = Object.keys(errors).length !== 0;
  const { t } = useTranslation();
  return (
    <div className={cl.login}>
      <div className={cl.form_title}>
        <img
          src={isLogining ? logInLogo : signUpLogo}
          alt={isLogining ? t("login.loginLogo") : t("login.signUpLogo")}
        />
        <div>
          <p className="mb-[20px] text-[32px]">
            {isLogining ? t("login.logIn") : t("login.createAccount")}
          </p>
          <p className={`${cl.errorMessage}`} ref={errorMessageRef}>
            {thereIsError
              ? errors[Object.keys(errors)[0]]?.message
              : t("login.defaultErrorMessage")}
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          className="bg-login_mailbox"
          type="email"
          register={register}
          placeholder={t("login.emailPlaceholder")}
          formValue="email"
          errors={errors}
        />
        <Field
          className="bg-login_shield"
          type={shownPassword ? "text" : "password"}
          register={register}
          placeholder={t("login.passwordPlaceholder")}
          formValue="password"
          errors={errors}
        />
        <img
          src={shownPassword ? closedEye : eye}
          onClick={() => setShownPassword(!shownPassword)}
          alt={
            shownPassword ? t("login.hidePassword") : t("login.showPassword")
          }
          className="cursor-pointer"
        />
        {!isLogining && <License register={register} />}
        <Link
          className="my-2 text-[#6E6E6E] inline-block hover:text-orange duration-300"
          to="forgot-password"
        >
          Forgot password ?
        </Link>
        <button
          className="bg-[#181818] sm:text-[20px] border-b-2 border-b-[#101010] rounded-t-[12px] w-full h-[50px]"
          onClick={googleLogin}
          type="button"
        >
          {t("login.useGoogleAccount")}
        </button>

        <FormChoose
          handleError={handleError}
          isLogining={isLogining}
          setIsLogining={setIsLogining}
        />
      </form>
    </div>
  );
};

export default withLoader(Login);
