import React from "react";
import conf_logo from "image/login_p/conf_email/conf_logo.svg";
import { UseFormRegister, UseFormHandleSubmit } from "react-hook-form";
import cl from "./ConfEmail.module.scss";
import withLoader from "utils/hoc/withLoader";
import withErrorShow from "utils/hoc/withErrorShow";
import { useTranslation } from "react-i18next"; // добавляем useTranslation для перевода

interface Input {
  code: string;
}

interface Props {
  register: UseFormRegister<Input>;
  onSubmit: (data: Input) => void;
  handleSubmit: UseFormHandleSubmit<Input>;
}

const ConfEmail: React.FC<Props> = ({ register, onSubmit, handleSubmit }) => {
  const { t } = useTranslation(); // добавляем хук перевода

  return (
    <>
      <div className={cl.title}>
        <img src={conf_logo} alt="Conf email logo" />
        <div className="max-w-[234px]">
          <p className="mb-1">{t("confEmail.writeCode")}</p>
          <p className="text-[14px] text-[#6E6E6E]">
            {t("confEmail.confirmationMessage")}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={cl.form}>
        <input
          {...register("code")}
          placeholder={t("confEmail.code")}
          autoComplete="off"
        />
        <button type="submit">{t("confEmail.done")}</button>
      </form>
    </>
  );
};

export default withErrorShow(withLoader(ConfEmail));
