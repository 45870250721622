import FormContainer from "./Form/FormContainer";
import { useTranslation } from "react-i18next";

const CreatingTicket = ({
  handleBackClick,
}: {
  handleBackClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="text-2xl flex justify-center">
        <button onClick={handleBackClick} className="absolute left-6">
          ←
        </button>
        <p className="mx-auto">{t("new_ticket")}</p>
      </section>
      <FormContainer handleBackClick={handleBackClick} />
    </>
  );
};

export default CreatingTicket;
